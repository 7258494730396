import React from 'react';
import {Text, View, TouchableOpacity, TextInput} from 'react-native';
import {
  responsiveHeight,
  responsiveWidth,
} from 'react-native-responsive-dimensions';

import {Fonts, gutters, Palette, Style} from '../styles';
import {Button, Input, Spacer} from '../components';

export const PriceFilterUI = ({
  prices = [],
  onReset = () => {},
  onApply = () => {},
  minPrice,
  maxPrice,
  setMinPrice,
  setMaxPrice,
  priceSelected,
  setPriceSelected,
}) => {
  return (
    <View style={Style.con({flex: 1, justify: 'space-between', mb: 20})}>
      <View>
        <Text
          style={Fonts.t(17, Palette.black, {
            wei: '700',
            text: 'center',
            t: responsiveHeight(1.5),
            b: responsiveHeight(4),
          })}>
          {'Prix'}
        </Text>
        <View
          style={Style.con({
            direc: 'row',
            wrap: 'wrap',
            justify: 'center',
            mb: responsiveHeight(6),
          })}>
          {Array.isArray(prices) &&
            prices.map(item => {
              const isPriceSelected = priceSelected === item;
              const onPressPrice = () => {
                if (isPriceSelected) {
                  setPriceSelected(0);
                } else {
                  setPriceSelected(parseInt(item));
                  setMaxPrice('');
                }
              };
              return (
                <TouchableOpacity
                  key={item}
                  onPress={() => onPressPrice(item)}
                  style={Style.con({
                    w: responsiveWidth(40),
                    h: responsiveHeight(6),
                    bg: isPriceSelected ? Palette.primary : Palette.gray[2],
                    bor: 5,
                    m: 6,
                    cen: true,
                  })}>
                  <Text
                    style={Fonts.t(
                      15,
                      isPriceSelected ? Palette.white : Palette.black,
                      {wei: '600'},
                    )}>{`moins de ${item}€`}</Text>
                </TouchableOpacity>
              );
            })}
        </View>
        <View style={Style.con({px: gutters})}>
          <Input
            placeholder="Prix minimum"
            keyboardType="numeric"
            inputMode="numeric"
            style={Style.con({mb: 12})}
            value={minPrice}
            onChange={price => {
              if (parseInt(price, 10)) {
                setMinPrice(parseInt(price, 10));
              }
            }}
          />
          <Input
            placeholder="Prix maximum"
            keyboardType="numeric"
            value={maxPrice}
            onChange={price => {
              if (parseInt(price, 10)) {
                setMaxPrice(parseInt(price, 10));
              }
              setPriceSelected(0);
            }}
          />
        </View>
      </View>
      <View>
        <Button
          title="Appliquer"
          onPress={() => onApply(priceSelected, minPrice, maxPrice)}
          style={Style.con({mx: gutters, mb: 6})}
        />
        <Button
          primary={false}
          title="Réinitialiser" // Reset
          onPress={onReset}
          style={Style.con({mx: gutters})}
        />
      </View>
    </View>
  );
};

export default PriceFilterUI;
