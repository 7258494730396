const Palette = {
  primary: '#C02C3D',
  secondary: '#F28992',
  white: 'white',
  black: 'black',
  tran: 'transparent',

  gray: {
    1: '#7E7E7E',
    2: '#F5F5F5',
    3: '#DEDEDE',
    4: '#E9E9E9',
    5: '#F0F0F0',
    6: '#949494',
    7: '#6D6D6D',
    8: '#939393',
    9: '#D5D5D5',
    10: '#979797',
    11: '#CDCDCD',
    12: '#BEBEBE',
    13: '#D0D0D0',
    14: '#C4C4C4',
    15: '#F3F3F3',
    16: '#F1F1F1',
    17: '#D7D7D7',
    18: '#B8B8B8',
  },
  text: {
    1: '#2E2A2B',
  },
};

export default Palette;
