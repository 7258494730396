import React from "react";
import {Image, Text} from 'react-native';
import GoogleMapReact from "google-map-react";
import { icons } from "../../assets";
import { googleApiKey } from "../../config/apiKeys";

export const ItemMarker = ({ ...props }) => <div {...props}></div>;
export const providerGoogle = null;

export default ({
  initialRegion = {},
  containerStyle = {},
  zoom = 5,
  children = null,
  googleMapReactProps = {},
}) => {
  return (
    <div style={{width: '100%', height: '100vh', ...containerStyle}}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: googleApiKey,
        }}
        defaultCenter={{
          lat: initialRegion?.latitude || 48.18,
          lng: initialRegion?.longitude || 5.23,
        }}
        defaultZoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        {...googleMapReactProps}>
        {children.map(({props}) => (
          <div
            lat={props?.coordinate?.latitude}
            lng={props?.coordinate?.longitude}
            onClick={props?.onPress}>
            <Image
              source={icons.iconMapMarker}
              style={{width: 40, height: 40}}
            />
          </div>
        ))}
      </GoogleMapReact>
    </div>
  );
};
