import React, { useState, useGlobal } from "reactn";

import { Text, View, TouchableOpacity, Image } from "react-native";
import { useNavigation } from "@react-navigation/native";

import { Style, Fonts, Palette, gutters } from "../styles";
import { icons } from "../assets";
import { Routes } from "../navigation";
import { thematicData } from "../data";

const ServiceAppointments = ({
  item,
  selectedCategory,
  setSelectedCategory,
  prestationsList,
  instituteName,
  instituteId,
  instituteProfilPicture,
  instituteLocation,
  instituteInstagram,
  categories,
  phone,
  isLoading = true,
}) => {
  const navigation = useNavigation();
  const [selectedPrestations, setSelectedPrestations] = useGlobal(
    "selectedPrestations"
  );

  const [isOpened, setOpened] = useState(false);

  const onPressAppointment = (prestation) => {
    try {
      let newSelectedprestation = { ...selectedPrestations };

      if (
        !(newSelectedprestation?.[instituteId] || [])
          .map(({ prestationId }) => prestationId)
          .includes(prestation.prestationId)
      ) {
        newSelectedprestation[instituteId] = [
          ...(newSelectedprestation?.[instituteId] || []),
          prestation,
        ];
        setSelectedPrestations(newSelectedprestation);
      }
      navigation?.navigate(Routes.ChooseTimeDate, {
        institute: {
          instituteName,
          instituteProfilPicture,
          instituteId,
          instituteLocation,
          instituteInstagram,
          categories,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onPressGallery = (prestation) => {
    navigation?.navigate(Routes.PostsRelatedToService, {
      // item,
      prestation,
      instituteName,
      instituteId,
      instituteProfilPicture,
      instituteLocation,
      instituteInstagram,
      categories,
    });
  };

  const onPressOpen = () => {
    if (isOpened) {
      setOpened(false);
      setSelectedCategory(null);
    } else {
      setOpened(true);
      setSelectedCategory(item.type);
    }
  };

  return (
    <>
      <TouchableOpacity
        onPress={() => {
          onPressOpen(item);
        }}
        style={[
          Style.con({
            direc: "row",
            items: "center",
            justify: "space-between",
            py: 40,
            px: gutters,
            bg:
              selectedCategory === item.type ? Palette.gray[15] : Palette.white,
          }),
          Style.border({ color: Palette.gray[5], bbw: 1 }),
        ]}
      >
        <Text>{thematicData[item.thematicId].name}</Text>

        <Image
          style={Style.con({
            size: 24,
            rotate: selectedCategory === item.type ? "180deg" : "0deg",
          })}
          source={icons.iconDown}
        />
      </TouchableOpacity>

      {selectedCategory === item.type &&
        Array.isArray(prestationsList) &&
        prestationsList.map((prestation) => {
          return (
            <TouchableOpacity
              key={prestation.prestationId}
              onPress={() => onPressAppointment(prestation)}
              style={[
                Style.con({
                  direc: "row",
                  items: "center",
                  justify: "space-between",
                  py: 20,
                  px: gutters,
                }),
                Style.border({ color: Palette.gray[5], bbw: 1 }),
              ]}
            >
              <View style={Style.con({ direc: "column", mb: 20 })}>
                <Text style={Fonts.t(12, Palette.black, { wei: "600" })}>
                  {prestation?.name || ""}
                </Text>

                <Text
                  style={Fonts.t(12, Palette.black, { wei: "400", op: 0.5 })}
                >
                  {prestation.allSkinTypes
                    ? "Convient à tout les types de peaux"
                    : "Ne convient pas à tout les types de peaux"}
                </Text>

                <View
                  style={Style.con({
                    direc: "row",
                    items: "center",
                    t: 14,
                  })}
                >
                  <Image
                    style={Style.con({ size: 16 })}
                    source={icons.iconClock}
                  />
                  <Text
                    style={Fonts.t(12, Palette.black, { wei: "600", l: 9 })}
                  >
                    {`${prestation?.duration || null} min`}
                  </Text>
                  <Text
                    style={Fonts.t(12, Palette.primary, { wei: "600", l: 48 })}
                  >
                    {`${prestation?.price || null} €`}
                  </Text>
                </View>
              </View>

              <TouchableOpacity
                onPress={() => onPressGallery(prestation)}
                style={Style.con({ items: "center" })}
              >
                <Image
                  style={Style.con({ size: 26 })}
                  source={icons.iconGallery}
                />
                <Text
                  style={Fonts.t(12, Palette.black, {
                    wei: "400",
                    op: 0.5,
                    t: 5,
                  })}
                >
                  {"Voir des exemples"}
                </Text>
              </TouchableOpacity>
            </TouchableOpacity>
          );
        })}
    </>
  );
};

export default ServiceAppointments;
