import {Motion} from '@legendapp/motion';
import {Pressable} from 'react-native';
import {Palette} from '../styles';

export default ({value, setValue}) => {
  const currentColor = value ? Palette.primary : Palette.gray[6];

  const switchHeight = 28;
  const borderWidth = 2;
  const internalMargin = 2;

  const itemSize = switchHeight - borderWidth * 2 - internalMargin * 2;

  return (
    <Pressable
      onPress={() => setValue(!value)}
      style={{
        flexDirection: 'row',
        width: switchHeight * 2,
        height: switchHeight,
        borderColor: currentColor,
        borderWidth: borderWidth,
        borderRadius: 25,
      }}>
      <Motion.View
        animate={{
          right: value ? 0 : switchHeight,
          left: value ? switchHeight : 0,
        }}
        transition={{
          type: 'timing',
          duration: 300,
        }}
        style={{
          position: 'absolute',
          width: itemSize,
          height: itemSize,
          margin: internalMargin,
          borderRadius: 25,
          backgroundColor: currentColor,
        }}
      />
    </Pressable>
  );
};
