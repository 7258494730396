import React, {useEffect, useState} from 'react';
import {useGlobal} from 'reactn';
import {
  ActivityIndicator,
  FlatList,
  Image,
  Platform,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import {Video} from 'expo-av';
import Lightbox from 'react-native-lightbox-v2';
import {useNavigation} from '@react-navigation/native';
import {
  responsiveHeight,
  responsiveWidth,
} from 'react-native-responsive-dimensions';

import {Fonts, gutters, Palette, Style} from '../styles';
import {icons} from '../assets';
import {Button, Header, ServiceTypeList, TabView} from '../components';
import {thematicData} from '../data';
import {Routes} from '../navigation';
import {reservationRouting} from '../helpers/reservation';
import useDataFromRef from '../hooks/useDataFromRef';

import {
  postsRef,
  reviewsRef,
  institutesRef,
  arrayRemove,
  arrayUnion,
  usersRef,
} from '../config/firebase';
import {isDesktopWeb} from '../config/layoutConstants';
import Page from '../components/Page';

export const INSTITUTE_PROFILE_TABS = {
  Posts: 'Posts',
  Avis: 'Avis',
  Informations: 'Informations',
};

export default ({route}) => {
  const navigation = useNavigation();
  const [currentUserData] = useGlobal('currentUserData');
  const [currentUID] = useGlobal('currentUID');

  const [query, setQuery] = useState(postsRef);
  const [selectedTags] = useGlobal('selectedTags');
  const [curTab, setCurTab] = useState(INSTITUTE_PROFILE_TABS.Posts);
  const [, setIsGlobalLoading] = useGlobal('_isLoading');
  const [, setTooltip] = useGlobal('_tooltip');
  const [categoriesInstitute, setCategoriesInstitute] = useState([]);

  useEffect(() => {
    let newQuery = postsRef;

    if (selectedTags.length > 0) {
      newQuery = newQuery.where('prestationData.category', 'in', selectedTags);
    }
    setQuery(newQuery);
  }, [selectedTags]);

  console.log('route', route?.params);

  // const instituteId = institute?.instituteId || route?.params?.instituteId || institute?.id;
  const instituteId = route?.params?.instituteId || null;

  const {
    data: postsList,
    loadmore,
    loading,
  } = useDataFromRef({
    ref: instituteId
      ? query.where('instituteId', '==', instituteId || '-')
      : null,
    simpleRef: false,
    refreshArray: [selectedTags, instituteId, query],
    batchSize: 10,
    usePagination: false,
    listener: true,
    documentID: 'postId',
    initialState: [],
  });

  const {data: institute} = useDataFromRef({
    ref: instituteId ? institutesRef.doc(instituteId) : null,
    simpleRef: true,
    refreshArray: [instituteId],
    listener: true,
    documentID: 'instituteId',
    onUpdate: data => {
      if (data?.categories) {
        setCategoriesInstitute(data.categories.map(cat => cat.thematicId));
      }
    },
  });

  const instituteName = institute?.name || route?.params?.instituteName;

  const instituteProfilPicture =
    institute?.profilPicture || route?.params?.instituteProfilPicture;
  const ratings = institute?.ratings || route?.params?.ratings;
  const categories = institute?.categories || route?.params?.categories;
  const instituteLocation =
    institute?.location || route?.params?.instituteLocation;
  const instituteInstagram =
    institute?.instagram || route?.params?.instituteInstagram;

  function onPressMakeAppointment() {
    reservationRouting({
      navigation,
      institute: {
        instituteName,
        instituteProfilPicture,
        instituteId,
        instituteLocation,
        instituteInstagram,
        categories,
        ratings,
      },
    });
  }

  const {data: reviewsList} = useDataFromRef({
    ref: instituteId
      ? reviewsRef
          .where('display', '==', true)
          .where('instituteId', '==', instituteId || '-')
      : null,
    refreshArray: [instituteId],
    condition: instituteId,
    simpleRef: false,
    initialState: [],
    documentID: 'reviewId',
  });

  const loadingView = () => {
    if (loading) {
      return (
        <View style={Style.con({flex: 1, justify: 'center'})}>
          <ActivityIndicator size="large" />
        </View>
      );
    } else {
      return null;
    }
  };

  const isLikedInstitute =
    currentUserData?.likedInstitutesId?.includes(instituteId);
  const instituteDocument = institutesRef.doc(instituteId);
  const userDocument = currentUID ? usersRef.doc(currentUID) : null;

  const likedInstitute = async () => {
    if (isLikedInstitute === true) {
      try {
        setIsGlobalLoading(true);
        await instituteDocument.update({
          favBy: arrayRemove(currentUserData?.id),
        });
        await userDocument.update({
          likedInstitutesId: arrayRemove(instituteId),
        });
        setTooltip({text: 'Institut retiré des favoris'});
      } catch (error) {
        console.log(error);
      } finally {
        setIsGlobalLoading(false);
      }
    } else {
      try {
        setIsGlobalLoading(true);
        await instituteDocument.update({
          favBy: arrayUnion(currentUserData?.id),
        });
        await userDocument.update({
          likedInstitutesId: arrayUnion(instituteId),
        });
        setTooltip({text: 'Institut ajouté aux favoris'});
      } catch (error) {
        console.log({text: error.message});
      } finally {
        setIsGlobalLoading(false);
      }
    }
  };

  const reviewImage = uri => {
    if (Platform.OS === 'web') {
      return (
        <Image style={Style.con({size: 54, bor: 5})} source={{uri: uri}} />
      );
    } else {
      return (
        <Lightbox
          style={Style.con({size: 54, bor: 5, over: 'hidden'})}
          activeProps={{resizeMode: 'contain'}}>
          <Image style={{width: '100%', height: '100%'}} source={{uri: uri}} />
        </Lightbox>
      );
    }
  };

  const RenderTabBody = () => {
    if (curTab === INSTITUTE_PROFILE_TABS.Posts) {
      return (
        <View style={Style.con({flex: 1, mb: 50})}>
          <ServiceTypeList
            data={thematicData?.filter(data =>
              categoriesInstitute.includes(data.id),
            )}
            style={Style.con({
              mt: isDesktopWeb ? 10 : 0,
              mb: isDesktopWeb ? 10 : 24,
            })}
            isInstituteProfile={true}
          />
          {postsList.length < 1 ? (
            <View
              style={Style.con({
                justify: 'center',
                self: 'center',
                my: responsiveHeight(15),
              })}>
              {loading ? (
                <ActivityIndicator size="large" />
              ) : (
                <>
                  <Image
                    style={Style.con({size: 40, self: 'center'})}
                    source={icons.iconGallery2}
                  />
                  <Text style={[Fonts.t(15, Palette.black)]}>
                    Pas de posts publiés
                  </Text>
                </>
              )}
            </View>
          ) : (
            <FlatList
              data={postsList}
              numColumns={3}
              showsVerticalScrollIndicator={false}
              onEndReached={loadmore}
              onEndReachedThreshold={0.5}
              ListFooterComponent={loadingView}
              initialNumToRender={10}
              ItemSeparatorComponent={() => {
                return <View style={Style.con({h: 2, bg: Palette.white})} />;
              }}
              renderItem={({item, index}) => {
                const itemSize = (responsiveWidth(100) - 3) / 3;
                const itemStyle = index % 3 === 1 ? Style.con({mx: 2}) : {};
                const postId = item.postId;
                const INSTITUTE_POSTS = true;
                const onPressPost = () => {
                  navigation?.push(Routes.PostDetails, {
                    postId,
                    INSTITUTE_POSTS,
                    instituteId,
                  });
                };
                const onPressVideo = () => {
                  navigation?.navigate(Routes.ReelsStack, item);
                };

                return (
                  <TouchableOpacity
                    style={
                      isDesktopWeb ? {width: '33%', height: 200} : itemStyle
                    }
                    onPress={item.type === 'image' ? onPressPost : onPressVideo}
                    key={index}>
                    {item?.type === 'image' ? (
                      <Image
                        style={
                          isDesktopWeb
                            ? {
                                width: '100%',
                                height: '100%',
                              }
                            : Style.con({size: itemSize})
                        }
                        source={{
                          uri: item?.mediaURL,
                        }}
                      />
                    ) : (
                      <Video
                        source={{uri: item?.mediaURL}}
                        style={
                          isDesktopWeb
                            ? {
                                width: '100%',
                                height: '100%',
                              }
                            : Style.con({size: itemSize})
                        }
                        onError={e => console.log('error', e)}
                        resizeMode="cover"
                        paused={true}
                      />
                    )}
                  </TouchableOpacity>
                );
              }}
            />
          )}
        </View>
      );
    } else if (curTab === INSTITUTE_PROFILE_TABS.Avis) {
      return (
        <View style={Style.con({flex: 1, mb: 50})}>
          {reviewsList.length < 1 ? (
            <View
              style={Style.con({
                justify: 'center',
                self: 'center',
                my: responsiveHeight(15),
              })}>
              <Text style={[Fonts.t(15, Palette.black)]}>
                Pas d'avis publiés
              </Text>
            </View>
          ) : (
            <>
              {reviewsList.map(item => {
                return (
                  <View
                    key={item?.reviewId}
                    style={[
                      Style.con({direc: 'row', py: 32, px: gutters}),
                      Style.border({bbw: 1, color: Palette.gray[4]}),
                    ]}>
                    {item?.userData?.profilPicture ? (
                      <Image
                        style={[
                          Style.con({
                            size: 58,
                            bor: 58,
                          }),
                        ]}
                        source={{uri: item?.userData?.profilPicture || ''}}
                      />
                    ) : (
                      <View
                        style={Style.con({
                          size: 58,
                          bor: 58,
                          bg: Palette.gray[4],
                          justify: 'center',
                        })}>
                        <Text
                          style={Fonts.t(15, Palette.text[1], {
                            wei: '700',
                            mt: 20,
                            text: 'center',
                          })}>
                          {`${(item?.userData?.firstName || '').charAt(0)} `}
                        </Text>
                      </View>
                    )}
                    <View style={Style.con({ml: 16, flex: 1})}>
                      <View style={Style.con({direc: 'row', items: 'center'})}>
                        <Text style={Fonts.t(13, Palette.black, {wei: '600'})}>
                          {item?.userData?.firstName || ''}
                        </Text>
                        <View style={Style.con({direc: 'row', ml: 12})}>
                          {Array(item?.note)
                            .fill()
                            .map(() => {
                              return (
                                <Image
                                  style={Style.con({size: 14})}
                                  source={icons.iconStar}
                                />
                              );
                            })}
                        </View>
                        <Text
                          style={Fonts.t(12, Palette.black, {
                            wei: '600',
                            l: 6,
                          })}>
                          {parseFloat(item?.note).toFixed(1)}
                        </Text>
                      </View>
                      <Text
                        style={Fonts.t(13, Palette.black, {wei: '400', t: 3})}>
                        {item?.text || ''}
                      </Text>
                    </View>
                    {item?.img !== '' ? reviewImage(item?.img) : null}
                  </View>
                );
              })}
            </>
          )}
        </View>
      );
    } else if (curTab === INSTITUTE_PROFILE_TABS.Informations) {
      return (
        <View style={Style.con({flex: 1, py: gutters, mb: 50})}>
          <View>
            <Text
              style={[
                Style.con({pl: responsiveWidth(5)}),
                Fonts.t(15, Palette.black, {wei: '900', b: 10}),
              ]}>
              Horaires d'ouverture
            </Text>
            {(institute?.openingHours || []).map(item => {
              const {dayIndex, dayName, timeRanges} = item;
              return (
                <View
                  key={dayIndex}
                  style={Style.con({
                    direc: 'row',
                    items: 'center',
                    py: responsiveHeight(2),
                    px: responsiveWidth(5),
                    // w: "50%",
                  })}>
                  <Text style={Fonts.t(12)}>{dayName} : </Text>

                  <View style={Style.con({direc: 'row', wrap: 'wrap'})}>
                    {timeRanges?.length > 0 ? (
                      (timeRanges || []).map((item, index) => {
                        const {startTime, endTime} = item;
                        const lastItem = timeRanges.length - 1 === index;
                        if (!lastItem) {
                          return (
                            <Text
                              style={Fonts.t(
                                12,
                              )}>{`${startTime} - ${endTime} | `}</Text>
                          );
                        } else {
                          return (
                            <Text
                              style={Fonts.t(
                                12,
                              )}>{` ${startTime} - ${endTime}`}</Text>
                          );
                        }
                      })
                    ) : (
                      <Text style={Fonts.t(12)}>Fermé</Text>
                    )}
                  </View>
                </View>
              );
            })}
          </View>
        </View>
      );
    }
    return null;
  };

  return (
    <Page>
      <Header
        title={instituteName}
        back={true}
        navigation={navigation}
        iconRight={
          currentUID
            ? isLikedInstitute
              ? icons.iconHeart
              : icons.iconHeartStroke
            : null
        }
        iconTintColor={Palette.primary}
        onRight={likedInstitute}
      />

      <ScrollView
        style={Style.con({flex: 1})}
        contentContainerStyle={Style.con({mb: 25})}
        showsHorizontalScrollIndicator={false}>
        <View style={Style.con({direc: 'row', p: 20})}>
          <View>
            <Image
              source={{uri: institute?.profilPicture}}
              style={[
                Style.con({size: 100, bor: 50}),
                Style.border({color: Palette.black, width: 1}),
              ]}
            />
          </View>
          <View style={Style.con({ml: 20, mt: 5, direc: 'col'})}>
            <Text style={Fonts.t(12, Palette.black, {wei: '700'})}>
              {institute?.type}
            </Text>
            <View style={Style.con({flex: 1, direc: 'row', items: 'center'})}>
              <Text>{'📍 '}</Text>
              <Text
                style={Fonts.t(12, Palette.black, {
                  dec: 'underline',
                  secColor: Palette.gray[6],
                })}>
                {`${institute?.location?.addressIns}, ${institute?.location?.postCode} ${institute?.location?.city}`}
              </Text>
            </View>
            <View style={Style.con({direc: 'row'})}>
              <View
                style={Style.con({
                  bg: Palette.gray[5],
                  px: 10,
                  py: 7,
                  bor: 5,
                  mr: 10,
                })}>
                <Text style={Fonts.t(12, Palette.black, {wei: '700'})}>
                  {institute?.instagram}
                </Text>
              </View>
              <View
                style={Style.con({
                  bg: Palette.gray[5],
                  px: 10,
                  py: 7,
                  bor: 5,
                  direc: 'row',
                  items: 'center',
                })}>
                <Text style={Fonts.t(12, Palette.black, {wei: '700'})}>
                  {institute?.ratingNote}
                </Text>
                <Image
                  source={icons.iconStarSelect}
                  style={Style.con({size: 15, ml: 5})}
                />
              </View>
            </View>
          </View>
        </View>
        <View style={Style.con({flex: 1})}>
          <View style={Style.con({direc: 'row', h: 45})}>
            <TabView
              title="Posts"
              isSelected={curTab === INSTITUTE_PROFILE_TABS.Posts}
              onTap={() => setCurTab(INSTITUTE_PROFILE_TABS.Posts)}
            />
            <TabView
              title="Avis"
              isSelected={curTab === INSTITUTE_PROFILE_TABS.Avis}
              onTap={() => setCurTab(INSTITUTE_PROFILE_TABS.Avis)}
            />
            <TabView
              title="Informations"
              isSelected={curTab === INSTITUTE_PROFILE_TABS.Informations}
              onTap={() => setCurTab(INSTITUTE_PROFILE_TABS.Informations)}
            />
          </View>
          <RenderTabBody />
        </View>
      </ScrollView>
      <Button
        style={Style.con({
          pos: 'absolute',
          l: 22,
          r: 22,
          b: responsiveHeight(2),
          mr: isDesktopWeb ? responsiveWidth(2) : 0,
          ml: isDesktopWeb ? responsiveWidth(2) : 0,
        })}
        onPress={() => onPressMakeAppointment()}
        title="Prendre rendez-vous"
      />
    </Page>
  );
};
