import Palette from './Palette';

const Colors = {
  background: Palette.white,
  text: {
    primary: 'rgba(0,0,0,0.7)',
    secondary: Palette.text1,
    link: Palette.text1,
  },
  button: {
    primary: {
      background: Palette.primary,
      text: Palette.white,
      border: Palette.primary,
    },
    secondary: {
      background: 'transparent',
      text: Palette.text1,
      border: Palette.text1,
    },
  },
  input: {
    background: Palette.grey15,
    text: Palette.text1,
    placeholder: '#8E9192',
  },
};

export default Colors;
