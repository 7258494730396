import React, {useEffect, useState, useRef} from 'react';
import {useGlobal} from 'reactn';
import {Image, TouchableOpacity, TextInput, View} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {responsiveHeight} from 'react-native-responsive-dimensions';
import {gutters, Palette, Style} from '../../styles';
import {icons} from '../../assets';
import MapView, {ItemMarker} from '../../components/MapsView';
import {PlaceHorizontalList, Button, Header} from '../../components';
import firebase, {institutesRef} from '../../config/firebase';
import useAlgoliaSearch from '../../hooks/useAlgoliaSearch';
import {get, init} from 'geofirex';
import {isDesktopWeb, isMobileWeb, isWeb} from '../../config/layoutConstants';
import Locations from '../../services/location';
import Page from '../../components/Page';
import {openToday, openThreeDays} from '../../helpers/openFilter';
import {Routes} from '../../navigation';
import {algoliaSearchKey, algoliaAppId} from '../../config/apiKeys';

const geo = init(firebase);

export default ({navigation}) => {
  const [, setShowFilterModal] = useGlobal('showFilterModal');
  const onPressFilter = () =>
    setShowFilterModal(['SCORE', 'DISTANCE', 'AVAILABILITY']);

  const [scoreSelected] = useGlobal('scoreSelected');
  const [filterScore] = useGlobal('filterScore');
  const [distanceLevel, setDistanceLevel] = useGlobal('distanceLevel');
  const [filterDistance, setFilterDistance] = useGlobal('filterDistance');
  const [selectedCity, setSelectedCity] = useGlobal('selectedCity');
  const [latSelectedCity] = useGlobal('latSelectedCity');
  const [lngSelectedCity] = useGlobal('lngSelectedCity');
  const [currentLoc, setCurrentLoc] = useGlobal('currentLoc');
  const [availabilitySelected] = useGlobal('availabilitySelected');
  const [filterAvailability] = useGlobal('filterAvailability');

  const [institutesList, setInstitutesList] = useState([]);
  const [newInstituteList, setNewInstituteList] = useState([]);
  const [filterSelected, setFilterSelected] = useState(false);
  const [searchText, setSearchText] = useState('');

  console.log('institutesList', institutesList);

  const mapRef = useRef();

  const radius = distanceLevel;

  const searchResult = useAlgoliaSearch({
    query: searchText,
    algoliaObject: {
      index: 'institutes',
      projectID: algoliaAppId,
      publicKey: algoliaSearchKey,
    },
  });

  const institutesData =
    searchResult.length > 0 ? searchResult : institutesList;

  useEffect(() => {
    Locations.location().then(async userLoc => {
      await setCurrentLoc({
        latitude: userLoc.coords.latitude,
        longitude: userLoc.coords.longitude,
        latitudeDelta: 0.01,
        longitudeDelta: 0.01,
      });
    });
  }, []);

  const currentLatitude = currentLoc?.latitude || 47.5;
  const currentLongitude = currentLoc?.longitude || 1.75;

  async function newGeoQuery() {
    try {
      // const center = geo.point(
      //   latSelectedCity || currentLatitude,
      //   lngSelectedCity || currentLongitude,
      // );
      let newQuery = institutesRef;
      if (scoreSelected) {
        newQuery = newQuery.where('ratingNote', '==', scoreSelected);
      }
      if (selectedCity) {
        newQuery = newQuery.where('location.city', '==', selectedCity);
      }
      if (filterScore || filterDistance || filterAvailability) {
        setFilterSelected(true);
      } else {
        setFilterSelected(false);
      }
      // const query = geo.query(newQuery).within(center, radius, field);
      // const queryLoc = await get(query);

      const data = (await newQuery.get()).docs.map(doc => ({
        ...doc.data(),
        instituteId: doc.id,
      }));

      setInstitutesList(data);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    let newInstituteList = institutesData;
    if (availabilitySelected === 'OPEN_NOW' && newInstituteList.length > 0) {
      newInstituteList = newInstituteList.filter(institute =>
        openToday(institute.openingHours),
      );
      setNewInstituteList(newInstituteList);
      return;
    }
    if (
      availabilitySelected === 'IN_THREE_DAYS' &&
      newInstituteList.length > 0
    ) {
      newInstituteList = newInstituteList.filter(institute =>
        openThreeDays(institute.openingHours),
      );
      setNewInstituteList(newInstituteList);
      return;
    }
    setFilterSelected(false);
  }, [availabilitySelected, institutesData]);

  useEffect(() => {
    newGeoQuery();
  }, [
    scoreSelected,
    selectedCity,
    filterDistance,
    filterScore,
    filterAvailability,
    availabilitySelected,
    radius,
    currentLoc,
  ]);

  useEffect(() => {
    mapRef.current?.animateToRegion(
      {
        latitude: latSelectedCity || currentLatitude,
        longitude: lngSelectedCity || currentLongitude,
        latitudeDelta: currentLoc?.latitudeDelta || 10,
        longitudeDelta: currentLoc?.longitudeDelta || 10,
      },
      1000,
    );
  }, [latSelectedCity, lngSelectedCity, currentLoc]);

  const [trackingRegion, setTrackingRegion] = useState({});
  let trackingLatitude = trackingRegion?.latitude;
  let trackingLongitude = trackingRegion?.longitude;

  const distance = () => {
    return Math.sqrt(
      Math.pow(currentLatitude - trackingLatitude, 2) +
        Math.pow(currentLongitude - trackingLongitude, 2),
    );
  };

  const locations = institutesData.map(institute => {
    const instituteLatitude =
      institute?._geoloc?.lat || institute.coordinates._latitude;
    const instituteLongitude =
      institute?._geoloc?.lng || institute.coordinates._longitude;

    return {
      instituteId: institute.instituteId || institute.objectID,
      latitude: instituteLatitude,
      longitude: instituteLongitude,
    };
  });

  const navigateToInstitute = instituteData => {
    navigation?.navigate(Routes.InstituteProfile, {
      ...instituteData,
    });
  };

  return (
    <Page>
      <Header navigation={navigation} />
      <View
        style={Style.con({
          direc: 'row',
          items: 'center',
          mb: isDesktopWeb ? 20 : responsiveHeight(1),
          mt: isMobileWeb ? responsiveHeight(1) : 0,
          justify: 'space-between',
          px: gutters,
        })}>
        <View
          style={Style.con({
            flex: 1,
            direc: 'row',
            items: 'center',
            bg: Palette.gray[2],
            bor: 10,
            mr: gutters / 1.5,
            h: responsiveHeight(5),
            px: gutters / 1.5,
            mt: isDesktopWeb ? responsiveHeight(1) : 0,
          })}>
          <Image
            style={Style.con({size: 20, tin: Palette.black})}
            source={icons.iconSearch2}
          />
          <TextInput
            style={[Style.con({flex: 1, pl: 8})]}
            placeholder="Rechercher"
            placeholderTextColor={Palette.black}
            autoCorrect={false}
            value={searchText}
            onChangeText={text => setSearchText(text)}
          />
        </View>
        <TouchableOpacity
          style={Style.con({size: 27, cen: true})}
          onPress={onPressFilter}>
          <Image
            style={Style.con({
              size: 27,
              tin: filterSelected ? Palette.primary : Palette.black,
            })}
            source={icons.iconFilter}
          />
        </TouchableOpacity>
      </View>

      <View
        style={{
          ...Style.con({
            flex: 1,
          }),
        }}>
        <MapView
          style={Style.con({
            flex: 1,
            pos: 'absolute',
            t: 0,
            l: 0,
            b: 0,
            r: 0,
          })}
          currentLoc={currentLoc}
          locations={locations}
          ref={mapRef}
          panEnabled={false}
          loadingEnabled={true}
          showsUserLocation={true}
          onRegionChangeComplete={region => setTrackingRegion(region)}>
          {institutesData.map(institute => {
            console.log('institute', institute);
            const instituteLatitude = institute?.coordinates?.latitude || 0;
            const instituteLongitude = institute?.coordinates?.longitude || 0;

            const instituteId =
              institute.instituteId || institute.objectID || institute.id;

            console.log('instituteLatitude', instituteLatitude);
            console.log('instituteLongitude', instituteLongitude);

            return (
              <ItemMarker
                key={instituteId}
                coordinate={{
                  latitude: instituteLatitude,
                  longitude: instituteLongitude,
                }}
                title={institute.name}
                onPress={() =>
                  navigateToInstitute({...institute, instituteId})
                }>
                <Image
                  source={icons.iconMapMarker}
                  style={Style.con({size: 40})}
                />
              </ItemMarker>
            );
          })}
        </MapView>
        <View
          style={Style.con({
            pos: 'absolute',
            l: 0,
            r: 0,
            t: 0,
            b: 0,
          })}
          pointerEvents="box-none">
          {distance() > 0.01 ? (
            <Button
              style={Style.con({
                pos: 'absolute',
                b: 0,
                r: gutters,
                l: gutters,
                t: responsiveHeight(1),
              })}
              title={'Revenir sur ma position'}
              onPress={() => {
                mapRef.current?.animateToRegion({
                  latitude: currentLatitude,
                  longitude: currentLongitude,
                  latitudeDelta: 0.01,
                  longitudeDelta: 0.01,
                });
                setDistanceLevel(25);
                setSelectedCity('');
                setFilterDistance(false);
              }}
            />
          ) : null}
          <PlaceHorizontalList
            data={
              availabilitySelected === 'OPEN_NOW' ||
              availabilitySelected === 'IN_THREE_DAYS'
                ? newInstituteList
                : institutesData
            }
            navigation={navigation}
            containerCustomStyle={{
              position: 'absolute',
              bottom: isWeb ? 30 : 100,
              left: 0,
              right: 0,
              width: '100%',
            }}
            isMap={true}
            onScroll={index => {
              mapRef.current?.animateToRegion({
                latitude: institutesData[index].coordinates.geopoint.latitude,
                longitude: institutesData[index].coordinates.geopoint.longitude,
                latitudeDelta: 0.01,
                longitudeDelta: 0.01,
              });
            }}
          />
        </View>
      </View>
    </Page>
  );
};
