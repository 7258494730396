import {icons} from '../assets';
import React from 'react';
import {Text, Image, TouchableOpacity, Switch, Platform} from 'react-native';
import {Fonts, gutters, Palette, Style} from '../styles';
import {SwitchWeb} from './index';

const OptionRow = ({
  title = 'Prix',
  titleStyle = {},
  onPress = () => undefined,
  divider = false,
  style = {},
  showSwitch = false,
  push = false,
  onSwitchChange = () => undefined,
  switchValue = true,
  ios_backgroundColor = '',
  onSwitchValueChange = () => undefined,
  switchTrackColor = {},
}) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={showSwitch}
      style={[
        Style.con({
          direc: 'row',
          items: 'center',
          justify: 'space-between',
          py: 20,
          px: gutters,
        }),
        divider && Style.border({bbw: 1, color: Palette.gray[5]}),
        style,
      ]}>
      <Text style={[Fonts.t(15, Palette.black), titleStyle]}>{title}</Text>
      {push && (
        <Image style={Style.con({size: 24})} source={icons.arrowRight} />
      )}
      {showSwitch && (
        <>
          {Platform.OS === 'web' ? (
            <SwitchWeb value={switchValue} setValue={onSwitchChange} />
          ) : (
            <Switch
              value={switchValue}
              onChange={onSwitchChange}
              ios_backgroundColor={ios_backgroundColor}
              onValueChange={onSwitchValueChange}
              trackColor={switchTrackColor}
              thumbColor={Palette.white}
              {...Platform.select({
                web: {
                  activeThumbColor: Palette.white,
                },
              })}
            />
          )}
        </>
      )}
    </TouchableOpacity>
  );
};

export default OptionRow;
