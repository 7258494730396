import React, {useGlobal, useState} from 'reactn';
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  ScrollView,
  TextInput,
  Alert,
} from 'react-native';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import ImagePickers from '../components/ImagePicker';
import {
  responsiveHeight,
  responsiveWidth,
} from 'react-native-responsive-dimensions';

import {Style, Fonts, Palette, gutters} from '../styles';
import {images, icons} from '../assets';
import {Button} from '../components';
import firebase, {usersRef} from '../config/firebase';
import {uploadPictureToFirebase} from '../helpers/uploadToFirebase';
import Page from '../components/Page';
import {isDesktopWeb} from '../config/layoutConstants';
import {resetToPage} from '../navigation/NavigationService';
import {Routes as ROUTES} from '../navigation';

export default ({navigation}) => {
  const [currentUserData] = useGlobal('currentUserData');
  const [, setIsGlobalLoading] = useGlobal('_isLoading');
  const [, setTooltip] = useGlobal('_tooltip');
  const db = firebase.firestore();

  const currentUser = firebase.auth().currentUser;

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [viewPassword, setViewPassword] = useState(true);

  const currentFirstName = currentUserData.contactDetails.firstName;
  const [changeFirstName, setChangeFirstName] = useState(currentFirstName);

  const currentLastName = currentUserData.contactDetails.lastName;
  const [changeLastName, setChangeLastName] = useState(currentLastName);

  const currentEmail = currentUserData.contactDetails.email;
  const [changeEmail, setChangeEmail] = useState(currentEmail);

  const currentPhone = currentUserData.contactDetails.phone;
  const [changePhone, setChangePhone] = useState(currentPhone);

  const onPressEnd = () => navigation?.goBack();
  const onPressCancel = () => navigation?.goBack();

  const onPressChangePassword = async () => {
    try {
      await setIsGlobalLoading(true);
      if (newPassword !== confirmPassword) {
        await setTooltip({text: 'Les mots de passe ne correspondent pas'});
      }

      const credentials = firebase.auth.EmailAuthProvider.credential(
        currentUserData.contactDetails.email,
        currentPassword,
      );
      await firebase
        .auth()
        .currentUser.reauthenticateWithCredential(credentials);
      await firebase.auth().currentUser.updatePassword(newPassword);
      await setTooltip({text: 'Mot de passe changé avec succès'});
      navigation?.goBack();
    } catch (error) {
      console.log(error.message);
    } finally {
      await setIsGlobalLoading(false);
    }
  };

  const onPressResetPassword = async () => {
    try {
      await setIsGlobalLoading(true);
      await firebase
        .auth()
        .sendPasswordResetEmail(currentUserData.contactDetails.email);
      await setTooltip({
        text: 'Un email de réinitialisation de mot de passe vous a été envoyé',
      });
    } catch (error) {
      console.log(error.message);
    } finally {
      await setIsGlobalLoading(false);
    }
  };

  const onPressChangeInformation = async () => {
    try {
      await setIsGlobalLoading(true);
      if (
        currentFirstName !== changeFirstName ||
        currentLastName !== changeLastName
      ) {
        await db.collection('users').doc(currentUserData.id).update({
          'contactDetails.firstName': changeFirstName,
          'contactDetails.lastName': changeLastName,
        });
      }
      if (currentEmail !== changeEmail) {
        await currentUser.updateEmail(changeEmail);
        await db.collection('users').doc(currentUserData.id).update({
          'contactDetails.email': changeEmail,
        });
      }
      if (currentPhone !== changePhone) {
        await db.collection('users').doc(currentUserData.id).update({
          'contactDetails.phoneNumber': changePhone,
        });
      }
      await setTooltip({text: 'Informations changées avec succès'});
    } catch (error) {
      console.log(error.message);
    } finally {
      await setIsGlobalLoading(false);
      navigation.goBack();
    }
  };

  function askToDeleteAccount(confirm = false) {
    Alert.alert(
      confirm ? 'Attention' : 'Supprimer le compte',
      confirm
        ? 'Une fois votre compte supprimer vos données seront effacées et vous ne pourrez plus vous connecter.'
        : 'Êtes-vous sûr de vouloir supprimer votre compte ?',
      [
        {
          text: 'Annuler',
          style: 'cancel',
        },
        {
          text: confirm ? 'Oui supprimer mon compte' : 'Supprimer mon compte',
          style: 'destructive',
          onPress: () => {
            if (!confirm) {
              askToDeleteAccount(true);
            } else {
              db.collection('users').doc(currentUserData.id).delete();
              resetToPage(navigation, ROUTES.Home);
            }
          },
        },
      ],
    );
  }

  return (
    <Page>
      <View
        style={[
          Style.con({
            direc: 'row',
            items: 'center',
            mt: responsiveHeight(1),
            justify: 'space-between',
            px: gutters,
            h: responsiveHeight(6),
          }),
          Style.border({bbw: 1, color: Palette.gray[4]}),
        ]}>
        <TouchableOpacity
          onPress={onPressCancel}
          style={Style.con({w: 60, cen: true})}>
          <Text style={Fonts.t(13, Palette.text[1])}>{'Annuler'}</Text>
        </TouchableOpacity>
        <View style={Style.con({flex: 1, cen: true})}>
          <Text style={Fonts.t(15, Palette.text[1], {wei: '700'})}>
            {'Mon compte'}
          </Text>
        </View>
        <TouchableOpacity
          onPress={onPressEnd}
          style={Style.con({w: 60, cen: true})}>
          <Text style={Fonts.t(13, Palette.primary, {wei: '700'})}>
            {'Terminé'}
          </Text>
        </TouchableOpacity>
      </View>
      <KeyboardAwareScrollView
        keyboardDismissMode={'interactive'}
        keyboardShouldPersistTaps={'handled'}
        contentInsetAdjustmentBehavior={'automatic'}
        enableOnAndroid={true}
        extraHeight={160}
        style={Style.con({flex: 1, pt: 0})}
        scrollEnabled>
        <ScrollView
          style={Style.con({flex: 1})}
          contentContainerStyle={Style.con({pb: 80})}>
          <ImagePickers
            onComplete={async ({uri}) => {
              const uriFireStore = await uploadPictureToFirebase({
                uri,
                path: 'users/' + currentUserData.id + '/profilePicture.jpg',
              });
              await db.collection('users').doc(currentUserData.id).update({
                profilPicture: uriFireStore.resultURI,
              });
            }}
            style={Style.con({items: 'center'})}>
            {currentUserData.profilPicture ? (
              <Image
                style={[
                  Style.con({
                    size: responsiveWidth(30),
                    bor: responsiveWidth(30),
                    mt: 20,
                  }),
                  {maxWidth: 200, maxHeight: 200},
                ]}
                source={{uri: currentUserData?.profilPicture || ''}}
              />
            ) : (
              <View
                style={[
                  Style.con({
                    size: responsiveWidth(30),
                    bor: responsiveWidth(30),
                    mt: 20,
                    bg: Palette.gray[4],
                    justify: 'center',
                  }),
                  {maxWidth: 200, maxHeight: 200},
                ]}>
                <Text
                  style={Fonts.t(30, Palette.text[1], {
                    wei: '700',
                    mt: 20,
                    text: 'center',
                  })}>
                  {`${(currentUserData?.contactDetails?.firstName).charAt(
                    0,
                  )} ${(currentUserData?.contactDetails?.lastName).charAt(0)}`}
                </Text>
              </View>
            )}
            <Text
              style={Fonts.t(12, Palette.primary, {
                wei: '700',
                t: 14,
              })}>
              {'Modifier la photo de profil'}
            </Text>
          </ImagePickers>
          <Text
            style={Fonts.t(15, Palette.black, {
              wei: '600',
              x: 30,
              t: 40,
              b: 12,
            })}>
            {'Mes coordonnées'}
          </Text>
          <View
            style={[
              Style.con({
                direc: 'row',
                items: 'center',
                py: responsiveHeight(2),
                px: gutters,
              }),
              Style.border({bbw: 1, color: Palette.gray[4]}),
            ]}>
            <View style={Style.con({w: 150})}>
              <Text style={Fonts.t(13, Palette.text[1])}>{'Nom'}</Text>
            </View>
            <TextInput
              style={Fonts.t(13, Palette.text[1])}
              defaultValue={`${currentUserData?.contactDetails?.lastName}`}
              onChangeText={e => setChangeLastName(e)}
              autoCorrect={false}
            />
          </View>
          <View
            style={[
              Style.con({
                direc: 'row',
                items: 'center',
                py: responsiveHeight(2),
                px: gutters,
              }),
              Style.border({bbw: 1, color: Palette.gray[4]}),
            ]}>
            <View style={Style.con({w: 150})}>
              <Text style={Fonts.t(13, Palette.text[1])}>{'Prénom'}</Text>
            </View>
            <TextInput
              style={Fonts.t(13, Palette.text[1])}
              defaultValue={`${currentUserData?.contactDetails?.firstName}`}
              onChangeText={e => setChangeFirstName(e)}
              autoCorrect={false}
            />
          </View>
          <View
            style={[
              Style.con({
                direc: 'row',
                items: 'center',
                py: 24,
                px: gutters,
              }),
              Style.border({bbw: 1, color: Palette.gray[4]}),
            ]}>
            <View style={Style.con({w: 150})}>
              <Text style={Fonts.t(13, Palette.text[1])}>{'Email'}</Text>
            </View>
            <TextInput
              style={Fonts.t(13, Palette.text[1])}
              defaultValue={currentUserData?.contactDetails?.email}
              onChangeText={e => setChangeEmail(e.trim())}
              autoCorrect={false}
              autoCapitalize={'none'}
              keyboardType={'email-address'}
            />
          </View>
          <View
            style={[
              Style.con({
                direc: 'row',
                items: 'center',
                py: responsiveHeight(2),
                px: gutters,
              }),
              Style.border({bbw: 1, color: Palette.gray[4]}),
            ]}>
            <View style={Style.con({w: 150})}>
              <Text style={[Style.con({w: 100}), Fonts.t(13, Palette.text[1])]}>
                {'Numéro \nde téléphone'}
              </Text>
            </View>
            <View
              style={Style.con({
                direc: 'row',
                items: 'center',
                p: 14,
                bor: 10,
                bg: Palette.gray[2],
              })}>
              <Image
                style={[
                  Style.con({w: 27, h: 22}),
                  Style.border({color: Palette.gray[13], width: 1, bor: 5}),
                ]}
                source={images.franceFlag}
              />
              <TextInput
                style={[Style.con({pl: 10}), Fonts.t(13, Palette.text[1])]}
                defaultValue={currentUserData?.contactDetails?.phoneNumber}
                onChangeText={e => setChangePhone(e)}
              />
              {/*<Text*/}
              {/*  style={Fonts.t(13, Palette.text[1], {l: responsiveWidth(4)})}>*/}
              {/*  {currentUserData?.contactDetails?.phoneNumber || ''}*/}
              {/*</Text>*/}
            </View>
          </View>
          <Button
            title="Modifier mes coordonnées"
            style={Style.con({mx: gutters, my: responsiveHeight(2)})}
            onPress={onPressChangeInformation}
          />
          <Text
            style={Fonts.t(15, Palette.black, {
              wei: '600',
              x: gutters,
              t: responsiveHeight(4),
            })}>
            {'Mot de passe'}
          </Text>
          <Text
            style={Fonts.t(12, Palette.gray[14], {
              wei: '600',
              x: gutters,
              y: responsiveHeight(1),
            })}>
            {
              'Pour modifier votre mot de passe, veuillez confirmer votre identité en  saisissant votre mot de passe actuel.'
            }
          </Text>
          <View
            style={Style.con({
              px: gutters,
              my: responsiveHeight(1),
            })}>
            <Text
              style={[
                Fonts.t(13, Palette.text[1]),
                Style.con({mb: responsiveHeight(1)}),
              ]}>
              {'Mot de passe actuel'}
            </Text>
            <View
              style={Style.con({
                h: 48,
                direc: 'row',
                w: 'auto',
                px: isDesktopWeb ? responsiveWidth(1) : gutters,
                bg: Palette.gray[2],
                bor: 10,
                mb: responsiveHeight(1),
              })}>
              <TextInput
                placeholder="Mon mot de passe actuel"
                style={[
                  Style.con({
                    mb: isDesktopWeb ? 0 : responsiveHeight(1),
                    flex: 1,
                    pr: 8,
                  }),
                  Fonts.t(12, Palette.black, {wei: '700'}),
                ]}
                value={currentPassword}
                placeholderTextColor={Palette.gray[1]}
                onChangeText={e => setCurrentPassword(e.trim())}
                secureTextEntry={viewPassword}
                autoCapitalize={'none'}
                autoCorrect={false}
              />
              <TouchableOpacity
                onPress={() => setViewPassword(!viewPassword)}
                style={Style.con({self: 'center'})}>
                <Image
                  source={viewPassword ? icons.iconEyeClose : icons.iconEyeOpen}
                  style={Style.con({size: 24})}
                />
              </TouchableOpacity>
            </View>
          </View>
          <View
            style={Style.con({
              px: gutters,
              my: responsiveHeight(1),
            })}>
            <Text
              style={[
                Fonts.t(13, Palette.text[1]),
                Style.con({mb: responsiveHeight(1)}),
              ]}>
              {'Nouveau mot de passe'}
            </Text>
            <View
              style={Style.con({
                h: 48,
                direc: 'row',
                w: 'auto',
                px: isDesktopWeb ? responsiveWidth(1) : gutters,
                bg: Palette.gray[2],
                bor: 10,
                mb: responsiveHeight(1),
              })}>
              <TextInput
                placeholder="Mon nouveau mot de passe"
                style={[
                  Style.con({
                    mb: isDesktopWeb ? 0 : responsiveHeight(1),
                    flex: 1,
                    pr: 8,
                  }),
                  Fonts.t(12, Palette.black, {wei: '700'}),
                ]}
                value={newPassword}
                placeholderTextColor={Palette.gray[1]}
                onChangeText={e => setNewPassword(e.trim())}
                secureTextEntry={viewPassword}
                autoCapitalize={'none'}
                autoCorrect={false}
              />
              <TouchableOpacity
                onPress={() => setViewPassword(!viewPassword)}
                style={Style.con({self: 'center'})}>
                <Image
                  source={viewPassword ? icons.iconEyeClose : icons.iconEyeOpen}
                  style={Style.con({size: 24})}
                />
              </TouchableOpacity>
            </View>
          </View>
          <View
            style={Style.con({
              px: gutters,
              mt: responsiveHeight(1),
              mb: responsiveHeight(3),
            })}>
            <Text
              style={[
                Fonts.t(13, Palette.text[1]),
                Style.con({mb: responsiveHeight(1)}),
              ]}>
              {'Confirmer le mot de passe'}
            </Text>
            <View
              style={Style.con({
                h: 48,
                direc: 'row',
                w: 'auto',
                px: isDesktopWeb ? responsiveWidth(1) : gutters,
                bg: Palette.gray[2],
                bor: 10,
                mb: responsiveHeight(1),
              })}>
              <TextInput
                placeholder="Mon nouveau mot de passe"
                style={[
                  Style.con({
                    mb: isDesktopWeb ? 0 : responsiveHeight(1),
                    flex: 1,
                    pr: 8,
                  }),
                  Fonts.t(12, Palette.black, {wei: '700'}),
                ]}
                value={confirmPassword}
                placeholderTextColor={Palette.gray[1]}
                onChangeText={e => setConfirmPassword(e.trim())}
                secureTextEntry={viewPassword}
                autoCapitalize={'none'}
                autoCorrect={false}
              />
              <TouchableOpacity
                onPress={() => setViewPassword(!viewPassword)}
                style={Style.con({self: 'center'})}>
                <Image
                  source={viewPassword ? icons.iconEyeClose : icons.iconEyeOpen}
                  style={Style.con({size: 24})}
                />
              </TouchableOpacity>
            </View>
          </View>
          <Button
            title="Modifier mon mot de passe"
            style={Style.con({mx: gutters, mb: responsiveHeight(1)})}
            onPress={onPressChangePassword}
          />
          <Button
            title="Réinitialiser mon mot de passe"
            primary={false}
            onPress={onPressResetPassword}
            style={Style.con({mx: gutters, mb: responsiveHeight(1)})}
          />
          <Button
            title="Supprimer mon compte"
            primary={false}
            onPress={() => askToDeleteAccount(false)}
            style={Style.con({mx: gutters})}
          />
        </ScrollView>
      </KeyboardAwareScrollView>
    </Page>
  );
};
