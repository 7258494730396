import React from 'react';
import { FlatList, Text, TouchableOpacity, View, Image } from 'react-native';
import { Style, Palette, Fonts, gutters } from '../../styles';
import { Rating } from '../../helpers/rating';
import { icons } from '../../assets';

export default ({
    horizontal = true,
    containerCustomStyle = {},
    showsHorizontalScrollIndicator = false,
    style = {},
    data = [],
    itemStyle = {},
    navigateToInstitute = () => undefined,
}) => {
    const content = (institute, instituteId, ratingStart) => {
        return(
          <TouchableOpacity
            onPress={() => {
                navigateToInstitute({ ...institute, instituteId })
            }}
            key={instituteId}
            style={[
              Style.con({
                bg: Palette.white,
                bor: 15,
                h: 158,
                w: 221,
                ml: 16,
                over: "hidden",
              }),
              itemStyle,
            ]}
          >
            <Image
              style={Style.con({ h: 60, w: 221 })}
              source={{ uri: institute?.coverPicture }}
            />
            <View
              style={[
                Style.con({
                  flex: 1,
                  py: 12,
                  px: gutters / 2,
                  justify: "space-between",
                }),
              ]}
            >
              <Text style={Fonts.t(15, Palette.black, { wei: "700" })}>
                {institute?.name}
              </Text>
              <Text
                numberOfLines={2}
                ellipsizeMode="tail"
                style={Fonts.t(13, Palette.black, { wei: "400" })}
              >
                {`📍 ${institute?.location?.addressIns} ${institute?.location?.postCode} ${institute?.location?.city} `}
              </Text>
              <View style={Style.con({ direc: "row", items: "center" })}>
                {ratingStart ? (
                  <>
                    <Text style={Fonts.t(13, Palette.black, { wei: "400" })}>
                      {institute.ratingNote}
                    </Text>
                    <View
                      style={Style.con({direc: "row", items: "center", ml: 10,})}
                    >
                      {Array(ratingStart)
                        .fill('')
                        .map((v, i) => (
                          <Image
                            key={i}
                            style={Style.con({ size: 12 })}
                            source={icons.iconStar}
                          />
                        ))
                      }
                    </View>
                    <Text style={Fonts.t(13, Palette.black, { wei: "400", l: 10 })}>
                      {`${institute?.reviewsId?.length || 0} avis`}
                    </Text>
                  </>
                  ) : (
                    <Text style={Fonts.t(13, Palette.black, { wei: "400" })}>
                      Aucun avis publié
                    </Text>
                )}
              </View>
            </View>
          </TouchableOpacity>
        )
      }
    return(
        <FlatList 
            data={data}
            horizontal = {horizontal}
            style={[containerCustomStyle]}
            showsHorizontalScrollIndicator = {showsHorizontalScrollIndicator}
            contentContainer={[Style.con({ h: 158 }), style]}
            keyExtractor={(item) => item.id}
            renderItem={({item: institute}) => {
                const instituteId = institute.objectID || institute.instituteId || institute.id;
                const ratingStart = Math.ceil(parseFloat(Rating(institute?.ratings)))

                return(content(institute, instituteId, ratingStart));
            }}
        />
    )
}