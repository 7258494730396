import React from 'react';
import {View, TextInput} from 'react-native';
import {Fonts, gutters, Palette, Style} from '../styles';

const Input = ({
  placeholder = 'Adresse mail',
  style = {},
  inputStyle = {},
  onChange = () => undefined,
  value = '',
  keyboardType = 'default',
  password = false,
  multiline = false,
  autoCapitalize = 'sentences',
  inputMode = 'text',
}) => {
  return (
    <View
      style={[
        Style.con({
          h: 48,
          direc: 'row',
          w: 'auto',
          px: gutters,
          bg: Palette.gray[2],
          bor: 10,
        }),
        style,
      ]}>
      <TextInput
        style={[
          Style.con({flex: 1}),
          Fonts.t(12, Palette.black, {wei: '700'}),
          inputStyle,
        ]}
        value={value}
        secureTextEntry={password}
        placeholderTextColor={Palette.gray[1]}
        placeholder={placeholder}
        onChangeText={onChange}
        keyboardType={keyboardType}
        multiline={multiline}
        autoCapitalize={autoCapitalize}
        inputMode={inputMode}
      />
    </View>
  );
};

export default Input;
