import React, {useState} from 'react';
import {useGlobal} from 'reactn';
import {Text, View, Image, TouchableOpacity, TextInput} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {responsiveHeight} from 'react-native-responsive-dimensions';
import firebase from '../../config/firebase';
import {Style, Fonts, Palette, gutters} from '../../styles';
import {Button, Input} from '../../components';
import {Routes} from '../../navigation';
import {icons} from '../../assets';
import {
  checkIfEmailIsValid,
  checkIfPasswordIsStrongEnough,
  handleFirebaseError,
  formatPhoneNumber,
} from '../../actions/signupActions';
import Page from '../../components/Page';

export default ({navigation}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mail, setMail] = useState('');
  const [phone, setPhone] = useState('');
  const [passwd, setPasswd] = useState('');
  const [, setIsGlobalLoading] = useGlobal('_isLoading');
  const [, setTooltip] = useGlobal('_tooltip');

  const [viewPassword, setViewPassword] = useState(true);
  const db = firebase.firestore();

  const onPressCreateAnAccount = async () => {
    try {
      await setIsGlobalLoading(true);

      if (!checkIfEmailIsValid(mail || '')) {
        await setTooltip({text: 'Email non valide'});
        setMail('');
      }
      if (!checkIfPasswordIsStrongEnough(passwd || '')) {
        await setTooltip({text: 'Mot de passe non valide.'});
        setPasswd('');
      }
      if (!formatPhoneNumber(phone || '')) {
        await setTooltip({text: 'Numéro de téléphone non valide'});
        setPhone('');
      }
      if (firstName.length < 2 > 30 && lastName.length < 2 > 30) {
        await setTooltip({text: 'Nom non valides'});
        setFirstName('');
        setLastName('');
      }

      if (
        firstName !== '' &&
        lastName !== '' &&
        mail !== '' &&
        passwd !== '' &&
        phone !== ''
      ) {
        const user = await firebase
          .auth()
          .createUserWithEmailAndPassword(mail, passwd);

        await firebase.auth().currentUser.sendEmailVerification();

        navigation?.navigate(Routes.AccountCreated, {
          user,
          firstName,
          lastName,
          mail,
          phone,
        });
      } else {
        await setTooltip({text: 'Veuillez remplir tous les champs'});
      }
    } catch (error) {
      await setTooltip({text: handleFirebaseError(error.code)});
    } finally {
      await setIsGlobalLoading(false);
    }
  };

  const onPressLoginWithGoogle = () => {
    // navigation?.navigate(Routes.AccountCreated);
  };
  const onPressLoginWithApple = async () => {
    /*    try {
      await setIsGlobalLoading(true);
      // Start the sign-in request
      const appleAuthRequestResponse = await appleAuth.performRequest({
        requestedOperation: appleAuth.Operation.LOGIN,
        requestedScopes: [appleAuth.Scope.EMAIL, appleAuth.Scope.FULL_NAME],
      });
      const result = appleAuthRequestResponse;
      const {identityToken, nonce, fullName, email = null} = result;

      if (identityToken) {
        const appleCredential = auth.AppleAuthProvider.credential(
          identityToken,
          nonce,
        );
        const user = await auth().signInWithCredential(appleCredential);
        console.log({user});
      } else {
        throw new Error('no identity token');
      }
    } catch (error) {
      console.log(error);
    } finally {
      await setIsGlobalLoading(false);
    } */
  };

  const onPressLoginWithFacebook = () => {
    // navigation?.navigate(Routes.AccountCreated);
  };

  return (
    <Page>
      <SafeAreaView style={Style.con({flex: 1, bg: Palette.white})}>
        {/* <Header
          // back
          navigation={navigation}
          withLogo={!isDesktopWeb}
          title={"S'inscrire"}
          style={Style.con({ mb: 150, py: 10 })}
        /> */}
        <KeyboardAwareScrollView
          keyboardDismissMode={'interactive'}
          keyboardShouldPersistTaps={'handled'}
          contentInsetAdjustmentBehavior={'automatic'}
          enableOnAndroid={true}
          extraHeight={160}
          style={Style.con({flex: 1})}
          scrollEnabled>
          <View style={Style.con({px: gutters})}>
            <View style={Style.con({mb: 30})}>
              <Image
                source={icons.wink_full_red}
                style={Style.con({h: 90, w: 205, mb: 20, self: 'center'})}
              />
              <Text
                style={Fonts.t(25, Palette.black, {
                  wei: '700',
                  text: 'center',
                })}>
                S'inscrire
              </Text>
            </View>
            <Input
              placeholder="Nom"
              style={Style.con({mb: responsiveHeight(1)})}
              value={lastName}
              onChange={e => setLastName(e)}
              autoCorrect={false}
            />
            <Input
              placeholder="Prénom"
              style={Style.con({mb: responsiveHeight(1)})}
              value={firstName}
              onChange={e => setFirstName(e)}
            />
            <Input
              placeholder="Adresse mail"
              style={Style.con({mb: responsiveHeight(1)})}
              keyboardType={'email-address'}
              value={mail}
              onChange={e => setMail(e)}
              autoCapitalize={'none'}
              autoCorrect={false}
            />
            <Input
              placeholder="Numéro de téléphone"
              style={Style.con({mb: responsiveHeight(1)})}
              value={phone}
              onChange={e => setPhone(e)}
              keyboardType={'phone-pad'}
            />
            <View
              style={Style.con({
                h: responsiveHeight(5.5),
                direc: 'row',
                w: 'auto',
                px: gutters,
                bg: Palette.gray[2],
                bor: 10,
                mb: responsiveHeight(1),
              })}>
              <TextInput
                placeholder="Mot de passe"
                style={[
                  Style.con({flex: 1, pr: 8}),
                  Fonts.t(12, Palette.black, {wei: '700'}),
                ]}
                value={passwd}
                placeholderTextColor={Palette.gray[1]}
                onChangeText={e => setPasswd(e.trim())}
                secureTextEntry={viewPassword}
                autoCapitalize={'none'}
                autoCorrect={false}
              />
              <TouchableOpacity
                onPress={() => setViewPassword(!viewPassword)}
                style={Style.con({self: 'center'})}>
                <Image
                  source={viewPassword ? icons.iconEyeClose : icons.iconEyeOpen}
                  style={Style.con({size: 24})}
                />
              </TouchableOpacity>
            </View>
            <Text
              style={Fonts.t(12, Palette.gray[6], {
                b: responsiveHeight(1),
                l: responsiveHeight(2),
              })}>
              Le mot de passe doit contenir au minimum 6 caractères, une
              majuscule, un chiffre et un caractère spécial.
            </Text>
            <Button
              title="Créer un compte"
              onPress={onPressCreateAnAccount}
              style={Style.con({mb: 16})}
            />
            <View style={Style.con({self: 'center', items: 'center'})}>
              <Text style={Fonts.t(12, Palette.gray[1], {wei: '700'})}>
                {'En vous inscrivant, vous acceptez nos'}
              </Text>
              <Text style={Fonts.t(12, Palette.primary, {wei: '700'})}>
                {"Conditions Générales d'Utilisation"}
              </Text>
            </View>
          </View>
          <View style={Style.con({px: gutters, py: responsiveHeight(2)})}>
            <Text
              style={Fonts.t(12, Palette.gray[1], {
                wei: '700',
                self: 'center',
                b: responsiveHeight(2),
              })}>
              {'Ou connectez-vous avec vos réseaux sociaux'}
            </Text>
            <View style={Style.con({direc: 'row'})}>
              <TouchableOpacity
                onPress={onPressLoginWithGoogle}
                style={[
                  Style.con({
                    flex: 1,
                    bg: Palette.gray[2],
                    cen: true,
                    py: responsiveHeight(0.5),
                  }),
                  Style.border({bor: 10, color: Palette.gray[3], width: 1}),
                ]}>
                <Image
                  style={Style.con({size: 36})}
                  source={icons.iconGoogle}
                />
              </TouchableOpacity>
              <View style={Style.con({w: 15})} />
              <TouchableOpacity
                onPress={onPressLoginWithApple}
                style={[
                  Style.con({
                    flex: 1,
                    bg: Palette.gray[2],
                    cen: true,
                    py: responsiveHeight(0.5),
                  }),
                  Style.border({bor: 10, color: Palette.gray[3], width: 1}),
                ]}>
                <Image style={Style.con({size: 36})} source={icons.iconApple} />
              </TouchableOpacity>
              <View style={Style.con({w: 15})} />
              <TouchableOpacity
                onPress={onPressLoginWithFacebook}
                style={[
                  Style.con({
                    flex: 1,
                    bg: Palette.gray[2],
                    cen: true,
                    py: responsiveHeight(0.5),
                  }),
                  Style.border({bor: 10, color: Palette.gray[3], width: 1}),
                ]}>
                <Image
                  style={Style.con({size: 36})}
                  source={icons.iconFacebook}
                />
              </TouchableOpacity>
            </View>
            <Text
              style={Fonts.t(12, Palette.gray[1], {
                wei: '700',
                self: 'center',
                t: 12,
              })}>
              {'Vous avez déjà un compte? '}
              <Text
                onPress={() => navigation?.navigate(Routes.Profile)}
                style={Fonts.t(12, Palette.primary, {wei: '700', l: 4})}>
                {' Connexion'}
              </Text>
            </Text>
          </View>
        </KeyboardAwareScrollView>
      </SafeAreaView>
    </Page>
  );
};
