import React, {useEffect} from 'react';
import {LogBox, View, Platform} from 'react-native';
import {setGlobal} from 'reactn';
import {GestureHandlerRootView} from 'react-native-gesture-handler';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {useFonts} from 'expo-font';
import {NavigationContainer, DefaultTheme} from '@react-navigation/native';
import moment from 'moment';
import 'moment/locale/fr';
import {MainStack} from './src/navigation';
import {Palette} from './src/styles';
import {navigationRef} from './src/navigation/NavigationService';
import UserDataProvider from './src/providers/UserDataProvider';
import ModalFilterProvider from './src/providers/ModalFilterProvider';
import InstituteRoutingProvider from './src/providers/InstituteRoutingProvider';
import LoadingProvider from './src/providers/LoadingProvider';
import TooltipProvider from './src/providers/TooltipProvider';

import initialGlobalState from './src/config/initialGlobalState';
import Location from './src/services/location';
import 'moment/locale/fr';
import {isDesktopWeb} from './src/config/layoutConstants';

console.disableYellowBox = true;
console.reportErrorsAsExceptions = false;

moment.locale('fr');
moment.updateLocale('fr', {
  // weekdays: [
  //   'Lundi',
  //   'Mardi',
  //   'Mercredi',
  //   'Jeudi',
  //   'Vendredi',
  //   'Samedi',
  //   'Dimanche',
  // ],
  week: {
    dow: 1,
  },
});

setGlobal(initialGlobalState);

const App = () => {
  const [fontsLoaded] = useFonts({
    Urbanist: require('./src/assets/fonts/Urbanist-Medium.ttf'),
  });

  useEffect(() => {
    // if (__DEV__) {
    //   LogBox.ignoreAllLogs();
    // }
    Location.start();
  }, []);

  const GestureContainer = isDesktopWeb ? View : GestureHandlerRootView;
  const style = {};

  if (!fontsLoaded) {
    return null;
  }

  return (
    <GestureContainer
      style={[{flex: 1, backgroundColor: Palette.white}, style]}>
      <TooltipProvider>
        <LoadingProvider>
          <UserDataProvider>
            <ModalFilterProvider>
              <SafeAreaProvider>
                <NavigationContainer
                  documentTitle={{
                    formatter: options =>
                      `${
                        options?.title ? `${options.title} -` : ''
                      } Wink Beauty`,
                  }}
                  theme={{
                    ...DefaultTheme,
                    colors: {...DefaultTheme.colors, background: 'white'},
                  }}
                  ref={navigationRef}>
                  <InstituteRoutingProvider>
                    <MainStack />
                  </InstituteRoutingProvider>
                </NavigationContainer>
              </SafeAreaProvider>
            </ModalFilterProvider>
          </UserDataProvider>
        </LoadingProvider>
      </TooltipProvider>
    </GestureContainer>
  );
};

export default App;
