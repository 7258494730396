import React from "react";
import { Text, View, ScrollView, Image, TouchableOpacity } from "react-native";
import { responsiveHeight } from "react-native-responsive-dimensions";

import { Style, Fonts, Palette, gutters } from "../styles";
import { icons } from "../assets";
import { Header } from "../components";

import Page from "../components/Page";

export const FAQ = [
  {
    question: "Comment fonctionne Wink ?",
    answer:
      "Wink est une application de réservation de prestations beauté, regroupant des professionnels du secteur. Elle vous permet de bénéficier d'une recherche ciblée par le biais des filtres des salons ou instituts, tout en ayant un aperçu direct du résultat final de la prestation, grâce au contenu publié par ces derniers.",
  },
  {
    question: "Comment trouver une prestation ?",
    answer:
      "Sur l’accueil de votre application, vous pouvez cliquer directement sur ‘Je prend rendez-vous’ pour commencer à rechercher un salon ou un institut. Vous pouvez également choisir la catégorie de prestations souhaitée, en cliquant sur les pictogrammes en haut, vous serez redirigé ensuite vers une liste des salons concernés. Vous pouvez également rechercher manuellement une prestation en appuyant sur l’icône de recherche et en tapant le nom de votre prestation.",
  },
  {
    question: "Comment trouver un institut ?",
    answer:
      "Vous pouvez trouver un institut en cliquant sur le bouton ‘Je prend rendez-vous’ ou sur une des catégories qui se trouve en haut sur l’accueil. Vous pouvez également taper le nom d’un institut dans la barre de recherche ainsi que sur l’onglet carte. Des filtres sont à disposition en haut à droite, vous permettant de personnaliser la recherche comme : l’institut le plus proche de chez vous ou d’un lieu donné ; le salon ou l’institut et la prestation disponible le jour même ou à une date personnalisée.",
  },
  {
    question: "Comment mettre un salon dans mes favoris ?",
    answer:
      "Vous pouvez ajouter votre salon ou institut préféré dans l’onglet ‘Favoris’ sur votre compte (vous pouvez y accéder simplement en appuyant sur l’icône utilisateur en bas à droite). Vous pouvez également enregistrer les photos de vos prestations préférées afin de les retrouver plus rapidement.",
  },
  {
    question: "Comment laisser un avis après un rendez-vous ?",
    answer:
      "Vous pouvez laisser un commentaire dans un salon ou un institut seulement si vous avez eu un rendez-vous pris via l'application. Lorsque la prestation est passée, une notification est envoyée dans les 24-48 heures suivants le rendez-vous, vous pourrez à ce moment-là, laisser un avis. Un avis est constitué d'une note, d'un commentaire et d'une photo (facultative) de la prestation. Les notes attribuées sont sur 5 étoiles (5 étant la meilleure note), pouvant s'appuyer sur les critères suivants tels que accueil, propreté, ambiance et qualité de la prestation.",
  },
];

export default ({ navigation }) => {
  return (
    <Page>
      <Header title="Aide" back navigation={navigation} />
      <ScrollView
        style={Style.con({flex: 1, paddingBottom: responsiveHeight(30)})}>
        <Text
          style={Fonts.t(15, Palette.black, {
            wei: '600',
            y: responsiveHeight(5),
            x: gutters,
          })}>
          {'FAQ'}
        </Text>
        <View style={Style.con({h: 1, bg: Palette.gray[5]})} />
        {FAQ.map(({question = '', answer = ''}, index) => {
          const [isOpened, setOpened] = React.useState(false);
          const toggleOpened = () => setOpened(!isOpened);

          return (
            <View key={index}>
              <TouchableOpacity
                onPress={() => toggleOpened(index)}
                style={[
                  Style.con({
                    direc: 'row',
                    items: 'center',
                    justify: 'space-between',
                    py: 20,
                    px: gutters,
                  }),
                  Style.border({bbw: 1, color: Palette.gray[5]}),
                ]}>
                <Text style={[Fonts.t(15, Palette.black)]}>{question}</Text>
                <Image
                  style={Style.con({
                    size: 24,
                    rotate: isOpened ? '90deg' : '0deg',
                  })}
                  source={icons.arrowRight}
                />
              </TouchableOpacity>
              {isOpened && (
                <View style={Style.con({px: gutters, py: 20})}>
                  <Text style={[Fonts.t(15, Palette.black)]}>{answer}</Text>
                </View>
              )}
            </View>
          );
        })}
      </ScrollView>
    </Page>
  );
};
