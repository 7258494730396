import React from 'react';
import {Text, View, ScrollView, Image} from 'react-native';
import {
  responsiveHeight,
  responsiveWidth,
} from 'react-native-responsive-dimensions';
import {CommonActions} from '@react-navigation/native';
import {Style, Fonts, Palette, gutters} from '../../styles';
import {icons} from '../../assets';
import {Header, Button} from '../../components';
import {Routes} from '../../navigation';
import {useNavigation} from '@react-navigation/native';
import moment from 'moment';
import {isDesktopWeb} from '../../config/layoutConstants';
import Page from '../../components/Page';

export default ({route}) => {
  const navigation = useNavigation();
  const {
    instituteProfilPicture,
    instituteName,
    meetingDate,
    prestations,
    meetingRef,
  } = route?.params || {};

  const onPressHome = () =>
    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [{name: Routes.BottomTab}],
      }),
    );

  return (
    <Page>
      <Header title="Reservation Validée" navigation={navigation} />
      <ScrollView style={Style.con({flex: 1})}>
        <Text
          style={Fonts.t(27, Palette.black, {
            wei: '600',
            self: 'center',
            y: responsiveHeight(5),
            x: gutters,
          })}>
          {'Merci !'}
        </Text>
        <View
          style={Style.con({
            h: 1,
            bg: Palette.gray[5],
            mb: responsiveHeight(2),
          })}
        />
        <View
          style={[
            Style.con({
              direc: 'row',
              items: 'center',
              px: gutters,
              py: isDesktopWeb ? responsiveHeight(2) : 30,
              mx: gutters,
              bg: Palette.gray[2],
              bor: 15,
              mb: isDesktopWeb ? '10%' : responsiveHeight(3.5),
            }),
            {
              // minHeight: 180,
            },
          ]}>
          <View
            style={Style.con({
              bor: 5,
              cen: true,
              bg: Palette.gray[17],
            })}>
            <Image
              style={Style.con({size: 78, bor: 5, bg: Palette.primary})}
              source={{
                uri: instituteProfilPicture
                  ? instituteProfilPicture
                  : meetingRef?.instituteData?.instituteProfilPicture,
              }}
            />
          </View>
          <View style={Style.con({flex: 1, ml: responsiveWidth(6)})}>
            <Text
              style={[
                Fonts.t(15, Palette.black, {
                  wei: '700',
                }),
                {minWidth: isDesktopWeb ? 'fit-content' : null},
              ]}>
              {`${
                moment(meetingDate).format('dddd DD ').charAt(0).toUpperCase() +
                moment(meetingDate).format('dddd DD ').slice(1)
              }${
                moment(meetingDate)
                  .format('MMMM [•] HH[h]mm')
                  .charAt(0)
                  .toUpperCase() +
                moment(meetingDate).format('MMMM [•] HH[h]mm').slice(1)
              }`}
            </Text>
            <Text
              style={[
                Fonts.t(13, Palette.black, {t: 6}),
                {minWidth: isDesktopWeb ? 'fit-content' : null},
              ]}>
              {prestations !== undefined
                ? prestations.length > 1
                  ? `${instituteName} • ${prestations?.length} prestations`
                  : `${instituteName} • ${prestations?.length} prestation`
                : meetingRef.prestationData.length > 1
                ? `${instituteName} • ${meetingRef?.prestationData?.length} prestations`
                : `${instituteName} • ${meetingRef?.prestationData?.length} prestation`}
            </Text>
          </View>
        </View>
        <View
          style={Style.con({
            direc: 'row',
            items: 'center',
            self: 'center',
          })}>
          <Image style={Style.con({size: 23})} source={icons.iconInfo} />
          <Text style={Fonts.t(12, Palette.black, {l: responsiveWidth(2)})}>
            {
              'Une notification de rappel vous sera envoyé la veille de\n votre rendez-vous afin que vous confirmiez \n votre présence.'
            }
          </Text>
        </View>
      </ScrollView>
      <Button
        title={"Retournez à l'accueil"}
        onPress={onPressHome}
        primary={false}
      />
    </Page>
  );
};
