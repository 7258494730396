import moment from "moment";

export function openToday(openingHours) {
  const today = new Date().getDay() - 1;
  const todayOpeningHours = openingHours[today].timeRanges;
  let isOpen = false;

  if (todayOpeningHours) {
    const now = moment().format("HH:mm");
    isOpen = todayOpeningHours.some(({ startTime, endTime }) =>
      moment(now, "HH:mm").isBetween(
        moment(startTime, "HH:mm"),
        moment(endTime, "HH:mm")
      )
    );
  }
  return isOpen;
}

export function openThreeDays(openingHours) {
  const inThreeDays = moment().add(3, "days").get('weekday');
  return openingHours[inThreeDays].timeRanges.length > 0;
}
