import React, {useGlobal} from 'reactn';
import {Text, View, TouchableOpacity} from 'react-native';
import {responsiveHeight} from 'react-native-responsive-dimensions';

import {Fonts, gutters, Palette, Style} from '../styles';
import {Button, PickerMenu, Spacer} from '../components';

export const AvailableFilterUI = ({
  availableModes = [],
  onReset = () => {},
  onApply = () => {},
}) => {
  const [availabilitySelected, setAvailabilitySelected] = useGlobal(
    'availabilitySelected',
  );

  return (
    <View style={Style.con({flex: 1, justify: 'space-between', mb: 20})}>
      <View>
        <Text
          style={Fonts.t(17, Palette.black, {
            wei: '700',
            text: 'center',
            t: responsiveHeight(1.5),
          })}>
          {'Disponibilités'}
        </Text>
        <View style={Style.con({px: gutters, mb: 24})}>
          {Array.isArray(availableModes) &&
            availableModes.map(item => {
              const {mode, displayName} = item;
              const isSelected = availabilitySelected === mode;
              return (
                <TouchableOpacity
                  key={mode}
                  onPress={() => setAvailabilitySelected(mode)}
                  style={Style.con({
                    direc: 'row',
                    justify: 'space-between',
                    items: 'center',
                    py: 20,
                  })}>
                  <Text style={Fonts.t(15, Palette.black)}>{displayName}</Text>
                  <View
                    style={[
                      // Style.con({size: 20, cen: true}),
                      Style.border({
                        bor: 10,
                        width: 0.5,
                        color: Palette.gray[10],
                      }),
                    ]}>
                    <View
                      style={Style.con({
                        size: 13,
                        bor: 7,
                        bg: isSelected ? Palette.primary : Palette.gray[2],
                      })}
                    />
                  </View>
                </TouchableOpacity>
              );
            })}
        </View>
      </View>
      <View>
        <Button
          title="Appliquer"
          onPress={() => onApply()}
          style={Style.con({mx: gutters, mb: 6})}
        />
        <Button
          primary={false}
          title="Réinitialiser"
          onPress={() => onReset()}
          style={Style.con({mx: gutters})}
        />
      </View>
    </View>
  );
};

export default AvailableFilterUI;
