import firebase from 'firebase/compat/app';
import {
  initializeAuth,
  getReactNativePersistence,
} from 'firebase/auth/react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {isWeb} from './layoutConstants';

import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/functions';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyAxS1Lw-l8laAcb4pqRj9FXSOW6l5w9PBM',
  authDomain: 'beautyspot-f58bf.firebaseapp.com',
  projectId: 'beautyspot-f58bf',
  storageBucket: 'beautyspot-f58bf.appspot.com',
  messagingSenderId: '738962483977',
  appId: '1:738962483977:web:fb51e5074106001d0c41d8',
  measurementId: 'G-QQYZ5P79LT',
};

if (!firebase?.apps?.filter(({name_}) => name_ === '[DEFAULT]').length) {
  const defaultApp = firebase.initializeApp(firebaseConfig, {});

  if (!isWeb) {
    initializeAuth(defaultApp, {
      persistence: getReactNativePersistence(AsyncStorage),
    });
  }

  if (__DEV__) {
    firebase.functions().useEmulator('localhost', 5001);
  }
  console.log('Firebase init');
}

export const usersRef = firebase.firestore().collection('users');
export const postsRef = firebase.firestore().collection('posts');
export const institutesRef = firebase.firestore().collection('institutes');
export const reviewsRef = firebase.firestore().collection('reviews');
export const prestationsRef = firebase.firestore().collectionGroup('presta');
export const meetingsRef = firebase.firestore().collection('meetings');

export const {arrayUnion, arrayRemove, increment} =
  firebase.firestore.FieldValue;

export default firebase;
