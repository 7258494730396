import {CommonActions} from '@react-navigation/native';
import React from 'react';

export const navigationRef = React.createRef();

export function resetToPage(navigation, pageName, params = {}) {
  navigation.dispatch(
    CommonActions.reset({
      index: 0,
      routes: [{name: pageName, params}],
    }),
  );
}

export function navigate(name, params) {
  navigationRef.current?.navigate(name, params);
}

export function dispatch(route) {
  navigationRef.current?.dispatch(route);
}

export function goBack() {
  navigationRef.current?.goBack();
}
