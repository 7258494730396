import React, { useGlobal, useState } from "reactn";
import { Text, View, Image, TouchableOpacity } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { responsiveHeight } from "react-native-responsive-dimensions";
import moment from "moment";
import { useNavigation } from "@react-navigation/native";

import { Style, Fonts, Palette, gutters } from "../styles";
import { Button, Header, Input } from "../components";
import { Routes } from "../navigation";
import { DIMENSION_WIDTH } from "../common";
import useDataFromRef from "../hooks/useDataFromRef";

import firebase from "../config/firebase";
import { isDesktopWeb } from "../config/layoutConstants";
import Page from '../components/Page';

export default ({ route }) => {
  const {
    prestations,
    instituteName,
    instituteId,
    instituteProfilPicture,
    instituteLocation,
    daySelected = new Date(),
    hourSelected,
    withWhom,
  } = route?.params || {};

  const navigation = useNavigation();

  const hourArraySplitted = hourSelected.split(":");
  const meetingDate = moment(daySelected, "DD/MM/YYYY").set({
    hour: hourArraySplitted[0],
    minutes: hourArraySplitted[1],
  });
  const meetingDateTime = moment(meetingDate)
    .set("hour", hourArraySplitted[0])
    .set("minute", hourArraySplitted[1])
    .format("DD MMMM YYYY [à] HH[h]mm");

  const [clientMessage, setClientMessage] = useState("");

  const employeeData = withWhom;

  const {
    name = "indifferent",
    guest = false,
    profilPicture = null,
  } = employeeData || {};

  const totalPrice = prestations
    .map((prestation) => prestation.price)
    .reduce((a, b) => a + b, 0);
  const totalDuration = prestations
    .map((prestation) => prestation.duration)
    .reduce((a, b) => a + b, 0);

  const [currentUserData] = useGlobal("currentUserData");
  const [currentUID] = useGlobal("currentUID");

  const contactDetails = currentUserData?.contactDetails || {};

  const [, setIsGlobalLoading] = useGlobal("_isLoading");

  const db = firebase.firestore();

  const { data: institute } = useDataFromRef({
    ref: firebase.firestore().collection("institutes").doc(instituteId),
    simpleRef: true,
    refreshArray: [],
    listener: true,
  });

  const publishMeeting = async ({ status = "PENDING" } = {}) => {
    try {
      const { id } = await db.collection("meetings").add({
        instituteData: {
          instituteId: instituteId,
          instituteName: instituteName,
          instituteProfilPicture: instituteProfilPicture,
          instituteLocation: instituteLocation,
          employeeData: { name, guest, profilPicture },
        },
        status,
        date: moment(meetingDate).toDate(),
        clientData: contactDetails,
        clientId: currentUID,
        employeesId: employeeData?.employeeId || null,
        payment: [],
        clientMessage: clientMessage || "-",
        prestationData: prestations,
        meetingDuration: totalDuration,
        meetingPrice: totalPrice,
      });

      return id;
    } catch (error) {
      console.log(error);
    }
  };

  const onPressValidateMyReservation = async () => {
    try {
      setIsGlobalLoading(true);
      await publishMeeting();
      navigation?.navigate(Routes.ReservationValidated, {
        instituteProfilPicture,
        instituteName,
        meetingDate,
        prestations,
      });
      console.log("Document written with success");
    } catch (error) {
      console.error("Error adding document: ", error);
    } finally {
      setIsGlobalLoading(false);
    }
  };

  const calculateUpFrontPayment = () => {
    const upFrontPayment =
      Number((institute?.upfrontPercent / 100) * totalPrice) + 1;
    return upFrontPayment;
  };

  return (
    <Page>
      <Header title="Votre rendez-vous" back navigation={navigation} />
      <KeyboardAwareScrollView
        keyboardDismissMode={'interactive'}
        keyboardShouldPersistTaps={'handled'}
        contentInsetAdjustmentBehavior={'automatic'}
        enableOnAndroid={true}
        extraHeight={160}
        style={Style.con({flex: 1, pt: 0})}
        scrollEnabled>
        <Image
          style={Style.con({
            h: responsiveHeight(13),
            w: isDesktopWeb ? '100%' : DIMENSION_WIDTH,
          })}
          source={{uri: instituteProfilPicture}}
        />
        <Text
          style={Fonts.t(27, Palette.black, {
            wei: '700',
            t: responsiveHeight(2),
            self: 'center',
          })}>
          {instituteName}
        </Text>
        <Text
          style={Fonts.t(15, Palette.black, {
            wei: '400',
            t: responsiveHeight(1),
            self: 'center',
          })}>
          {`Rendez-vous prévu le ${meetingDateTime}`}
        </Text>
        <Text
          style={Fonts.t(15, Palette.black, {
            wei: '400',
            t: responsiveHeight(1),
            self: 'center',
            b: responsiveHeight(3),
          })}>
          {`📍 ${instituteLocation.addressIns}`}
        </Text>
        <View
          style={Style.con({
            direc: 'column',
            items: 'flex-start',
            self: 'flex-start',
            mb: responsiveHeight(2),
            pl: 20,
            pr: 20,
          })}>
          {prestations.map(prestation => {
            return (
              <View
                style={Style.con({
                  direc: 'row',
                  items: 'center',
                  self: 'flex-start',
                  mb: responsiveHeight(4),
                })}
                key={prestation.prestationId}>
                <View
                  style={Style.con({
                    size: 30,
                    bg: Palette.gray[16],
                    cen: true,
                  })}>
                  <Text
                    style={Fonts.t(15, Palette.black, {
                      wei: '700',
                    })}>
                    {prestations.indexOf(prestation) + 1}
                  </Text>
                </View>
                <Text
                  style={Fonts.t(15, Palette.black, {
                    wei: '700',
                    l: responsiveHeight(2),
                  })}>
                  {prestation.name || prestation.prestationName}
                </Text>
              </View>
            );
          })}
        </View>
        <View>
          <Text
            style={Fonts.t(15, Palette.black, {
              wei: '600',
              x: gutters,
              b: responsiveHeight(1),
            })}>
            {'Informations complémentaires'}
          </Text>
          <View>
            <Input
              style={Style.con({
                mx: gutters,
                h: responsiveHeight(18),
                bor: 15,
                bg: Palette.gray[16],
                p: 16,
              })}
              multiline={true}
              placeholder="Besoin précis, informations à préciser..."
              placeholderTextColor={Palette.gray[1]}
              value={clientMessage}
              onChange={e => setClientMessage(e)}
            />
          </View>
        </View>

        <Button
          title="Valider ma réservation"
          onPress={onPressValidateMyReservation}
          style={[Style.con({mx: gutters, my: responsiveHeight(2)})]}
        />
      </KeyboardAwareScrollView>
    </Page>
  );
};
