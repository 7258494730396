import { useEffect, useGlobal } from "reactn";
import { Linking, Platform } from "react-native";

import { useNavigation } from "@react-navigation/native";

import firebase from "../config/firebase";
import { Routes } from "../navigation";

export default ({ children }) => {
  const navigation = useNavigation();

  const [, setIsGlobalLoading] = useGlobal("_isLoading");

  useEffect(() => {
    if (Platform.OS === "web") {
      instituteAutoRouting();
    }
  }, []);

  const instituteAutoRouting = async () => {
    try {
      const initialUrl = await Linking.getInitialURL();

      const instituteName =
        initialUrl?.replaceAll("/", "")?.split("@")?.[1]?.trim() || null;

      if (instituteName) {
        setIsGlobalLoading(true);

        const result = await firebase
          .firestore()
          .collection("institutes")
          .where("instagram", "==", `@${instituteName}`)
          .get();

        if (result?.docs?.length > 0) {
          const instituteData = result?.docs?.[0]?.data();
          const instituteId = result?.docs?.[0]?.id;

          if (instituteData.isPublished) {
            navigation?.navigate(Routes.SearchStack, {
              screen: Routes.InstituteProfile,
              params: {
                instituteProfilPicture: instituteData.profilPicture,
                instituteId,
                instituteName: instituteData.name,
                categories: instituteData.categories,
                ratings: instituteData.ratings,
                instituteLocation: instituteData.location,
                instituteInstagram: instituteData.instagram,
                instituteType: instituteData.type,
              },
            });
          } else {
            console.log("institute not published");
          }
        } else {
          console.log("institute not found");
        }
      } else {
        console.log("institute name not found");

        if (initialUrl?.includes("privacyPolicy")) {
          console.log("privacyPolicy");
          navigation?.navigate(Routes.ProfileStack, { screen: Routes.Privacy });
        }
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setIsGlobalLoading(false);
    }
  };

  return children;
};
