import { getGlobal } from "reactn";
import { Routes } from "../navigation";

import { navigate } from "../navigation/NavigationService";

export const reservationRouting = ({ institute }) => {
  const { selectedPrestations, currentUID } = getGlobal();
  if (!currentUID) {
    navigate(Routes.ReservationAttempt);
  } else if (selectedPrestations[institute?.instituteId]) {
    navigate(Routes.ChooseTimeDate, { institute });
  } else {
    navigate(Routes.MakeAppointment, { institute });
  }
};
