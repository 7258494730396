import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';

import {Palette} from '../styles';
import {Routes} from './Routes';

import Home from '../screens/tabs/Home';
import PostDetails from '../screens/posts/PostDetails';
import InstituteProfile from '../screens/InstituteProfile';
import PostByHashtag from '../screens/posts/PostByHashtag';
import ChooseTimeDate from '../screens/ChooseTimeDate';
import MakeAppointment from '../screens/booking/MakeAppointment';
import Recap from '../screens/Recap';
import Login from '../screens/auth/Login';
import Profile from '../screens/tabs/Profile';
import ReservationValidated from '../screens/booking/ReservationValidated';
import Inscription from '../screens/auth/Inscription';

const screenOptions = {
  headerShown: false,
  cardOverlayEnabled: true,
  headerStyle: {
    backgroundColor: Palette.background,
  },
  headerTitleAlign: 'center',
  headerBackTitleVisible: false,
};

const HomeStack = createStackNavigator();

export default function HomeStackScreen() {
  return (
    <HomeStack.Navigator
      initialRouteName={Routes.Home}
      screenOptions={[
        {
          contentStyle: {
            backgroundColor: Palette.white,
          },
        },
        screenOptions,
      ]}>
      <HomeStack.Screen
        name={Routes.Home}
        component={Home}
        options={{headerShown: false}}
      />
      <HomeStack.Screen
        name={Routes.PostDetails}
        component={PostDetails}
        options={{headerShown: false}}
      />
      <HomeStack.Screen
        name={Routes.InstituteProfile}
        component={InstituteProfile}
        options={{headerShown: false}}
      />
      <HomeStack.Screen
        name={Routes.PostByHashtag}
        component={PostByHashtag}
        options={{headerShown: false}}
      />
      <HomeStack.Screen
        name={Routes.ChooseTimeDate}
        component={ChooseTimeDate}
        options={{headerShown: false}}
      />
      <HomeStack.Screen
        name={Routes.MakeAppointment}
        component={MakeAppointment}
        options={{headerShown: false}}
      />
      <HomeStack.Screen
        name={Routes.Recap}
        component={Recap}
        options={{headerShown: false}}
      />
      <HomeStack.Screen
        name={Routes.ReservationValidated}
        component={ReservationValidated}
        options={{headerShown: false}}
      />
      <HomeStack.Screen
        name={Routes.Login}
        component={Login}
        options={{headerShown: false}}
      />
      <HomeStack.Screen
        name={Routes.Inscription}
        component={Inscription}
        options={{headerShown: false}}
      />
      <HomeStack.Screen
        name={Routes.Profile}
        component={Profile}
        options={{headerShown: false}}
      />
    </HomeStack.Navigator>
  );
}
