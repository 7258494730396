import React from "react";
import { Image, Platform } from "react-native";
import { Style } from "../styles";
import { icons } from "../assets";
import {
  responsiveHeight,
  responsiveWidth,
} from "react-native-responsive-dimensions";
import { isDesktopWeb } from "../config/layoutConstants";

const Logo = () => {
  return (
    <Image
      resizeMode="contain"
      source={icons.wink_full_red}
      style={[
        Style.con({
          h: isDesktopWeb ? responsiveHeight(6) : responsiveHeight(8),
          w: isDesktopWeb ? responsiveWidth(25) : responsiveWidth(30),
          // cen: true,
        }),
        {
          // alignSelf: "center",
          marginTop: Platform.OS === "ios" ? 0 : 10,
        },
      ]}
    />
  );
};

export default Logo;
