import React, {useState, useGlobal, useEffect} from 'reactn';
import {Text, View, TouchableOpacity, Switch, Platform} from 'react-native';
import Slider from '@react-native-community/slider';
import {Fonts, gutters, Palette, Style} from '../styles';
import {Spacer, SwitchWeb} from '../components';
import Button from './Button';
import Input from './Input';
import usePlaceApi from '../hooks/usePlacesApi';

export const DistanceFilterUI = ({onReset = () => {}, onApply = () => {}}) => {
  const [distanceLevel, setDistanceLevel] = useGlobal('distanceLevel');
  const [searchCity, setSearchCity] = useState('');
  const [selectedCity, setSelectedCity] = useGlobal('selectedCity');
  const [, setLatSelectedCity] = useGlobal('latSelectedCity');
  const [, setLngSelectedCity] = useGlobal('lngSelectedCity');
  const [isAroundUser, setIsAroundUser] = useState(false);

  const {places, clearSearch, getPlaceData, placeDetails} = usePlaceApi({
    query: searchCity,
    apiKey: 'AIzaSyAxS1Lw-l8laAcb4pqRj9FXSOW6l5w9PBM',
    queryFields: 'formatted_address,geometry,name,address_components',
    queryCountries: ['fr'],
    language: 'fr-FR',
    minChars: 2,
  });

  useEffect(() => {
    setDistanceLevelAroundUser();
  }, [isAroundUser]);

  function setDistanceLevelAroundUser() {
    if (isAroundUser) {
      setDistanceLevel(5);
    }
  }

  console.log(isAroundUser, 'isAroundUser');
  return (
    <View style={Style.con({flex: 1, justify: 'space-between', mb: 10})}>
      <View>
        <Text
          style={Fonts.t(17, Palette.black, {
            wei: '700',
            text: 'center',
            t: 16,
          })}>
          {'Distance'}
        </Text>
        <View style={Style.con({px: gutters, t: 40})}>
          <Text style={Fonts.t(15, Palette.black, {wei: '600', b: 20})}>
            {'Ville'}
          </Text>
          <Input
            placeholder="Saisissez une ville"
            style={Style.con({mb: 30})}
            value={searchCity}
            onChange={value => setSearchCity(value)}
          />

          {places.length > 0 ? (
            <View>
              {places.map(suggestion => {
                const {
                  place_id,
                  structured_formatting: {main_text, secondary_text},
                } = suggestion;
                return (
                  <TouchableOpacity
                    key={place_id}
                    style={Style.con({direc: 'row', mb: 20})}
                    onPress={() => {
                      setSelectedCity(main_text);
                      clearSearch();
                      getPlaceData(suggestion);
                    }}>
                    <Text style={Fonts.t(14, Palette.black, {wei: '700'})}>
                      {`${main_text} - `}
                    </Text>
                    <Text style={Fonts.t(14)}>{`${secondary_text}`}</Text>
                  </TouchableOpacity>
                );
              })}
            </View>
          ) : null}
          <View
            style={[
              Style.con({
                py: 7,
                px: gutters,
                cen: true,
                mb: 24,
                self: 'flex-start',
              }),
              Style.border({bor: 100, color: Palette.gray[9], width: 1}),
            ]}>
            <Text style={Fonts.t(13, Palette.black, {})}>
              {selectedCity
                ? `${selectedCity} - ${distanceLevel}km`
                : 'Aucune ville sélectionnée'}
            </Text>
          </View>
          <Spacer />
          <View
            style={Style.con({
              direc: 'row',
              items: 'center',
              justify: 'space-between',
              mb: 16,
            })}>
            <Text
              style={Fonts.t(15, Palette.black, {
                wei: '600',
              })}>
              {'Dans un rayon autour de '}
            </Text>
            <Text
              style={Fonts.t(
                15,
                Palette.primary,
              )}>{`${distanceLevel} kms`}</Text>
          </View>
          <View pointerEvents={isAroundUser ? 'none' : 'auto'}>
            <Slider
              style={Style.con({mb: 60})}
              value={distanceLevel}
              onValueChange={value => setDistanceLevel(value)}
              step={1}
              maximumValue={50}
              minimumTrackTintColor={Palette.primary}
              maximumTrackTintColor={Palette.gray[2]}
              thumbTintColor={Palette.primary}
              disabled={isAroundUser}
            />
          </View>
          <View style={Style.con({direc: 'row', justify: 'space-between'})}>
            <Text style={Fonts.t(15, Palette.black)}>{'Autour de moi'}</Text>
            {Platform.OS === 'web' ? (
              <SwitchWeb value={isAroundUser} setValue={setIsAroundUser} />
            ) : (
              <Switch
                value={isAroundUser}
                onChange={() => setIsAroundUser(!isAroundUser)}
                thumbColor={Palette.white}
                ios_backgroundColor={Palette.gray[9]}
                trackColor={{true: Palette.primary}}
              />
            )}
          </View>
        </View>
      </View>
      <View>
        <Button
          title="Appliquer"
          onPress={() => {
            onApply(distanceLevel, selectedCity);
            if (placeDetails) {
              setLatSelectedCity(placeDetails.geometry.location.lat);
              setLngSelectedCity(placeDetails.geometry.location.lng);
            }
          }}
          style={Style.con({mx: gutters, mb: 6})}
        />
        <Button
          primary={false}
          title="Réinitialiser" // Reset
          onPress={onReset}
          style={Style.con({mx: gutters})}
        />
      </View>
    </View>
  );
};

export default DistanceFilterUI;
