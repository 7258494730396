import iconApple from './icons/iconApple.png';
import iconGoogle from './icons/iconGoogle.png';
import iconFacebook from './icons/iconFacebook.png';
import iconEyeOpen from './icons/iconEyeOpen.png';
import iconEyeClose from './icons/iconEyeClose.png';
import iconFilter from './icons/iconFilter.png';

import iconHome from './icons/iconHome.png';
import iconMap from './icons/iconMap.png';
import iconSearch from './icons/iconSearch.png';
import iconReels from './icons/iconReels.png';
import iconUser from './icons/iconUser.png';

import arrowRight from './icons/arrowRight.png';
import arrowRightCircle from './icons/arrowRightCircle.png';

import iconTriangleRight from './icons/iconTriangleRight.png';
import iconSearch2 from './icons/iconSearch2.png';
import iconMapMarker from './icons/iconMapMarker.png';
import iconStar from './icons/iconStar.png';
import iconArrowLeft from './icons/iconArrowLeft.png';
import iconHeart from './icons/iconHeart.png';
import iconHeartStroke from './icons/iconHeartStroke.png';
import iconStarUnselect from './icons/iconStarUnselect.png';
import iconStarSelect from './icons/iconStarSelect.png';
import iconDown from './icons/iconDown.png';
import iconSettings from './icons/iconSettings.png';
import iconGallery from './icons/iconGallery.png';
import iconClock from './icons/iconClock.png';
import iconInfo from './icons/iconInfo.png';
import iconGallery2 from './icons/iconGallery2.png';
import iconCamera from './icons/iconCamera.png';
import iconClose from './icons/iconClose.png';
import iconHeartProfile from './icons/iconHeartProfile.png';
import iconHeartStrokeProfile from './icons/iconHeartStrokeProfile.png';
import wink_full_red from './icons/wink_full_red.png';

export const icons = {
  iconApple,
  iconGoogle,
  iconFacebook,
  iconEyeOpen,
  iconEyeClose,
  iconFilter,
  iconHome,
  iconMap,
  iconSearch,
  iconReels,
  iconUser,
  arrowRight,
  arrowRightCircle,
  iconTriangleRight,
  iconSearch2,
  iconMapMarker,
  iconStar,
  iconArrowLeft,
  iconHeart,
  iconHeartStroke,
  iconStarUnselect,
  iconStarSelect,
  iconDown,
  iconSettings,
  iconGallery,
  iconClock,
  iconInfo,
  iconGallery2,
  iconCamera,
  iconClose,
  iconHeartProfile,
  iconHeartStrokeProfile,
  wink_full_red,
};

import blurViewTop from './images/blurViewTop.png';
import franceFlag from './images/franceFlag.png';
import home from './images/home.png';
import homeWeb from './images/homeWeb.png';
import appMockup from './images/appMockup.png';
import downloadAppStore from './images/downloadAppStore.png';
import downloadPlayStore from './images/downloadPlayStore.png';

export const images = {
  blurViewTop,
  franceFlag,
  home,
  homeWeb,
  appMockup,
  downloadAppStore,
  downloadPlayStore,
  logo: require('./images/logo.png'),
};

import cleansingHires from './placeholders/cleansingHires.png';
import eyelashHires from './placeholders/eyelashHires.png';
import lipsHires from './placeholders/lipsHires.png';
import nailsHires from './placeholders/nailsHires.png';
import stonesHires from './placeholders/stonesHires.png';
import waxingHires from './placeholders/waxingHires.png';
import surgeryHires from './placeholders/surgeryHires.png';
import lipGloss from './placeholders/lipGloss.png';
import hairHires from './placeholders/hairHires.png';

import serviceBarber from './placeholders/serviceBarber.png';
import serviceCoiffure from './placeholders/serviceCoiffure.png';
import serviceEpilation from './placeholders/serviceEpilation.png';
import serviceLevres from './placeholders/serviceLevres.png';
import serviceMainsEtPieds from './placeholders/serviceMainsEtPieds.png';
import serviceMaquillage from './placeholders/serviceMaquillage.png';
import serviceRegard from './placeholders/serviceRegard.png';
import serviceSoinsDuCorps from './placeholders/serviceSoinsDuCorp.png';
import serviceVisage from './placeholders/serviceVisage.png';

import example1 from './placeholders/example1.png';
import example2 from './placeholders/example2.png';
import example3 from './placeholders/example3.png';
import example4 from './placeholders/example4.png';
import example5 from './placeholders/example5.png';
import example6 from './placeholders/example6.png';
import example7 from './placeholders/example7.png';
import example8 from './placeholders/example8.png';

import search1 from './placeholders/search1.png';
import search2 from './placeholders/search2.png';
import search3 from './placeholders/search3.png';
import search4 from './placeholders/search4.png';
import search5 from './placeholders/search5.png';
import search6 from './placeholders/search6.png';
import search7 from './placeholders/search7.png';
import search8 from './placeholders/search8.png';
import search9 from './placeholders/search9.png';
import search10 from './placeholders/search10.png';
import search11 from './placeholders/search11.png';
import search12 from './placeholders/search12.png';
import search13 from './placeholders/search13.png';
import search14 from './placeholders/search14.png';

import postAuthor from './placeholders/postAuthor.png';

import place1 from './placeholders/place1.png';
import place2 from './placeholders/place2.png';

import reels1 from './placeholders/reels1.png';
import reels2 from './placeholders/reels2.png';
import reels3 from './placeholders/reels3.png';
import reelsAuthor from './placeholders/reelsAuthor.png';

import userAvatar from './placeholders/userAvatar.png';
import appointment1 from './placeholders/appointment1.png';
import appointment2 from './placeholders/appointment2.png';

import userPost1 from './placeholders/userPost1.png';
import userPost2 from './placeholders/userPost2.png';
import userPost3 from './placeholders/userPost3.png';
import userPost4 from './placeholders/userPost4.png';
import userPost5 from './placeholders/userPost5.png';
import userPost6 from './placeholders/userPost6.png';
import userPost7 from './placeholders/userPost7.png';
import userPost8 from './placeholders/userPost8.png';
import userPost9 from './placeholders/userPost9.png';
import userPost10 from './placeholders/userPost10.png';
import userPost11 from './placeholders/userPost11.png';
import userPost12 from './placeholders/userPost12.png';
import userPost13 from './placeholders/userPost13.png';
import userPost14 from './placeholders/userPost14.png';
import userPost15 from './placeholders/userPost15.png';

import notice1 from './placeholders/notice1.png';
import notice2 from './placeholders/notice2.png';
import notice3 from './placeholders/notice3.png';

import user1 from './placeholders/user1.png';
import user2 from './placeholders/user2.png';
import user3 from './placeholders/user3.png';

import availablity1 from './placeholders/availablity1.png';
import availablity2 from './placeholders/availablity2.png';

import recap from './placeholders/recap.png';
import appointmentBanner1 from './placeholders/appointmentBanner1.png';
import appointmentBanner2 from './placeholders/appointmentBanner2.png';

export const placeholders = {
  serviceBarber,
  serviceCoiffure,
  serviceEpilation,
  serviceLevres,
  serviceMainsEtPieds,
  serviceMaquillage,
  serviceRegard,
  serviceSoinsDuCorps,
  serviceVisage,
  cleansingHires,
  eyelashHires,
  lipsHires,
  nailsHires,
  stonesHires,
  waxingHires,
  surgeryHires,
  lipGloss,
  hairHires,
  example1,
  example2,
  example3,
  example4,
  example5,
  example6,
  example7,
  example8,
  search1,
  search2,
  search3,
  search4,
  search5,
  search6,
  search7,
  search8,
  search9,
  search10,
  search11,
  search12,
  search13,
  search14,
  postAuthor,
  place1,
  place2,
  reels1,
  reels2,
  reels3,
  reelsAuthor,
  userAvatar,
  appointment1,
  appointment2,
  userPost1,
  userPost2,
  userPost3,
  userPost4,
  userPost5,
  userPost6,
  userPost7,
  userPost8,
  userPost9,
  userPost10,
  userPost11,
  userPost12,
  userPost13,
  userPost14,
  userPost15,
  notice1,
  notice2,
  notice3,
  user1,
  user2,
  user3,
  availablity1,
  availablity2,
  recap,
  appointmentBanner1,
  appointmentBanner2,
};

export const jsons = {};
