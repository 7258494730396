import React, {useGlobal, useState, useRef} from 'reactn';
import {View, Image, TouchableOpacity, Text, StyleSheet} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {
  responsiveHeight,
  responsiveWidth,
} from 'react-native-responsive-dimensions';
import {Video} from 'expo-av';
import {useNavigation} from '@react-navigation/native';
import {LinearGradient} from 'expo-linear-gradient';

import {Style, Palette, Fonts, gutters} from '../styles';
import {Button, Spacer} from '../components';
import {icons} from '../assets';
import {Routes} from '../navigation';
import {BOTTOM_BAR_HEIGHT, DIMENSION_HEIGHT, DIMENSION_WIDTH} from '../common';
import {reservationRouting} from '../helpers/reservation';
import firebase, {arrayRemove, arrayUnion} from '../config/firebase';
import {isDesktopWeb, isMobileWeb} from '../config/layoutConstants';
import {useEffect} from 'react';

const ReelsVideo = ({
  instituteData,
  instituteProfilPicture = instituteData.profilPicture,
  instituteName = instituteData.name,
  instituteId,
  ratings = instituteData.ratings,
  instituteLocation = instituteData.location,
  instituteInstagram = instituteData.instagram,
  categories = instituteData.categories,
  instituteType = instituteData.type,
  mediaURL,
  description,
  postId,
  route,
  prestationData,
  duration = prestationData.duration || null,
  name = prestationData.name || '',
  price = prestationData.price || null,
  allSkinTypes = prestationData.allSkinTypes || false,
  prestationId,
  play = true,
  index,
}) => {
  const {bottom} = useSafeAreaInsets();
  const navigation = useNavigation();
  const [tooltip, setTooltip] = useGlobal('_tooltip');

  const db = firebase.firestore();
  const postsRef = db.collection('posts').doc(postId);
  const usersRef = db.collection('users').doc(currentUserData?.id);

  const [currentUserData] = useGlobal('currentUserData');
  const [currentUID] = useGlobal('currentUID');
  const [selectedPrestations, setSelectedPrestations] = useGlobal(
    'selectedPrestations',
  );
  const isLikedPost = currentUserData?.likedPostsId?.includes(postId);
  const [openString, setOpenString] = useState(false);
  const descriptionString = description;

  const video = useRef(null);
  const [status, setStatus] = useState({});

  useEffect(() => {
    if (play) {
      video.current?.playAsync();
    } else {
      video.current?.pauseAsync();
    }
  }, [play]);

  const likedPost = async () => {
    console.log('uid', currentUserData?.id);
    if (isLikedPost === true) {
      try {
        await postsRef.update({
          favBy: arrayRemove(currentUserData?.id),
        });
        await usersRef.update({
          likedPostsId: arrayRemove(postId),
        });
        await setTooltip({text: 'Post retiré des favoris'});
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await postsRef.update({
          favBy: arrayUnion(currentUserData?.id),
        });
        await usersRef.update({
          likedPostsId: arrayUnion(postId),
        });
        await setTooltip({text: 'Post ajouté aux favoris'});
      } catch (error) {
        console.log({text: error.message});
      }
    }
  };

  const onPressAuthor = () => {
    navigation?.navigate(Routes.InstituteProfile, {
      instituteProfilPicture,
      instituteId,
      instituteName,
      ratings,
      instituteLocation,
      instituteInstagram,
      categories,
      instituteType,
    });
  };

  const onPressMakeAppointment = prestation => {
    prestation = {
      duration,
      price,
      name,
      allSkinTypes,
      prestationId,
    };
    try {
      let newselectedPrestations = {...selectedPrestations};

      if (
        !(newselectedPrestations?.[instituteId] || [])
          .map(({prestationId}) => prestationId)
          ?.includes(prestation.prestationId)
      ) {
        newselectedPrestations[instituteId] = [
          ...(newselectedPrestations?.[instituteId] || []),
          prestation,
        ];
        setSelectedPrestations(newselectedPrestations);
      }
      reservationRouting({
        navigation,
        institute: {
          instituteName,
          instituteProfilPicture,
          instituteId,
          instituteLocation,
          instituteInstagram,
          categories,
          ratings,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <View
      style={[
        isDesktopWeb
          ? {height: responsiveHeight(50), width: '100%'}
          : {
              height: responsiveHeight(100) - BOTTOM_BAR_HEIGHT,
            },
        {},
      ]}>
      <Video
        ref={video}
        style={Style.con({
          pos: 'absolute',
          t: 0,
          l: 0,
          r: 0,
          b: 0,
        })}
        videoStyle={{width: '100%'}}
        useNativeControls={isMobileWeb}
        source={{uri: mediaURL || ''}}
        onError={e => console.log('error', e)}
        resizeMode={'cover'}
        onPlaybackStatusUpdate={status => setStatus(() => status)}
        isLooping={true}
        isMuted={true}
        onLoad={() => {
          video.current.playAsync();
        }}
      />
      <LinearGradient
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
        }}
        colors={['rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0.5)']}
        locations={[0.05, 1]}>
        <View
          style={[
            Style.con({
              px: isDesktopWeb ? responsiveWidth(1) : gutters,
              pb: isDesktopWeb ? 0 : 16 - bottom,
              pt: 16,
            }),
          ]}>
          <View
            style={Style.con({
              direc: 'row',
              justify: 'space-between',
              mb: isDesktopWeb ? 0 : responsiveHeight(1),
            })}>
            <View>
              <TouchableOpacity
                onPress={onPressAuthor}
                style={Style.con({direc: 'row'})}>
                <Image
                  style={[
                    Style.con({size: 32}),
                    Style.border({color: Palette.white, width: 1, bor: 32}),
                  ]}
                  source={{uri: instituteProfilPicture}}
                />
                <Text
                  style={Fonts.t(15, Palette.white, {
                    wei: '700',
                    l: isDesktopWeb ? responsiveWidth(1) : responsiveWidth(4),
                    self: 'center',
                  })}>
                  {instituteName}
                </Text>
              </TouchableOpacity>
              {openString ? (
                <>
                  <Text style={Fonts.t(13, Palette.white, {wei: '400', t: 14})}>
                    {descriptionString}
                  </Text>
                  <TouchableOpacity onPress={() => setOpenString(false)}>
                    <Text style={Fonts.t(13, Palette.white, {wei: '700'})}>
                      {'moins'}
                    </Text>
                  </TouchableOpacity>
                </>
              ) : (
                <>
                  <Text
                    numberOfLines={1}
                    style={Fonts.t(13, Palette.white, {wei: '400', t: 14})}>
                    {`${descriptionString?.substring(0, 50) || ''}...`}
                  </Text>
                  <TouchableOpacity onPress={() => setOpenString(true)}>
                    <Text style={Fonts.t(13, Palette.white, {wei: '700'})}>
                      {'voir plus'}
                    </Text>
                  </TouchableOpacity>
                </>
              )}
            </View>
            {currentUID && (
              <TouchableOpacity onPress={likedPost}>
                <Image
                  style={Style.con({size: 32})}
                  resizeMode="contain"
                  source={
                    isLikedPost
                      ? icons.iconHeartProfile
                      : icons.iconHeartStrokeProfile
                  }
                />
              </TouchableOpacity>
            )}
          </View>
          <Button
            title="Prendre rendez-vous"
            style={Style.con({
              mb: isDesktopWeb ? responsiveHeight(1) : responsiveHeight(2),
            })}
            onPress={() => onPressMakeAppointment(route?.params)}
          />
        </View>
      </LinearGradient>
    </View>
  );
};

export default ReelsVideo;
