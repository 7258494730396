import React, {useState} from 'reactn';
import {ScrollView} from 'react-native';
import {Style} from '../../styles';
import {Header, ServiceAppointments} from '../../components';
import useDataFromRef from '../../hooks/useDataFromRef';
import {institutesRef} from '../../config/firebase';
import {useNavigation} from '@react-navigation/native';
import Page from '../../components/Page';

export default ({route}) => {
  const navigation = useNavigation();

  const {institute = {}} = route?.params;
  const {
    instituteName,
    instituteId,
    instituteProfilPicture,
    instituteInstagram,
    instituteLocation,
    categories,
  } = institute;

  const [selectedCategory, setSelectedCategory] = useState(null);

  const {data: prestationsList} = useDataFromRef({
    ref: institutesRef
      .doc(instituteId)
      .collection('presta')
      .where('category', '==', selectedCategory),
    simpleRef: false,
    documentID: 'prestationId',
    initialState: [],
    condition: selectedCategory,
    refreshArray: [instituteId, selectedCategory],
  });
  return (
    <Page>
      <Header
        title={instituteName}
        back
        navigation={navigation}
        // withLogo={!isDesktopWeb}
      />
      <ScrollView style={Style.con({flex: 1})}>
        {categories.map((item, index) => {
          return (
            <ServiceAppointments
              item={item}
              key={index}
              // isLoading={isLoadingPrestations}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              categories={categories}
              navigation={navigation}
              prestationsList={prestationsList}
              instituteName={instituteName}
              instituteId={instituteId}
              instituteProfilPicture={instituteProfilPicture}
              instituteLocation={instituteLocation}
              instituteInstagram={instituteInstagram}
            />
          );
        })}
      </ScrollView>
    </Page>
  );
};
