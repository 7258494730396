import React, {useState, useGlobal} from 'reactn';
import {
  Dimensions,
  FlatList,
  ScrollView,
  View,
  Text,
  ActivityIndicator,
} from 'react-native';
import {responsiveHeight} from 'react-native-responsive-dimensions';
import {useNavigation} from '@react-navigation/native';
import {Palette, Style, Fonts} from '../../styles';
import {Header, PostDetailsView} from '../../components';
import useDataFromRef from '../../hooks/useDataFromRef';
import {validateDate} from '../../actions/dateActions';
import {institutesRef, postsRef} from '../../config/firebase';
import moment from 'moment';
import Page from '../../components/Page';

export default ({route}) => {
  const navigation = useNavigation();
  const [currentUID] = useGlobal('currentUID');

  const [itemPostDimensions, setItemPostDimensions] = useState(null);

  const {postId, LIKED_POST, INSTITUTE_POSTS, instituteName, instituteId} =
    route?.params;

  const {
    data: postList,
    loading,
    loadMore,
  } = useDataFromRef({
    ref: postsRef.where('type', '==', 'image'),
    initialDocumentRef: postsRef.doc(postId),
    refreshArray: [postId],
    listener: true,
    simpleRef: false,
    documentID: 'postId',
    usePagination: true,
    batchSize: 10,
    format: async data => {
      try {
        const promiseInstituteAndPrestationData = [];

        data.map(postData =>
          promiseInstituteAndPrestationData.push(
            new Promise(async (resolve, reject) => {
              try {
                const {instituteId, prestationId} = postData;

                const instituteData =
                  (await institutesRef.doc(instituteId).get())?.data() || {};

                resolve({
                  ...postData,
                  instituteData,
                });
              } catch (error) {
                reject(error);
              }
            }),
          ),
        );
        const newPostList = await Promise.all(
          promiseInstituteAndPrestationData,
        );
        return newPostList.map((newPost = {}) => ({
          ...newPost,
          datePost: validateDate(newPost.datePost),
        }));
      } catch (error) {
        console.log(error);
      }
    },
  });

  const {data: likedPostList} = useDataFromRef({
    ref: postsRef
      .where('type', '==', 'image')
      .where('favBy', 'array-contains', currentUID || '-'),
    initialDocumentRef: postsRef.doc(postId),
    refreshArray: [currentUID, postId],
    listener: true,
    simpleRef: false,
    documentID: 'postId',
    usePagination: true,
    batchSize: 10,
    format: async data => {
      try {
        const promiseInstituteAndPrestationData = [];

        data.map(postData =>
          promiseInstituteAndPrestationData.push(
            new Promise(async (resolve, reject) => {
              try {
                const {instituteId, prestationId} = postData;

                const instituteData =
                  (await institutesRef.doc(instituteId).get())?.data() || {};

                resolve({
                  ...postData,
                  instituteData,
                });
              } catch (error) {
                reject(error);
              }
            }),
          ),
        );
        const newPostList = await Promise.all(
          promiseInstituteAndPrestationData,
        );
        return newPostList.map((newPost = {}) => ({
          ...newPost,
          datePost: validateDate(newPost.datePost),
        }));
      } catch (error) {
        console.log(error);
      }
    },
  });

  const {data: institutePostList} = useDataFromRef({
    ref: postsRef
      .where('type', '==', 'image')
      .where('instituteId', '==', instituteId || '-'),
    initialDocumentRef: postsRef.doc(postId),
    refreshArray: [currentUID, postId, instituteId],
    listener: true,
    simpleRef: false,
    documentID: 'postId',
    usePagination: true,
    batchSize: 10,
    format: async data => {
      try {
        const promiseInstituteAndPrestationData = [];

        data.map(postData =>
          promiseInstituteAndPrestationData.push(
            new Promise(async (resolve, reject) => {
              try {
                const {instituteId, prestationId} = postData;

                const instituteData =
                  (await institutesRef.doc(instituteId).get())?.data() || {};

                resolve({
                  ...postData,
                  instituteData,
                });
              } catch (error) {
                reject(error);
              }
            }),
          ),
        );
        const newPostList = await Promise.all(
          promiseInstituteAndPrestationData,
        );
        return newPostList.map((newPost = {}) => ({
          ...newPost,
          datePost: validateDate(newPost.datePost),
        }));
      } catch (error) {
        console.log(error);
      }
    },
  });

  const data = () => {
    if (LIKED_POST) {
      return likedPostList;
    }
    if (INSTITUTE_POSTS) {
      return institutePostList;
    }
    return postList;
  };

  const title = () => {
    if (INSTITUTE_POSTS) {
      return (
        <View
          style={Style.con({
            direc: 'column',
          })}>
          <Text
            style={Fonts.t(12, Palette.text[1], {
              self: 'center',
              textVer: 'center',
            })}>
            {instituteName?.toUpperCase()}
          </Text>
          <Text
            style={Fonts.t(16, Palette.text[1], {
              self: 'center',
              textVer: 'center',
              wei: '700',
            })}>
            {'Publications'}
          </Text>
        </View>
      );
    }
    if (LIKED_POST) {
      return (
        <Text
          style={Fonts.t(15, Palette.text[1], {
            self: 'center',
            textVer: 'center',
            wei: '700',
          })}>
          {'Posts Enregistrés'}
        </Text>
      );
    } else {
      return (
        <Text
          style={Fonts.t(15, Palette.text[1], {
            self: 'center',
            textVer: 'center',
            wei: '700',
          })}>
          {'Explorer'}
        </Text>
      );
    }
  };

  const loadingView = () => {
    if (loading) {
      return (
        <View style={Style.con({flex: 1, justify: 'center'})}>
          <ActivityIndicator size="large" />
        </View>
      );
    } else {
      return null;
    }
  };

  return (
    <Page edges={['left', 'right', 'top']}>
      <Header back navigation={navigation} title={title()} />

      <FlatList
        data={data()}
        removeClippedSubviews
        scrollEventThrottle={8}
        showsVerticalScrollIndicator={false}
        onEndReached={loadMore}
        scrollEnabled={true}
        initialNumToRender={10}
        ListFooterComponent={loadingView}
        onEndReachedThreshold={0.5}
        snapToAlignment="start"
        decelerationRate={'fast'}
        snapToInterval={
          itemPostDimensions?.height + 40 || Dimensions.get('window').height
        }
        renderItem={({item}) => {
          const {postId, instituteData, datePost} = item;
          return (
            <PostDetailsView
              key={postId}
              onLayout={event => {
                const {width, height} = event.nativeEvent.layout;
                if (!itemPostDimensions) {
                  setItemPostDimensions({width, height});
                }
              }}
              {...item}
              instituteData={instituteData || {}}
              navigation={navigation}
              datePost={moment(datePost).format('DD MMMM')}
            />
          );
        }}
      />
    </Page>
  );
};
