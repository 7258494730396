export const getUniqByID = ({ array, id }) => {
  return [
    ...array
      .reduce((a, c) => {
        a.set(c?.[id], c);
        return a;
      }, new Map())
      .values(),
  ];
};

export const renderPrice = ({ prestation = {}, phone }) => {
  const {
    isQuotation = false,
    price = 0,
    maxPrice,
    priceType = "FIXED",
  } = prestation;

  let returnedPrice = null;

  if (!price) {
    returnedPrice = null;
  }

  if (isQuotation) {
    returnedPrice = null;
  } else if (priceType === "FIXED") {
    returnedPrice = price;
  } else if (priceType === "RANGE") {
    returnedPrice = `De ${price} à ${maxPrice}`;
  } else {
    returnedPrice = `à partir de ${price}`;
  }

  if (!returnedPrice) {
    return `Contactez\nle ${phone || "salon"}`;
  } else {
    return `${returnedPrice}€`;
  }
};