import React, {useState, useEffect, useGlobal} from 'reactn';
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  FlatList,
  ScrollView,
  ActivityIndicator,
} from 'react-native';
import {responsiveHeight} from 'react-native-responsive-dimensions';
import {Style, Fonts, Palette} from '../../styles';
import {ServiceTypeList} from '../../components';
import {icons} from '../../assets';
import {thematicData} from '../../data';
import {Routes} from '../../navigation';
import {BOTTOM_BAR_HEIGHT, DIMENSION_WIDTH} from '../../common';
import useDataFromRef from '../../hooks/useDataFromRef';
import {validateDate} from '../../actions/dateActions';
import {postsRef, institutesRef} from '../../config/firebase';
import {useNavigation} from '@react-navigation/native';
import Logo from '../../components/RenderLogo';
import {openToday, openThreeDays} from '../../helpers/openFilter';
import Page from '../../components/Page';
import {isDesktopWeb} from '../../config/layoutConstants';

export default () => {
  const navigation = useNavigation();
  const [query, setQuery] = useState(postsRef);
  const [newPostList, setNewPostList] = useState([]);
  const [, setShowFilterModal] = useGlobal('showFilterModal');
  const onPressFilter = () => setShowFilterModal(['PRICE', 'AVAILABILITY']);
  const NUMBER_PER_BATCH = 15;

  const [selectedTags, setSelectedTags] = useGlobal('selectedTags');
  const [minPrice] = useGlobal('minPrice');
  const [maxPrice] = useGlobal('maxPrice');
  const [priceSelected] = useGlobal('priceSelected');
  const [filterPrice] = useGlobal('filterPrice');
  const [filterAvailability] = useGlobal('filterAvailability');
  const [availabilitySelected] = useGlobal('availabilitySelected');
  const [filterSelected, setFilterSelected] = useState(false);

  useEffect(() => {
    let newQuery = postsRef;

    if (priceSelected) {
      newQuery = newQuery.where('prestationData.price', '<=', priceSelected);
    }

    if (minPrice) {
      newQuery = newQuery.where('prestationData.price', '>=', minPrice);
    }

    if (maxPrice) {
      newQuery = newQuery.where('prestationData.price', '<=', maxPrice);
    }

    if (selectedTags.length > 0) {
      newQuery = newQuery.where('prestationData.category', 'in', selectedTags);
    }

    if (filterPrice || filterAvailability) {
      setFilterSelected(true);
    } else {
      setFilterSelected(false);
    }

    setQuery(newQuery);
  }, [
    minPrice,
    maxPrice,
    priceSelected,
    filterPrice,
    selectedTags,
    filterAvailability,
  ]);

  const {data: postsList, loading} = useDataFromRef({
    ref: query.where('type', '==', 'image'),
    simpleRef: false,
    documentID: 'postId',
    refreshArray: [minPrice, maxPrice, priceSelected, query, selectedTags],
    // usePagination: true,
    listener: true,
    key: 'postId',
    initialState: [],
    // batchSize: NUMBER_PER_BATCH,
    format: async (data = []) => {
      try {
        const promiseInstitute = [];

        data.map(postData => {
          promiseInstitute.push(
            new Promise(async (resolve, reject) => {
              try {
                const {instituteId} = postData;
                const instituteData =
                  (await institutesRef.doc(instituteId).get())?.data() || {};

                resolve({
                  ...postData,
                  instituteData,
                });
              } catch (error) {
                reject(error);
              }
            }),
          );
        });
        const newPostList = await Promise.all(promiseInstitute);
        return newPostList.map(newPost => ({
          ...newPost,
          datePost: validateDate(newPost.datePost),
        }));
      } catch (error) {
        console.log(error);
      }
    },
  });

  useEffect(() => {
    let newPostList = postsList;
    if (availabilitySelected === 'OPEN_NOW') {
      newPostList = newPostList.filter(post =>
        openToday(post.instituteData.openingHours),
      );
      setNewPostList(newPostList);
      return;
    }
    if (availabilitySelected === 'IN_THREE_DAYS') {
      newPostList = newPostList.filter(post =>
        openThreeDays(post.instituteData.openingHours),
      );
      setNewPostList(newPostList);
      return;
    }
    setFilterSelected(false);
  }, [availabilitySelected]);

  const loadingView = () => {
    if (loading) {
      return (
        <View style={Style.con({flex: 1, justify: 'center'})}>
          <ActivityIndicator size="large" />
        </View>
      );
    } else {
      return null;
    }
  };

  return (
    <Page>
      <View
        style={Style.con({
          direc: 'row',
          py: responsiveHeight(2),
          px: 20,
          items: 'center',
          justify: isDesktopWeb ? 'end' : 'space-between',
        })}>
        {!isDesktopWeb && <Logo />}

        <TouchableOpacity onPress={onPressFilter}>
          <Image
            source={icons.iconFilter}
            style={Style.con({
              size: 27,
              tin: filterSelected ? Palette.primary : Palette.black,
            })}
          />
        </TouchableOpacity>
      </View>

      <ServiceTypeList data={thematicData} />
      <FlatList
        style={Style.con({flex: 1, mt: 10})}
        scrollEnabled={true}
        contentContainerStyle={Style.con({pb: BOTTOM_BAR_HEIGHT})}
        data={
          availabilitySelected === 'OPEN_NOW' ||
          availabilitySelected === 'IN_THREE_DAYS'
            ? newPostList
            : postsList
        }
        keyExtractor={item => item.postId}
        numColumns={isDesktopWeb ? 3 : 2}
        initialNumToRender={10}
        ItemSeparatorComponent={() => {
          return <View style={Style.con({h: 2, bg: Palette.white})} />;
        }}
        ListEmptyComponent={() => {
          return (
            <View
              style={Style.con({
                justify: 'center',
                self: 'center',
                my: responsiveHeight(30),
              })}>
              <Image
                style={Style.con({size: 40, self: 'center'})}
                source={icons.iconGallery2}
              />
              <Text style={[Fonts.t(15, Palette.black)]}>
                Pas de posts publiés
              </Text>
            </View>
          );
        }}
        // onEndReachedThreshold={1}
        ListFooterComponent={loadingView}
        renderItem={({item: post, index}) => {
          const postId = post?.postId;
          const onPressPostItem = post => {
            navigation?.navigate(Routes.PostDetails, {
              postId,
              NUMBER_PER_BATCH,
            });
          };

          return (
            <TouchableOpacity
              onPress={onPressPostItem}
              style={[
                Style.con({size: DIMENSION_WIDTH / 2}),
                {
                  maxHeight: 300,
                  maxWidth: isDesktopWeb ? `${100 / 3}%` : '50%',
                },
                isDesktopWeb
                  ? Style.con({px: 1})
                  : index % 2 === 0
                  ? Style.con({pr: 1})
                  : Style.con({pl: 1}), // vertical separator between posts
              ]}
              key={postId}>
              <Image
                resizeMode="cover"
                style={{width: '100%', height: '100%'}}
                source={
                  post?.mediaURL !== ''
                    ? {uri: post?.mediaURL}
                    : icons.iconGallery2
                }
              />
            </TouchableOpacity>
          );
        }}
      />
    </Page>
  );
};
