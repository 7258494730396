import React from 'react';
import {Text, TouchableOpacity, Image} from 'react-native';

import {Style, Palette, Fonts} from '../styles';

const Button = ({
  style = {},
  title = '',
  onPress = () => undefined,
  primary = true,
  textStyle = {},
  textColor = null,
  icon = null,
  iconStyle = {},
  color = null,
  disabled,
}) => {
  const bgColor = primary ? Palette.primary : Palette.tran;
  const textColorButton = textColor
    ? textColor
    : primary
    ? Palette.white
    : Palette.black;

  const haveTitle = typeof title === 'string' && title.length > 0;
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        Style.con({
          h: 54,
          cen: true,
          bg: color ?? bgColor,
          bor: 10,
          direc: 'row',
        }),
        style,
      ]}
      disabled={disabled}
      >
      {icon && (
        <Image
          style={[Style.con({size: 12, mr: haveTitle ? 12 : 0}), iconStyle]}
          source={icon}
        />
      )}
      {haveTitle && (
        <Text style={[Fonts.t(15, textColorButton, {wei: 'bold'}), textStyle]}>
          {title}
        </Text>
      )}
    </TouchableOpacity>
  );
};

export default Button;
