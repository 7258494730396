import React, {useGlobal, useState} from 'reactn';
import {Platform, Text, View} from 'react-native';
import ModalBox from 'react-native-modalbox';
import {responsiveHeight} from 'react-native-responsive-dimensions';

import {Fonts, gutters, Palette, resHeight, Style} from '../styles';
import {
  Button,
  PriceFilterUI,
  ScoreFilterUI,
  DistanceFilterUI,
  AvailableFilterUI,
  OptionRow,
} from '../components';
import {DIMENSION_WIDTH} from '../common';
import {
  isDesktopWeb,
  webMaxContentWidth,
  webSideBarWidth,
} from '../config/layoutConstants';

export const FilterDefaultUI = ({
  onPriceFilter,
  onScoreFilter,
  onDistanceFilter,
  onAvailableFilter,
  onApply,
  onRestAll,
}) => {
  const [showFilterModal] = useGlobal('showFilterModal');

  return (
    <View style={Style.con({flex: 1, justify: 'space-between', mb: 50})}>
      <View>
        <Text
          style={Fonts.t(17, Palette.black, {
            wei: '700',
            text: 'center',
            t: 16,
          })}>
          {'Trier et filtrer'}
        </Text>
        {showFilterModal.includes('PRICE') ? (
          <OptionRow title="Prix" push onPress={onPriceFilter} />
        ) : null}
        {showFilterModal.includes('SCORE') ? (
          <OptionRow title="Note" push onPress={onScoreFilter} />
        ) : null}
        {showFilterModal.includes('DISTANCE') ? (
          <OptionRow title="Distance" push onPress={onDistanceFilter} />
        ) : null}
        {showFilterModal.includes('AVAILABILITY') ? (
          <OptionRow title="Disponibilités" push onPress={onAvailableFilter} />
        ) : null}
        <View
          style={Style.con({
            h: 1,
            bg: Palette.gray[5],
            mb: responsiveHeight(2),
          })}
        />
      </View>
      <View
        style={{
          position: 'absolute',
          bottom: 0,
          right: gutters,
          left: gutters,
        }}>
        <Button onPress={onApply} title="Fermer" />
        <Button primary={false} title="Réinitialiser" onPress={onRestAll} />
      </View>
    </View>
  );
};

export const FILTER_UI_MODE = {
  Filter: 'Filter',
  Price: 'Price',
  Score: 'Score',
  Distances: 'Distances',
  Availability: 'Availability',
};

export const availabilitiesMode = [
  {displayName: 'Pas de préférence', mode: 'NO_PREFERENCE'},
  {displayName: 'Ouvert actuellement', mode: 'OPEN_NOW'},
  {displayName: 'Dans les trois prochains jours', mode: 'IN_THREE_DAYS'},
];

const SortFilterModal = ({
  style = {},
  isShow = false,
  onClosed = () => {},
  minPrice,
  maxPrice,
  setMinPrice,
  setMaxPrice,
  priceSelected,
  setPriceSelected,
  scoreSelected,
  setScoreSelected,
  distanceLevel,
  setDistanceLevel,
  selectedCity,
  setSelectedCity,
  setLngSelectedCity,
  setLatSelectedCity,
  availabilitySelected,
  setAvailabilitySelected,
}) => {
  const [mode, setMode] = React.useState(FILTER_UI_MODE.Filter);
  const prices = [20, 50, 100, 200];
  const onOpened = () => undefined;
  const _onClosed = () => {
    setMode(FILTER_UI_MODE.Filter);
    if (onClosed) {
      onClosed();
    }
  };
  const [, setFilterPrice] = useGlobal('filterPrice');
  const isPriceSelected = () => {
    if (priceSelected !== 0 || maxPrice !== '' || minPrice !== '') {
      setFilterPrice(true);
    } else {
      setFilterPrice(false);
    }
  };

  const [, setFilterScore] = useGlobal('filterScore');
  const isScoreSelected = () => {
    if (scoreSelected !== 0) {
      setFilterScore(true);
    } else {
      setFilterScore(false);
    }
  };

  const [, setFilterDistance] = useGlobal('filterDistance');
  const isDistanceSelected = () => {
    if (distanceLevel !== 0 || selectedCity !== '') {
      setFilterDistance(true);
    } else {
      setFilterDistance(false);
    }
  };

  const [, setFilterAvailability] = useGlobal('filterAvailability');
  const isAvailability = () => {
    if (
      availabilitySelected === 'OPEN_NOW' ||
      availabilitySelected === 'IN_THREE_DAYS'
    ) {
      setFilterAvailability(true);
    } else {
      setFilterAvailability(false);
    }
  };

  const renderModalBody = () => {
    switch (mode) {
      case FILTER_UI_MODE.Filter:
        return (
          <FilterDefaultUI
            onPriceFilter={() => setMode(FILTER_UI_MODE.Price)}
            onScoreFilter={() => setMode(FILTER_UI_MODE.Score)}
            onDistanceFilter={() => setMode(FILTER_UI_MODE.Distances)}
            onAvailableFilter={() => setMode(FILTER_UI_MODE.Availability)}
            onRestAll={() => {
              setMinPrice('');
              setMaxPrice('');
              setPriceSelected(0);
              setScoreSelected(null);
              setDistanceLevel(25);
              setSelectedCity('');
              setLngSelectedCity(0);
              setLatSelectedCity(0);
              setAvailabilitySelected('NO_PREFERENCE');
              setFilterPrice(false);
              setFilterScore(false);
              setFilterDistance(false);
              setFilterAvailability(false);
              onClosed();
            }}
            onApply={() => {
              _onClosed();
            }}
          />
        );
      case FILTER_UI_MODE.Price:
        return (
          <PriceFilterUI
            prices={prices}
            onReset={() => {
              setMode(FILTER_UI_MODE.Filter);
              setMinPrice('');
              setMaxPrice('');
              setPriceSelected(0);
              setFilterPrice(false);
            }}
            onApply={() => {
              setMode(FILTER_UI_MODE.Filter);
              isPriceSelected();
            }}
            minPrice={minPrice}
            maxPrice={maxPrice}
            setMinPrice={setMinPrice}
            setMaxPrice={setMaxPrice}
            priceSelected={priceSelected}
            setPriceSelected={setPriceSelected}
          />
        );
      case FILTER_UI_MODE.Score:
        return (
          <ScoreFilterUI
            scoreSelected={scoreSelected}
            setScoreSelected={setScoreSelected}
            onReset={() => {
              setMode(FILTER_UI_MODE.Filter);
              setScoreSelected(null);
              setFilterScore(false);
            }}
            onApply={() => {
              setMode(FILTER_UI_MODE.Filter);
              isScoreSelected();
            }}
          />
        );
      case FILTER_UI_MODE.Distances:
        return (
          <DistanceFilterUI
            onReset={() => {
              setMode(FILTER_UI_MODE.Filter);
              setDistanceLevel(25);
              setSelectedCity('');
              setLngSelectedCity(0);
              setLatSelectedCity(0);
              setFilterDistance(false);
            }}
            onApply={() => {
              setMode(FILTER_UI_MODE.Filter);
              isDistanceSelected();
            }}
          />
        );
      case FILTER_UI_MODE.Availability:
        return (
          <AvailableFilterUI
            availableModes={availabilitiesMode}
            onReset={() => {
              setMode(FILTER_UI_MODE.Filter);
              setAvailabilitySelected('NO_PREFERENCE');
              setFilterAvailability(false);
            }}
            onApply={() => {
              setMode(FILTER_UI_MODE.Filter);
              isAvailability();
            }}
          />
        );
      default:
        return null;
    }
  };
  if (isDesktopWeb) {
    return (
      <ModalBox
        style={[
          Style.con({
            bg: Palette.white,
            w: '50%',
            ml: webSideBarWidth / 2,
            flex: 1,
            py: 20,
          }),
          {
            borderTopRightRadius: 20,
            borderTopLeftRadius: 20,
            height: responsiveHeight(70),
            maxHeight: responsiveHeight(70),
            maxWidth: webMaxContentWidth,
          },
          style,
        ]}
        useNativeDriver={true}
        backdropPressToClose={true}
        backdropOpacity={0.2}
        backdropColor={'black'}
        animationDuration={300}
        backdrop={true}
        backButtonClose={true}
        isOpen={isShow}
        swipeToClose={true}
        position={'bottom'}
        coverScreen={Platform.OS === 'android'}
        onOpened={onOpened}
        onClosed={_onClosed}>
        <View
          style={Style.con({
            w: 52,
            h: 5,
            bor: 5,
            bg: Palette.gray[4],
            self: 'center',
          })}
        />
        {renderModalBody()}
      </ModalBox>
    );
  }
  return (
    <ModalBox
      style={[
        Style.con({
          bg: Palette.white,
          w: DIMENSION_WIDTH,
          bor: 20,
          py: 20,
        }),
        {height: resHeight(625), maxWidth: webMaxContentWidth},
        style,
      ]}
      useNativeDriver={true}
      backdropPressToClose={true}
      backdropOpacity={0.2}
      backdropColor={'black'}
      animationDuration={300}
      backdrop={true}
      backButtonClose={true}
      isOpen={isShow}
      swipeToClose={true}
      position={'bottom'}
      coverScreen={Platform.OS === 'android'}
      onOpened={onOpened}
      onClosed={_onClosed}>
      <View
        style={Style.con({
          w: 52,
          h: 5,
          bor: 5,
          bg: Palette.gray[4],
          self: 'center',
        })}
      />
      {renderModalBody()}
    </ModalBox>
  );
};

export default SortFilterModal;
