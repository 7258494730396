import React, {useGlobal, useState} from 'reactn';
import {Text, View, Image, TouchableOpacity, TextInput} from 'react-native';
import {responsiveHeight} from 'react-native-responsive-dimensions';
import {Style, Fonts, Palette, gutters} from '../../styles';
import {Button, Input} from '../../components';
import {Routes} from '../../navigation';
import {icons} from '../../assets';
import firebase from '../../config/firebase';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {useNavigation} from '@react-navigation/native';
import Page from '../../components/Page';

export default ({}) => {
  const [, setIsGlobalLoading] = useGlobal('_isLoading');
  const [, setTooltip] = useGlobal('_tooltip');

  const [mail, setMail] = useState('aureliane.dor@gmail.com');
  const [passwd, setPasswd] = useState('videpoche');
  const navigation = useNavigation();

  const [viewPassword, setViewPassword] = useState(true);

  const onPressForgotYourPassword = () => {
    navigation?.navigate(Routes.ForgotPassword);
  };
  // const onPressLoginWithGoogle = () => {
  //   navigation?.navigate(Routes.AccountCreated);
  // };
  // const onPressLoginWithApple = () => {
  //   navigation?.navigate(Routes.AccountCreated);
  // };
  // const onPressLoginWithFacebook = () => {
  //   navigation?.navigate(Routes.AccountCreated);
  // };

  async function onPressLogin() {
    try {
      await setIsGlobalLoading(true);

      await firebase
        .auth()
        .signInWithEmailAndPassword(mail.trim(), passwd.trim());

      navigation?.navigate(Routes.Profile);
    } catch (error) {
      console.log('error', error);
      setTooltip({text: 'Problème de connexion, vérifiez vos identifiants'});
    } finally {
      await setIsGlobalLoading(false);
    }
  }

  return (
    <Page>
      <KeyboardAwareScrollView
        keyboardDismissMode={'interactive'}
        keyboardShouldPersistTaps={'handled'}
        contentInsetAdjustmentBehavior={'automatic'}
        enableOnAndroid={true}
        extraHeight={160}
        style={Style.con({flex: 1})}
        scrollEnabled>
        <View
          style={Style.con({
            flex: 1,
            px: gutters,
            mt: 50,
            justify: 'between',
          })}>
          <View style={Style.con({mb: 100})}>
            <Image
              source={icons.wink_full_red}
              style={Style.con({h: 90, w: 205, mb: 20, self: 'center'})}
            />
            <Text
              style={Fonts.t(25, Palette.black, {wei: '700', text: 'center'})}>
              Se connecter
            </Text>
          </View>
          <View style={Style.con({flex: 3, justify: 'flex-start'})}>
            <Input
              placeholder="Identifiant"
              style={Style.con({mb: responsiveHeight(1)})}
              value={mail}
              keyboardType={'email-address'}
              onChange={e => setMail(e)}
              autoCapitalize={'none'}
              autoCorrect={false}
            />
            <View
              style={Style.con({
                h: 48,
                direc: 'row',
                w: 'auto',
                px: gutters,
                bg: Palette.gray[2],
                bor: 10,
                mb: responsiveHeight(1),
              })}>
              <TextInput
                placeholder="Mot de passe"
                style={[
                  Style.con({flex: 1, pr: 8}),
                  Fonts.t(12, Palette.black, {wei: '700'}),
                ]}
                value={passwd}
                placeholderTextColor={Palette.gray[1]}
                onChangeText={e => setPasswd(e.trim())}
                secureTextEntry={viewPassword}
                autoCapitalize={'none'}
                autoCorrect={false}
              />
              <TouchableOpacity
                onPress={() => setViewPassword(!viewPassword)}
                style={Style.con({self: 'center'})}>
                <Image
                  source={viewPassword ? icons.iconEyeClose : icons.iconEyeOpen}
                  style={Style.con({size: 24})}
                />
              </TouchableOpacity>
            </View>
            <Button
              title="Connexion"
              onPress={onPressLogin}
              style={Style.con({mb: responsiveHeight(2)})}
            />
            <View style={Style.con({self: 'center'})}>
              <Text
                onPress={onPressForgotYourPassword}
                style={Fonts.t(12, Palette.primary, {wei: '700'})}>
                {'Mot de passe oublié ?'}
              </Text>
            </View>
          </View>
          {/* <View>
            <Text
              style={Fonts.t(12, Palette.gray[1], {
                wei: "700",
                self: "center",
                t: responsiveHeight(7),
              })}
            >
              {"Ou connectez-vous avec vos réseaux sociaux"}
            </Text>
            <View
              style={Style.con({
                direc: "row",
                flex: 1,
                justify: "flex-end",
              })}
            >
              <TouchableOpacity
                // onPress={onPressLoginWithGoogle}
                style={[
                  Style.con({
                    flex: 1,
                    bg: Palette.gray[2],
                    cen: true,
                    py: responsiveHeight(1),
                    my: responsiveHeight(1),
                  }),
                  Style.border({ bor: 10, color: Palette.gray[3], width: 1 }),
                ]}
              >
                <Image
                  style={Style.con({ size: 36 })}
                  source={icons.iconGoogle}
                />
              </TouchableOpacity>
              <View style={Style.con({ w: 15 })} />
              <TouchableOpacity
                // onPress={onPressLoginWithApple}
                style={[
                  Style.con({
                    flex: 1,
                    bg: Palette.gray[2],
                    cen: true,
                    py: responsiveHeight(1),
                    my: responsiveHeight(1),
                  }),
                  Style.border({ bor: 10, color: Palette.gray[3], width: 1 }),
                ]}
              >
                <Image style={Style.con({ size: 36 })} source={icons.iconApple} />
              </TouchableOpacity>
              <View style={Style.con({ w: 15 })} />
              <TouchableOpacity
                // onPress={onPressLoginWithFacebook}
                style={[
                  Style.con({
                    flex: 1,
                    bg: Palette.gray[2],
                    cen: true,
                    py: responsiveHeight(1),
                    my: responsiveHeight(1),
                  }),
                  Style.border({ bor: 10, color: Palette.gray[3], width: 1 }),
                ]}
              >
                <Image
                  style={Style.con({ size: 36 })}
                  source={icons.iconFacebook}
                />
              </TouchableOpacity>
            </View>
            <View style={Style.con({ flex: 1, justify: "center" })}>
              <Text
                style={Fonts.t(12, Palette.gray[1], {
                  wei: "700",
                  self: "center",
                  y: responsiveHeight(2),
                })}
              >
                {"Vous n'avez pas de compte? "}
                <Text
                  onPress={() => navigation?.navigate(Routes.Inscription)}
                  style={Fonts.t(12, Palette.primary, { wei: "700" })}
                >
                  {" Créer un compte"}
                </Text>
              </Text>
            </View>
          </View> */}
        </View>
      </KeyboardAwareScrollView>
    </Page>
  );
};
