import React, {useEffect, useState} from 'react';
import {
  TextInput,
  View,
  Image,
  Pressable,
  FlatList,
  ActivityIndicator,
  Text,
} from 'react-native';
import {responsiveHeight} from 'react-native-responsive-dimensions';
import {Video} from 'expo-av';
import {Style, Palette, gutters, Fonts} from '../../styles';
import {icons} from '../../assets';
import {BOTTOM_BAR_HEIGHT, DIMENSION_WIDTH} from '../../common';
import useDataFromRef from '../../hooks/useDataFromRef';
import {postsRef, institutesRef} from '../../config/firebase';
import {Routes} from '../../navigation';
import {useNavigation} from '@react-navigation/native';
import useAlgoliaSearch from '../../hooks/useAlgoliaSearch';
import {useGlobal} from 'reactn';
import {isDesktopWeb, isMobileWeb} from '../../config/layoutConstants';
import Page from '../../components/Page';
import {validateDate} from '../../actions/dateActions';
import {openToday, openThreeDays} from '../../helpers/openFilter';
import {algoliaSearchKey, algoliaAppId} from '../../config/apiKeys';

export default ({route}) => {
  const NUMBER_PER_BATCH = 10;
  const navigation = useNavigation();

  const onPressFilter = () => setShowFilterModal(['PRICE', 'AVAILABILITY']);

  const [filterPrice] = useGlobal('filterPrice');
  const [filterAvailability] = useGlobal('filterAvailability');
  const [minPrice] = useGlobal('minPrice');
  const [maxPrice] = useGlobal('maxPrice');
  const [priceSelected] = useGlobal('priceSelected');
  const [, setShowFilterModal] = useGlobal('showFilterModal');
  const [availabilitySelected] = useGlobal('availabilitySelected');
  const [filterSelected, setFilterSelected] = useState(false);
  const [query, setQuery] = useState(postsRef);
  const [searchText, setSearchText] = useState('');
  const [postList, setPostList] = useState([]);

  const result = useAlgoliaSearch({
    query: searchText,
    algoliaObject: {
      index: 'posts',
      projectID: algoliaAppId,
      publicKey: algoliaSearchKey,
    },
  });

  useEffect(() => {
    let newQuery = postsRef;

    if (priceSelected) {
      newQuery = newQuery.where('prestationData.price', '<=', priceSelected);
    }

    if (minPrice) {
      newQuery = newQuery.where('prestationData.price', '>=', minPrice);
    }

    if (maxPrice) {
      newQuery = newQuery.where('prestationData.price', '<=', maxPrice);
    }

    if (filterPrice || filterAvailability) {
      setFilterSelected(true);
    } else {
      setFilterSelected(false);
    }

    setQuery(newQuery);
  }, [minPrice, maxPrice, priceSelected, filterPrice, filterAvailability]);

  const {
    data: posts,
    loadMore,
    loading,
  } = useDataFromRef({
    ref: query,
    simpleRef: false,
    documentID: 'postId',
    initialState: [],
    refreshArray: [
      minPrice,
      maxPrice,
      priceSelected,
      query,
      availabilitySelected,
    ],
    usePagination: true,
    listener: true,
    batchSize: NUMBER_PER_BATCH,
    format: async (data = []) => {
      try {
        const promiseInstitute = [];

        data.map(postData => {
          promiseInstitute.push(
            new Promise(async (resolve, reject) => {
              try {
                const {instituteId} = postData;
                const instituteData =
                  (await institutesRef.doc(instituteId).get())?.data() || {};

                resolve({
                  ...postData,
                  instituteData,
                });
              } catch (error) {
                reject(error);
              }
            }),
          );
        });
        const newPostList = await Promise.all(promiseInstitute);
        return newPostList.map(newPost => ({
          ...newPost,
          datePost: validateDate(newPost.datePost),
        }));
      } catch (error) {
        console.log(error);
      }
    },
  });

  useEffect(() => {
    let newPostList = posts;
    if (availabilitySelected === 'OPEN_NOW' && newPostList.length > 0) {
      newPostList = newPostList.filter(post =>
        openToday(post.instituteData.openingHours),
      );
      setPostList(newPostList);
      return;
    }
    if (availabilitySelected === 'IN_THREE_DAYS' && newPostList.length > 0) {
      newPostList = newPostList.filter(post =>
        openThreeDays(post.instituteData.openingHours),
      );
      setPostList(newPostList);
    }
    setPostList(posts);
  }, [availabilitySelected, posts]);

  const loadingView = () => {
    if (loading) {
      return (
        <View style={Style.con({flex: 1, justify: 'center'})}>
          <ActivityIndicator size="large" />
        </View>
      );
    } else {
      return null;
    }
  };

  return (
    <Page>
      <View
        style={Style.con({
          direc: 'row',
          items: 'center',
          mb: isDesktopWeb ? 20 : responsiveHeight(1),
          mt: isMobileWeb ? responsiveHeight(1) : 0,
          justify: 'space-between',
          px: gutters,
        })}>
        <View
          style={Style.con({
            flex: 1,
            direc: 'row',
            items: 'center',
            bg: Palette.gray[2],
            bor: 10,
            mr: gutters / 1.5,
            h: responsiveHeight(5),
            px: gutters / 1.5,
            mt: isDesktopWeb ? responsiveHeight(1) : 0,
          })}>
          <Image
            style={Style.con({size: 20, tin: Palette.black})}
            source={icons.iconSearch2}
          />
          <TextInput
            style={[Style.con({flex: 1, pl: 8})]}
            placeholder="Rechercher"
            placeholderTextColor={Palette.black}
            autoCorrect={false}
            value={searchText}
            onChangeText={text => setSearchText(text)}
          />
        </View>
        <Pressable
          style={Style.con({size: 27, cen: true})}
          onPress={onPressFilter}>
          <Image
            style={Style.con({
              size: 27,
              tin: filterSelected ? Palette.primary : Palette.black,
            })}
            source={icons.iconFilter}
          />
        </Pressable>
      </View>
      <View style={Style.con({flex: 1})}>
        {searchText.length > 0 && result.length < 1 ? (
          <View style={Style.con({flex: 1, justify: 'center', self: 'center'})}>
            <Image
              style={Style.con({size: 40, self: 'center'})}
              source={icons.iconGallery2}
            />
            <Text style={Fonts.t(15, Palette.black)}>Aucun résultat</Text>
          </View>
        ) : (
          <View style={Style.con({flex: 1})}>
            <FlatList
              style={Style.con({flex: 1})}
              contentContainerStyle={Style.con({pb: BOTTOM_BAR_HEIGHT})}
              showsVerticalScrollIndicator={false}
              data={searchText.length > 0 ? result : postList}
              initialNumToRender={NUMBER_PER_BATCH}
              numColumns={isDesktopWeb ? 3 : 2}
              ListFooterComponent={loadingView}
              ListEmptyComponent={() => {
                return (
                  <View
                    style={Style.con({
                      justify: 'center',
                      self: 'center',
                      my: responsiveHeight(30),
                    })}>
                    <Image
                      style={Style.con({size: 40, self: 'center'})}
                      source={icons.iconGallery2}
                    />
                    <Text style={[Fonts.t(15, Palette.black)]}>
                      Pas de posts publiés
                    </Text>
                  </View>
                );
              }}
              ItemSeparatorComponent={() => {
                return <View style={Style.con({h: 2, bg: Palette.white})} />;
              }}
              renderItem={({item: post, index}) => {
                const postId = post?.postId || post?.objectID;

                const onPressPost = () => {
                  navigation.navigate(Routes.PostDetails, {postId});
                };
                const onPressVideo = () => {
                  navigation?.navigate(Routes.ReelsStack, post);
                };
                return (
                  <>
                    <Pressable
                      key={postId}
                      style={[
                        Style.con({size: DIMENSION_WIDTH / 2}),
                        {
                          maxHeight: 300,
                          maxWidth: isDesktopWeb ? `${100 / 3}%` : '50%',
                        },
                        isDesktopWeb
                          ? Style.con({px: 1})
                          : index % 2 === 0
                          ? Style.con({pr: 1})
                          : Style.con({pl: 1}), // vertical separator between posts
                      ]}
                      onPress={
                        post.type === 'image' ? onPressPost : onPressVideo
                      }>
                      {(post?.type === 'image' && (
                        <Image
                          style={{width: '100%', height: '100%'}}
                          source={{
                            uri: post?.mediaURL,
                          }}
                        />
                      )) || (
                        <Video
                          source={{uri: post?.mediaURL}}
                          style={{width: '100%', height: '100%'}}
                          resizeMode="cover"
                          paused={false}
                          isLooping
                          shouldPlay
                          isMuted
                        />
                      )}
                    </Pressable>
                  </>
                );
              }}
            />
          </View>
        )}
      </View>
    </Page>
  );
};
