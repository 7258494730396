import React, {useGlobal, useState} from 'reactn';
import SortFilterModal from '../components/SortFilterModal';

export default ({children}) => {
  const [minPrice, setMinPrice] = useGlobal('minPrice');
  const [maxPrice, setMaxPrice] = useGlobal('maxPrice');
  const [priceSelected, setPriceSelected] = useGlobal('priceSelected');
  const [scoreSelected, setScoreSelected] = useGlobal('scoreSelected');
  const [mode, setMode] = useGlobal('mode');
  const [showFilterModal, setShowFilterModal] = useGlobal('showFilterModal');
  const [distanceLevel, setDistanceLevel] = useGlobal('distanceLevel');
  const [selectedCity, setSelectedCity] = useGlobal('selectedCity');
  const [latSelectedCity, setLatSelectedCity] = useGlobal('latSelectedCity');
  const [lngSelectedCity, setLngSelectedCity] = useGlobal('lngSelectedCity');
  const [availabilitySelected, setAvailabilitySelected] = useGlobal("availabilitySelected");


  return (
    <React.Fragment>
      {children}
      <SortFilterModal
        minPrice={minPrice}
        setMinPrice={setMinPrice}
        maxPrice={maxPrice}
        setMaxPrice={setMaxPrice}
        priceSelected={priceSelected}
        setPriceSelected={setPriceSelected}
        scoreSelected={scoreSelected}
        setScoreSelected={setScoreSelected}
        mode={mode}
        setMode={setMode}
        isShow={showFilterModal.length > 0}
        onClosed={() => {
          setShowFilterModal([]);
        }}
        distanceLevel={distanceLevel}
        setDistanceLevel={setDistanceLevel}
        selectedCity={selectedCity}
        setSelectedCity={setSelectedCity}
        setLatSelectedCity={setLatSelectedCity}
        setLngSelectedCity={setLngSelectedCity}
        availabilitySelected={availabilitySelected}
        setAvailabilitySelected={setAvailabilitySelected}
      />
    </React.Fragment>
  );
};
