import React from 'react';
import {
  Text,
  View,
  TouchableOpacity,
  Image,
  FlatList,
  ActivityIndicator,
} from 'react-native';
import {Style, Fonts, Palette, gutters} from '../../styles';
import {Button, Header} from '../../components';
import {Routes} from '../../navigation';
import {DIMENSION_WIDTH} from '../../common';
import useDataFromRef from '../../hooks/useDataFromRef';
import {postsRef} from '../../config/firebase';
import {useNavigation, StackActions} from '@react-navigation/native';
import Video from 'expo-av';
import {useGlobal} from 'reactn';
import {
  responsiveHeight,
  responsiveWidth,
} from 'react-native-responsive-dimensions';
import Page from '../../components/Page';
import {isDesktopWeb, isMobileWeb} from '../../config/layoutConstants';

export default ({route}) => {
  const navigation = useNavigation();
  const {
    // item,
    prestation,
    instituteName,
    instituteId,
    instituteLocation,
    instituteInstagram,
    instituteProfilPicture,
    categories,
    phone,
  } = route?.params;
  const {name, prestationId} = prestation;

  const [selectedPrestations, setSelectedPrestations] = useGlobal(
    'selectedPrestations',
  );

  const {
    data: postsList,
    loading,
    loadMore,
  } = useDataFromRef({
    ref: postsRef.where('prestationId', '==', prestationId),
    simpleRef: false,
    refreshArray: [prestation],
    documentID: 'postId',
    initialState: [],
    usePagination: true,
    batchSize: 10,
    listener: true,
  });

  function onPressBack() {
    navigation?.goBack();
  }

  function onPressAppointment(prestation) {
    try {
      let newSelectedprestation = {...selectedPrestations};

      if (
        !(newSelectedprestation?.[instituteId] || [])
          .map(({prestationId}) => prestationId)
          .includes(prestation.prestationId)
      ) {
        newSelectedprestation[instituteId] = [
          ...(newSelectedprestation?.[instituteId] || []),
          prestation,
        ];
        setSelectedPrestations(newSelectedprestation);
      }
      navigation?.navigate(Routes.ChooseTimeDate, {
        institute: {
          instituteName,
          instituteProfilPicture,
          instituteId,
          instituteLocation,
          instituteInstagram,
          categories,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  const loadingView = () => {
    if (loading) {
      return (
        <View style={Style.con({flex: 1, justify: 'center'})}>
          <ActivityIndicator size="large" />
        </View>
      );
    } else {
      return null;
    }
  };

  return (
    <Page>
      <Header
        back
        navigation={navigation}
        title={name}
        subTitle={instituteName.toUpperCase()}
      />
      <FlatList
        data={postsList}
        numColumns={3}
        keyExtractor={item => item?.postId}
        showsVerticalScrollIndicator={false}
        onEndReached={loadMore}
        onEndReachedThreshold={0.5}
        ListFooterComponent={loadingView}
        ItemSeparatorComponent={() => {
          return <View style={Style.con({h: 2, bg: Palette.white})} />;
        }}
        initialNumToRender={10}
        renderItem={({item: post, index}) => {
          const itemSize = (DIMENSION_WIDTH - 3) / 3;
          const itemStyle = index % 3 === 1 ? Style.con({mx: 2}) : {};

          const {mediaURL, postId} = post;

          const INSTITUTE_POSTS = true;

          const pushAction = StackActions.push(Routes.PostDetails, {
            postId,
            INSTITUTE_POSTS,
            instituteName,
            instituteId,
          });

          function onPressPost() {
            navigation?.dispatch(pushAction);
          }

          function onPressVideo() {
            navigation?.navigate(Routes.ReelsStack, post);
          }

          return (
            <TouchableOpacity
              key={postId}
              style={isDesktopWeb ? {width: '33%', height: 200} : itemStyle}
              onPress={post?.type === 'video' ? onPressVideo : onPressPost}>
              {(post?.type === 'image' && (
                <Image
                  style={
                    isDesktopWeb
                      ? {
                          width: '100%',
                          height: '100%',
                        }
                      : Style.con({size: itemSize})
                  }
                  source={{
                    uri: mediaURL,
                  }}
                />
              )) || (
                <Video
                  source={{uri: post?.mediaURL}}
                  style={
                    isDesktopWeb
                      ? {
                          width: '100%',
                          height: '100%',
                        }
                      : Style.con({size: itemSize})
                  }
                  resizeMode="cover"
                  paused={true}
                />
              )}
            </TouchableOpacity>
          );
        }}
      />
    </Page>
  );
};
