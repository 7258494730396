import React from 'react';
import {Text, TouchableOpacity} from 'react-native';

import {Style, Fonts, Palette} from '../styles';

export const PROFILE_TABS = {
  Appointment: 'Appointment',
  Favoris: 'Favoris',
};

export const TabView = ({title = '', isSelected = false, onTap = () => {}}) => {
  return (
    <TouchableOpacity
      activeOpacity={1}
      onPress={onTap}
      style={[
        Style.con({flex: 1, cen: true}),
        Style.border({
          bbw: 1,
          color: isSelected ? Palette.primary : Palette.gray[11],
        }),
      ]}>
      <Text
        style={Fonts.t(13, isSelected ? Palette.primary : Palette.black, {
          wei: '600',
        })}>
        {title}
      </Text>
    </TouchableOpacity>
  );
};

export default TabView;
