import React, { useGlobal } from "reactn";
import {
  Platform,
  View,
  Text,
  TouchableOpacity,
  Image,
  TextInput,
} from "react-native";
import ModalBox from "react-native-modalbox";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import ImagePicker from "expo-image-picker";
import ImagePickers from "../components/ImagePicker";
import { uploadPictureToFirebase } from "../helpers/uploadToFirebase";

import { Fonts, gutters, Palette, Style, resHeight, resWidth } from "../styles";
import Button from "./Button";
import { BOTTOM_BAR_HEIGHT, DIMENSION_WIDTH } from "../common";
import { icons } from "../assets";
import firebase, {reviewsRef} from "../config/firebase";
import moment from "moment";
import { isDesktopWeb } from "../config/layoutConstants";

export function Rating(rating) {
  return (
    Object.values(rating).reduce(
      (acc, curr, index) => acc + curr * (index + 1),
      0
    ) / Object.values(rating).reduce((acc, curr) => acc + curr, 0)
  );
}

const ReviewModal = ({
  style = {},
  isShow = false,
  onClosed = () => {},
  meetingRef,
}) => {
  const [starSelected, setStarSelected] = React.useState(0);
  const [currentUserData, setCurrentUserData] = useGlobal("currentUserData");
  const [isGlobalLoading, setIsGlobalLoading] = useGlobal("_isLoading");
  const [tooltip, setTooltip] = useGlobal("_tooltip");
  const db = firebase.firestore();
  const meetingDate = moment(meetingRef?.date).format(
    "DD MMMM YYYY [à] HH [h]"
  );

  const [reviewText, setReviewText] = React.useState("");
  const [imageReview, setImageReview] = React.useState("");

  const onOpened = () => undefined;
  const _onClosed = () => {
    if (onClosed) {
      onClosed();
    }
  };
  const onPressUseCamera = async () => {
    try {
      const image = await ImagePicker.openCamera({
        width: 300,
        height: 400,
        cropping: true,
      });
      setImageReview(image.path);
    } catch (error) {
      console.log(error);
    }
  };
  //TODO: revoir la publication de document 
  const onSubmitReview = async () => {
    if (starSelected === 0) {
      setTooltip({ text: "Veuillez selectionner une note" });
    } else {
      try {
        const newReviewRef = reviewsRef.doc();

        await setIsGlobalLoading(true);
        const uri = imageReview
          ? await uploadPictureToFirebase({
              uri: imageReview || "",
              path: "reviews/" + newReviewRef.id + "/reviewsPicture.jpg",
            })
          : null;
        await newReviewRef.set({
            userData: {
              id: currentUserData.id,
              firstName: currentUserData.contactDetails.firstName,
              lastName: currentUserData.contactDetails.lastName,
              profilPicture: currentUserData.profilPicture,
            },
            type: "data",
            note: starSelected,
            display: true,
            img: uri?.resultURI || "",
            instituteId: meetingRef?.instituteData.instituteId,
            text: reviewText || "-",
            meetingId: meetingRef?.meetingId,
            creatingDate: new Date(),
          });
        await setTooltip({ text: "Votre avis a bien été enregistré" });
      } catch (error) {
        console.log(error);
      } finally {
        await setIsGlobalLoading(false);
        _onClosed();
        setReviewText("");
        setStarSelected(0);
        setImageReview("");
      }
    }
  };

  return (
    <ModalBox
      style={[
        Style.con({
          bg: Palette.white,
          w: isDesktopWeb ? "100%" : DIMENSION_WIDTH,
          bor: 20,
          over: "hidden",
        }),
        { height: resHeight(625) + BOTTOM_BAR_HEIGHT },
        style,
      ]}
      useNativeDriver={true}
      backdropPressToClose={true}
      backdropOpacity={0.2}
      backdropColor={"black"}
      animationDuration={300}
      backdrop={true}
      backButtonClose={true}
      isOpen={isShow}
      swipeToClose={true}
      position={"bottom"}
      coverScreen={Platform.OS === "android"}
      onOpened={onOpened}
      onClosed={_onClosed}
    >
      <KeyboardAwareScrollView
        keyboardDismissMode={"interactive"}
        keyboardShouldPersistTaps={"handled"}
        contentInsetAdjustmentBehavior={"automatic"}
        enableOnAndroid={true}
        extraHeight={160}
        style={Style.con({ flex: 1, pt: 0 })}
        scrollEnabled
      >
        <View style={Style.con({ flex: 1, px: gutters })}>
          <View style={Style.con({ flex: 1 })}>
            <Text
              style={Fonts.t(27, Palette.black, {
                wei: "700",
                self: "center",
                t: 20,
              })}
            >
              {meetingRef?.instituteData?.instituteName}
            </Text>
            <Text
              style={Fonts.t(16, Palette.black, {
                wei: "400",
                self: "center",
                t: 8,
              })}
            >
              {`Rendez-vous passé • ${meetingDate}`}
            </Text>
            <Text
              style={Fonts.t(18, Palette.black, {
                wei: "700",
                self: "center",
                t: 49,
              })}
            >
              {"Notez votre rendez-vous"}
            </Text>
            <View style={Style.con({ direc: "row", self: "center", mt: 20 })}>
              {[1, 2, 3, 4, 5].map((item) => {
                const isSelected = item <= starSelected;
                return (
                  <TouchableOpacity
                    onPress={() => setStarSelected(item)}
                    key={item}
                    style={Style.con({ mx: 8 })}
                  >
                    <Image
                      style={Style.con({ size: 44 })}
                      source={
                        isSelected
                          ? icons.iconStarSelect
                          : icons.iconStarUnselect
                      }
                    />
                  </TouchableOpacity>
                );
              })}
            </View>
            <View
              style={Style.con({
                mx: 20,
                h: 134,
                bor: 15,
                bg: Palette.gray[16],
                p: 16,
                mt: 20,
              })}
            >
              <TextInput
                style={[Style.con({ flex: 1 })]}
                multiline={true}
                textAlignVertical={"top"}
                placeholder="Rajoutez votre commentaire ici..."
                placeholderTextColor={Palette.gray[1]}
                value={reviewText}
                onChangeText={(text) => setReviewText(text)}
              />
            </View>
            <Text
              style={Fonts.t(18, Palette.black, {
                wei: "700",
                self: "center",
                t: 28,
              })}
            >
              {"Ajoutez une photo ou une vidéo"}
            </Text>
            <View style={Style.con({ my: 16, direc: "row", self: "center" })}>
              <TouchableOpacity
                onPress={onPressUseCamera}
                style={[
                  Style.con({ size: 80, cen: true, mx: 12 }),
                  Style.border({
                    das: true,
                    color: Palette.gray[18],
                    width: 2,
                    bor: 10,
                  }),
                  {
                    display: isDesktopWeb ? "none" : "flex",
                  }
                ]}
              >
                <Image
                  style={Style.con({ size: 40 })}
                  source={icons.iconCamera}
                />
              </TouchableOpacity>
              <ImagePickers
                onComplete={async ({ uri }) => {
                  setImageReview(uri);
                }}
                style={[
                  Style.con({ size: 80, cen: true, mx: 12 }),
                  Style.border({
                    das: true,
                    color: Palette.gray[18],
                    width: 2,
                    bor: 10,
                  }),
                ]}
              >
                {imageReview !== "" ? (
                  <Image
                    style={Style.con({ size: 60 })}
                    source={{ uri: imageReview }}
                  />
                ) : (
                  <Image
                    style={Style.con({ size: 40 })}
                    source={icons.iconGallery2}
                  />
                )}
              </ImagePickers>
            </View>
          </View>
          <Button
            onPress={onSubmitReview}
            title={"Envoyer mon avis"}
            style={Style.con({ mb: BOTTOM_BAR_HEIGHT + 32 })}
          />
        </View>
      </KeyboardAwareScrollView>
    </ModalBox>
  );
};

export default ReviewModal;
