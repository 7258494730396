import React from 'react';
import {
  View,
  Image,
  FlatList,
  TouchableOpacity,
  Text,
  Platform,
} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {Style, Palette} from '../../styles';
import {ReelsVideo} from '../../components';
import {icons} from '../../assets';
import useDataFromRef from '../../hooks/useDataFromRef';
import {postsRef, institutesRef} from '../../config/firebase';
import {isDesktopWeb} from '../../config/layoutConstants';
import Page from '../../components/Page';
import {useIsFocused} from '@react-navigation/core';
import {
  responsiveHeight,
  responsiveWidth,
} from 'react-native-responsive-dimensions';
import {BOTTOM_BAR_HEIGHT} from '../../common';

export default ({navigation}) => {
  const onPressBack = () => navigation?.goBack();

  const NUMBER_PER_BATCH = 10;

  const isFocused = useIsFocused();

  const {data: postsList, loadMore} = useDataFromRef({
    ref: postsRef.where('type', '==', 'reel'),
    simpleRef: false,
    documentID: 'postId',
    usePagination: true,
    batchSize: NUMBER_PER_BATCH,
    format: async data => {
      try {
        const promiseInstituteData = [];
        data.map(postData =>
          promiseInstituteData.push(
            new Promise(async (resolve, reject) => {
              try {
                const {instituteId} = postData;
                const instituteData = await institutesRef
                  .doc(instituteId)
                  .get();
                resolve({
                  ...postData,
                  instituteData: {
                    ...instituteData.data(),
                  },
                });
              } catch (error) {
                reject(error);
              }
            }),
          ),
        );
        return Promise.all(promiseInstituteData);
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <Page containerType="VIEW">
      <View style={Style.con({flex: 1, bg: Palette.white})}>
        {postsList?.length > 0 ? (
          <>
            <SafeAreaView
              pointerEvents="box-none"
              style={[
                Style.con({pos: 'absolute', z: 2, t: 0, l: 0, b: 0, r: 0}),
              ]}>
              {!isDesktopWeb && (
                <TouchableOpacity
                  onPress={onPressBack}
                  style={Style.con({size: 40, cen: true, ml: 12})}>
                  <Image
                    style={Style.con({size: 24, tin: Palette.white})}
                    source={icons.iconArrowLeft}
                  />
                </TouchableOpacity>
              )}
            </SafeAreaView>
            <View
              style={{
                height: responsiveHeight(100) - BOTTOM_BAR_HEIGHT,
                // width: responsiveWidth(100),
                backgroundColor: Palette.black,
              }}>
              <FlatList
                style={{flex: 1}}
                removeClippedSubviews={Platform.OS !== 'web'}
                scrollEventThrottle={8}
                showsVerticalScrollIndicator={false}
                data={postsList}
                onEndReached={loadMore}
                snapToInterval={responsiveHeight(100) - BOTTOM_BAR_HEIGHT}
                initialNumToRender={NUMBER_PER_BATCH}
                onEndReachedThreshold={0.5}
                pagingEnabled
                decelerationRate={'fast'}
                disableIntervalMomentum
                renderItem={({item, index}) => {
                  return (
                    <ReelsVideo
                      key={item?.postId}
                      {...item}
                      instituteData={item?.instituteData}
                      navigation={navigation}
                      play={isFocused}
                      index={index}
                    />
                  );
                }}
              />
            </View>
          </>
        ) : (
          <View style={Style.con({flex: 1, cen: true, bg: 'white'})}>
            <Text>{'Aucun réel trouvé'}</Text>
          </View>
        )}
      </View>
    </Page>
  );
};
