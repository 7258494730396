import React, { useState } from "react";
import { View, Text, TouchableOpacity, Image, FlatList } from "react-native";
import { Fonts, gutters, Palette, Style } from "../styles";
import { icons } from "../assets";

import moment from "moment";
import { isDesktopWeb, isMobileWeb } from "../config/layoutConstants";

export const dayOfWeekNames = ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"];

export const DAY_LENGTH = 1000 * 60 * 60 * 24; //the length of a day in milliseconds

const DatePickerWidget = ({ daySelected, setDaySelected, style }) => {
  const [currentMonth, setCurrentMonth] = useState(moment().format("MM/YYYY"));

  const ITEM_WIDTH = 27;
  const ITEM_MARGIN = 12;

  const isCurrentMonth = moment(currentMonth, "MM/YYYY").isSame(
    moment(),
    "month"
  )
    ? true
    : false;

  const RenderDays = () => (
    <FlatList
      data={new Array(moment().daysInMonth())
        .fill(null)
        .map((x, i) => moment().startOf("month").add(i, "days"))
        .filter((x) => {
          if (isCurrentMonth) {
            return moment(x).isSameOrAfter(moment(), "day");
          } else {
            return true;
          }
        })}
      {...(isDesktopWeb
        ? {}
        : {
            horizontal: true,
            keyExtractor: (item, index) => index.toString(),
          })}
      contentContainerStyle={[
        Style.con({ pt: 20, pb: 12 }),
        isDesktopWeb
          ? {
              alignSelf: "center",
              alignContent: "center",
              justifyContent: "center",
              flexDirection: "initial",
              flexWrap: "wrap",
            }
          : {
              alignSelf: "center",
              alignContent: "center",
            },
      ]}
      style={isMobileWeb ? { width: "100%" } : {}}
      renderItem={({ item }) => {
        const day = moment(item).set({
          year: moment(currentMonth, "MM/YYYY").year(),
          month: moment(currentMonth, "MM/YYYY").month(),
        });

        const isSelected = day.isSame(daySelected, "date");
        const isBeforeToday = moment().isAfter(day, "day");

        return (
          <TouchableOpacity
            onPress={() => {
              setDaySelected(day);
            }}
            style={Style.con({
              mx: ITEM_MARGIN,
              mt: isDesktopWeb ? 20 : 0,
            })}
            disabled={isBeforeToday}
          >
            <Text
              style={Fonts.t(
                16,
                isBeforeToday ? Palette.gray[6] : Palette.black,
                { b: 10 }
              )}
            >
              {day.format("ddd")}
            </Text>
            <View
              style={Style.con({
                size: ITEM_WIDTH,
                bor: 27,
                bg: isBeforeToday
                  ? null
                  : isSelected
                  ? Palette.primary
                  : Palette.tran,
                cen: true,
              })}
            >
              <Text
                style={Fonts.t(
                  15,
                  isBeforeToday
                    ? Palette.gray[6]
                    : isSelected
                    ? Palette.white
                    : Palette.black,
                  { wei: "600" }
                )}
              >
                {day.format("DD")}
              </Text>
            </View>
          </TouchableOpacity>
        );
      }}
    />
  );

  return (
    <View
      style={[
        Style.con({
          bg: Palette.gray[2],
          bor: 15,
          px: gutters / 1.5,
          pt: 20,
          pb: 8,
        }),
        style,
        {
          overflow: isDesktopWeb ? "hidden" : "visible",
        },
      ]}
    >
      <View style={Style.con({ direc: "row" })}>
        <TouchableOpacity
          style={Style.con({ size: 30, op: !isCurrentMonth ? 1 : 0.2 })}
          onPress={() => {
            if (!isCurrentMonth) {
              setCurrentMonth(
                moment(currentMonth, "MM/YYYY")
                  .subtract(1, "months")
                  .format("MM/YYYY")
              );
            }
          }}
        >
          <Image
            style={Style.con({ size: 24, rotate: "90deg" })}
            source={icons.iconDown}
          />
        </TouchableOpacity>

        <View style={Style.con({ flex: 1, cen: true })}>
          <Text style={Fonts.t(15, Palette.black, { wei: "700" })}>
            {moment(currentMonth, "MM/YYYY").format("MMM YYYY")}
          </Text>
        </View>

        <TouchableOpacity
          onPress={() => {
            setCurrentMonth(
              moment(currentMonth, "MM/YYYY").add(1, "months").format("MM/YYYY")
            );
          }}
          style={Style.con({ size: 30 })}
        >
          <Image
            style={Style.con({ size: 24, rotate: "270deg" })}
            source={icons.iconDown}
          />
        </TouchableOpacity>
      </View>

      <View
        style={{
          alignItems: "center",
        }}
      >
        {RenderDays()}
      </View>
    </View>
  );
};

export default DatePickerWidget;
