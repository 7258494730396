import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';

import {Palette} from '../styles';
import {Routes} from './Routes';

import Profile from '../screens/tabs/Profile';
import MyAccount from '../screens/MyAccount';
import MoveAppointment from '../screens/booking/MoveAppointment';
import Privacy from '../screens/Privacy';
import About from '../screens/About';
import Help from '../screens/Help';
import Notifications from '../screens/Notifications';
import InstituteProfile from '../screens/InstituteProfile';
import PostDetails from '../screens/posts/PostDetails';
import PostByHashtag from '../screens/posts/PostByHashtag';
import PostsRelatedToService from '../screens/posts/PostsRelatedToService';
import Inscription from '../screens/auth/Inscription';
import Login from '../screens/auth/Login';
import ForgotPassword from '../screens/auth/ForgotPassword';
import MakeAppointment from '../screens/booking/MakeAppointment';
import ChooseTimeDate from '../screens/ChooseTimeDate';
import Recap from '../screens/Recap';

const screenOptions = {
  headerShown: false,
  cardOverlayEnabled: true,
  headerTitleAlign: 'center',
  headerBackTitleVisible: false,
};

const ProfileStack = createStackNavigator();

export default function ProfileStackScreen({navigation}) {
  return (
    <ProfileStack.Navigator
      initialRouteName={Routes.Profile}
      screenOptions={[
        {
          contentStyle: {
            backgroundColor: Palette.white,
          },
        },
        screenOptions,
      ]}>
      <ProfileStack.Screen
        name={Routes.Profile}
        component={Profile}
        options={{headerShown: false}}
      />
      <ProfileStack.Screen
        name={Routes.MyAccount}
        component={MyAccount}
        options={{headerShown: false}}
      />
      <ProfileStack.Screen
        name={Routes.MoveAppointment}
        component={MoveAppointment}
        options={{headerShown: false}}
      />
      <ProfileStack.Screen
        name={Routes.Privacy}
        component={Privacy}
        options={{headerShown: false}}
      />
      <ProfileStack.Screen
        name={Routes.About}
        component={About}
        options={{headerShown: false}}
      />
      <ProfileStack.Screen
        name={Routes.Help}
        component={Help}
        options={{headerShown: false}}
      />
      <ProfileStack.Screen
        name={Routes.Notifications}
        component={Notifications}
        options={{headerShown: false}}
      />
      <ProfileStack.Screen
        name={Routes.InstituteProfile}
        component={InstituteProfile}
        options={{headerShown: false}}
      />
      <ProfileStack.Screen
        name={Routes.PostDetails}
        component={PostDetails}
        options={{headerShown: false}}
      />
      <ProfileStack.Screen
        name={Routes.PostByHashtag}
        component={PostByHashtag}
        options={{headerShown: false}}
      />
      <ProfileStack.Screen
        name={Routes.PostsRelatedToService}
        component={PostsRelatedToService}
        options={{headerShown: false}}
      />
      <ProfileStack.Screen
        name={Routes.Inscription}
        component={Inscription}
        options={{headerShown: false}}
      />
      <ProfileStack.Screen
        name={Routes.Login}
        component={Login}
        options={{headerShown: false}}
      />
      <ProfileStack.Screen
        name={Routes.ForgotPassword}
        component={ForgotPassword}
        options={{headerShown: false}}
      />
      <ProfileStack.Screen
        name={Routes.MakeAppointment}
        component={MakeAppointment}
        options={{headerShown: false}}
      />
      <ProfileStack.Screen
        name={Routes.ChooseTimeDate}
        component={ChooseTimeDate}
        options={{headerShown: false}}
      />
      <ProfileStack.Screen
        name={Routes.Recap}
        component={Recap}
        options={{headerShown: false}}
      />
    </ProfileStack.Navigator>
  );
}
