export default {
  selectedPrestations: [],
  showFilterModal: [],
  minPrice: '',
  maxPrice: '',
  priceSelected: 0,
  filterPrice: false,
  scoreSelected: 0,
  filterScore: false,
  distanceLevel: 50,
  filterDistance: false,
  selectedCity: "",
  selectedTags: [],
  availabilitySelected: "NO_PREFERENCE",
  filterAvailability: false,
  latSelectedCity: 0,
  lngSelectedCity: 0,
  currentLoc: null,
  currentUID: null,
  currentUserData: null,
};
