import React from 'react';
import {SafeAreaView} from 'react-native-safe-area-context';
import {Fonts, gutters, Palette, Style} from '../../styles';
import {Button, Header, Spacer} from '../../components';
import {Routes} from '../../navigation';
import {responsiveHeight} from 'react-native-responsive-dimensions';
import Page from '../../components/Page';

export default ({navigation}) => {
  const onPressCreateAnAccount = () => {
    navigation?.navigate(Routes.Inscription);
  };
  const onPressLogin = () => {
    navigation?.navigate(Routes.Login);
  };
  // const onPressContinueWithoutRegistering = () => {
  //   firebase
  //     .auth()
  //     .signInAnonymously()
  //     .then(user => {
  //       console.log(user?.uid);
  //       navigation.navigate(Routes.BottomTab);
  //     });
  // };
  return (
    <Page>
      <SafeAreaView style={Style.con({flex: 1, bg: Palette.white})}>
        <Header navigation={navigation} />
        <Spacer />
        <Button
          title="Créer un compte"
          onPress={onPressCreateAnAccount}
          style={Style.con({mx: gutters, mb: responsiveHeight(1)})}
        />
        <Button
          title="Connexion"
          onPress={onPressLogin}
          primary={false}
          style={Style.con({mx: gutters})}
        />
        {/*<Text*/}
        {/*    onPress={onPressContinueWithoutRegistering}*/}
        {/*    style={Fonts.t(11, Palette.gray[1], {*/}
        {/*        self: 'center',*/}
        {/*        wei: '700',*/}
        {/*        y: responsiveHeight(2),*/}
        {/*    })}>*/}
        {/*    {"Continuer sans s'inscrire"}*/}
        {/*</Text>*/}
      </SafeAreaView>
    </Page>
  );
};
