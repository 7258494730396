import React, { useEffect, useMemo, useState } from "react";
import { View, Text, TouchableOpacity, FlatList } from "react-native";
import { Fonts, Palette, Style } from "../styles";
import moment from "moment";
import useDataFromRef from "../hooks/useDataFromRef";
import { institutesRef, meetingsRef } from "../config/firebase";
import { getUniqByID } from "../helpers";

const SLOT_DURATION = 15;

const HourPickerWidget = ({
  style,
  containerStyle = {},
  onChange = () => {},

  employeesList = [],
  prestations = [],

  isLoadingInstitute = true,

  hourSelected,
  setHourSelected,
  daySelected,

  theinstitute,
  withWhom,
  instituteId,
}) => {
  const [slots, setSlots] = useState([]);
  const [isCheckingSlots, setIsCheckingSlots] = useState(false);

  useEffect(() => {
    setHourSelected(null);
  }, [daySelected]);

  const onPressHour = (hour) => {
    setHourSelected(hour);
    onChange(hour);
  };

  const sharedParams = {
    listener: true,
    simpleRef: false,
    initialState: [],
    refreshArray: [daySelected],
  };

  const totalPrestationLength = useMemo(() => {
    return prestations.reduce(
      (acc, prestation) => acc + (prestation?.duration || 0),
      0
    );
  }, [prestations]);

  const { data: unavailabilitiesList, isLoading: isLoadingUnavailabilities } =
    useDataFromRef({
      ref: institutesRef
        .doc(instituteId)
        .collection("unavailabilities")
        .where(
          "startDay",
          ">=",
          moment(daySelected).subtract(30, "days").toDate()
        )
        .where("startDay", "<=", moment(daySelected).add(30, "days").toDate()),
      ...sharedParams,
      documentID: "unavailabilityID",
      refreshArray: [daySelected],
    });

  let baseMeetingRef = meetingsRef
    .where("instituteData.instituteId", "==", instituteId)
    .where("status", "in", ["PENDING", "PAID", "CONFIRMED"]);

  if (withWhom?.employeeId) {
    baseMeetingRef = baseMeetingRef.where(
      "employeesId",
      "==",
      withWhom?.employeeId
    );
  }

  const { data: dynamicMeetingList, isLoading: isLoadingDynamicMeetingList } =
    useDataFromRef({
      ref: baseMeetingRef
        .where("date", ">=", moment(daySelected).startOf("day").toDate())
        .where("date", "<=", moment(daySelected).endOf("day").toDate()),
      listener: true,
      simpleRef: false,
      initialState: [],
      documentID: "meetingID",
      refreshArray: [daySelected, withWhom?.employeeId],
    });

  const currentHour = new Date().getHours();
  const currentDay = new Date().getDate();

  const instituteTimeRanges =
    theinstitute?.openingHours?.[moment(daySelected).format("e")]?.timeRanges ||
    [];
  const instituteCloseHour =
    instituteTimeRanges?.[instituteTimeRanges?.length - 1]?.endTime || null;

  const checkIsBetween = (item, start, end) => {
    if (item >= start && item <= end) {
      return true;
    } else {
      return false;
    }
  };

  const slotToMoment = (slot) => {
    return moment(daySelected).set({
      hour: slot.split(":")[0],
      minute: slot.split(":")[1],
    });
  };

  const createSlots = () => {
    let slots = [];

    if (theinstitute?.openingHours?.length > 0 && instituteCloseHour) {
      const { timeRanges = [] } =
        theinstitute?.openingHours?.[moment(daySelected).format("e")] || {};

      timeRanges.map(({ startTime, endTime }) => {
        slots.push(...calculateSlots(startTime, endTime));
      });
    }

    console.log("slots", slots);

    return slots;
  };

  const calculateSlots = (startTime, endTime) => {
    const slots = [];

    const start = slotToMoment(startTime);
    const end = slotToMoment(endTime);
    const lastSlotBeforeClose = slotToMoment(instituteCloseHour).subtract(
      totalPrestationLength - 1,
      "minutes"
    );

    const dynamicEnd = end > lastSlotBeforeClose ? lastSlotBeforeClose : end;

    let current = moment(start);

    while (current.isBefore(dynamicEnd)) {
      slots.push(current.format("HH:mm"));
      current.add(SLOT_DURATION, "minutes");
    }

    console.log(`${slots?.length} slots created.`);

    return slots;
  };

  const filterSlots = (slots) => {
    let withoutUnavailabilitySlot = slots.filter((slot) => {
      let slotAvailable = true;

      unavailabilitiesList.map((unavailability) => {
        if (
          unavailability?.employeeId === withWhom?.employeeId &&
          (slotToMoment(slot).isBetween(
            moment(unavailability.startDay.toDate()),
            moment(unavailability.endDay.toDate()),
            "[]"
          ) ||
            slotToMoment(slot)
              .add(totalPrestationLength, "minutes")
              .isBetween(
                moment(unavailability.startDay.toDate()),
                moment(unavailability.endDay.toDate()),
                "[]"
              ))
        ) {
          slotAvailable = false;
        } else if (!withWhom) {
          const allUnavailabilitiesInsideSlot = unavailabilitiesList.filter(
            (unavailability) =>
              slotToMoment(slot).isBetween(
                moment(unavailability.startDay.toDate()),
                moment(unavailability.endDay.toDate()),
                "[]"
              ) ||
              slotToMoment(slot)
                .add(totalPrestationLength, "minutes")
                .isBetween(
                  moment(unavailability.startDay.toDate()),
                  moment(unavailability.endDay.toDate()),
                  "[]"
                )
          );

          const uniqEmployeeUnavailability = getUniqByID({
            array: allUnavailabilitiesInsideSlot,
            id: "employeeId",
          });

          if (uniqEmployeeUnavailability?.length === employeesList?.length) {
            slotAvailable = false;
            console.log("all employees are unavailable");
          }
        }
      });

      return slotAvailable;
    });

    let handledEmployeeSchedule = withoutUnavailabilitySlot;

    if (withWhom?.employeeId) {
      handledEmployeeSchedule = handledEmployeeSchedule.filter((slot) => {
        let slotAvailable = true;

        const { schedule = {} } = withWhom;

        if (Object.values(schedule)?.length > 0) {
          const { timeRanges = [] } =
            schedule?.[moment(daySelected).format("e")] || {};

          const workedSlots = timeRanges.filter(({ startTime, endTime }) => {
            if (
              slotToMoment(slot) >= slotToMoment(startTime) &&
              slotToMoment(slot) <= slotToMoment(endTime)
            ) {
              return true;
            } else {
              return false;
            }
          });

          if (!workedSlots.length) {
            slotAvailable = false;
          } else {
            slotAvailable = true;
          }
        } else {
          slotAvailable = false;
        }

        return slotAvailable;
      });
    }

    // On filtre les slots qui sont déjà pris
    return handledEmployeeSchedule.filter((slot) => {
      if (dynamicMeetingList.length === 0) {
        console.log("no meeting already booked");
        return true;
      } else {
        let slotAvailable = true;

        const meetingsFiltered = withWhom
          ? dynamicMeetingList.filter(
              (meeting) => meeting?.employeesId === withWhom?.employeeId
            )
          : dynamicMeetingList;

        const meetingsBetweenSlots = meetingsFiltered.filter((meeting) => {
          if (
            checkIsBetween(
              slotToMoment(slot),
              moment(meeting.date.toDate()),
              moment(meeting.date.toDate()).add(
                meeting.meetingDuration - 1,
                "minutes"
              )
            ) ||
            checkIsBetween(
              slotToMoment(slot).add(totalPrestationLength - 1, "minutes"),
              moment(meeting.date.toDate()),
              moment(meeting.date.toDate()).add(
                meeting.meetingDuration - 1,
                "minutes"
              )
            ) ||
            checkIsBetween(
              moment(meeting.date.toDate()),
              slotToMoment(slot),
              slotToMoment(slot).add(totalPrestationLength - 1, "minutes")
            )
          ) {
            return true;
          } else {
            return false;
          }
        });

        if (
          meetingsBetweenSlots.length >
          (withWhom?.employeeId ? 0 : employeesList.length - 1)
        ) {
          console.log("slot not available", slot);
          slotAvailable = false;
        }

        return slotAvailable;
      }
    });
  };

  const hasLoadedAllData =
    !isLoadingDynamicMeetingList &&
    !isLoadingUnavailabilities &&
    !isLoadingInstitute;

  const calculatedSlots = useMemo(() => {
    return filterSlots(createSlots());
  }, [
    employeesList?.length,
    theinstitute,
    daySelected,
    withWhom,
    unavailabilitiesList,
    dynamicMeetingList,
    hasLoadedAllData,
    totalPrestationLength,
    instituteCloseHour,
  ]);

  useEffect(() => {
    if (hasLoadedAllData) {
      setIsCheckingSlots(true);
      setSlots(calculatedSlots);
      setHourSelected(calculatedSlots[0]);
      setIsCheckingSlots(false);
    }
  }, [hasLoadedAllData, calculatedSlots]);

  if (!slots?.length || !hasLoadedAllData || isCheckingSlots) {
    return (
      <View
        style={{
          flex: 1,
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontWeight: "bold",
          }}
        >
          {hasLoadedAllData
            ? "Pas de disponibilités ce jour."
            : "Chargement..."}
        </Text>
      </View>
    );
  }

  return (
    <View style={[style]}>
      <FlatList
        data={slots}
        horizontal
        contentContainerStyle={[Style.con({ 18: 16, pb: 12 }), containerStyle]}
        renderItem={({ _, index }) => {
          const isTopSelect = hourSelected === slots[index * 3];
          const isMiddleSelect = hourSelected === slots[index * 3 + 1];
          const isBottomSelect = hourSelected === slots[index * 3 + 2];
          const isBeforeTodayTop =
            currentDay === daySelected.date() &&
            currentHour >= slots[index * 3]?.substring(0, 2);
          const isBeforeTodayMiddle =
            currentDay === daySelected.date() &&
            currentHour >= slots[index * 3 + 1]?.substring(0, 2);
          const isBeforeTodayBottom =
            currentDay === daySelected.date() &&
            currentHour >= slots[index * 3 + 2]?.substring(0, 2);

          return (
            <View
              style={[
                Style.con({ mr: 8 }),
                {
                  display:
                    isBeforeTodayTop ||
                    isBeforeTodayMiddle ||
                    isBeforeTodayBottom
                      ? "none"
                      : "flex",
                },
              ]}
            >
              <TouchableOpacity
                onPressIn={() => {
                  onPressHour(slots[index * 3]);
                }}
                disabled={isBeforeTodayTop}
                style={[
                  Style.con({
                    w: 110,
                    h: 44,
                    bor: 10,
                    bg: isBeforeTodayTop
                      ? null
                      : isTopSelect
                      ? Palette.primary
                      : Palette.gray[2],
                    cen: true,
                    mb: 8,
                  }),
                  {
                    display:
                      isBeforeTodayTop || slots[index * 3] === undefined
                        ? "none"
                        : "flex",
                  },
                ]}
              >
                <Text
                  style={Fonts.t(
                    15,
                    isBeforeTodayTop
                      ? Palette.gray[6]
                      : isTopSelect
                      ? Palette.white
                      : Palette.black,
                    { wei: "600" }
                  )}
                >
                  {slots[index * 3]}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPressIn={() => {
                  onPressHour(slots[index * 3 + 1]);
                }}
                disabled={isBeforeTodayMiddle}
                style={[
                  Style.con({
                    w: 110,
                    h: 44,
                    bor: 10,
                    bg: isBeforeTodayMiddle
                      ? null
                      : isMiddleSelect
                      ? Palette.primary
                      : Palette.gray[2],
                    cen: true,
                  }),
                  {
                    display:
                      isBeforeTodayMiddle || slots[index * 3 + 1] === undefined
                        ? "none"
                        : "flex",
                  },
                ]}
              >
                <Text
                  style={[
                    Fonts.t(
                      15,
                      isBeforeTodayMiddle
                        ? Palette.gray[6]
                        : isMiddleSelect
                        ? Palette.white
                        : Palette.black,
                      { wei: "600" }
                    ),
                  ]}
                >
                  {slots[index * 3 + 1]}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPressIn={() => {
                  onPressHour(slots[index * 3 + 2]);
                }}
                disabled={isBeforeTodayBottom}
                style={[
                  Style.con({
                    w: 110,
                    h: 44,
                    bor: 10,
                    bg: isBeforeTodayBottom
                      ? null
                      : isBottomSelect
                      ? Palette.primary
                      : Palette.gray[2],
                    cen: true,
                    mt: 8,
                  }),
                  {
                    display:
                      isBeforeTodayMiddle || slots[index * 3 + 2] === undefined
                        ? "none"
                        : "flex",
                  },
                ]}
              >
                <Text
                  style={[
                    Fonts.t(
                      15,
                      isBeforeTodayBottom
                        ? Palette.gray[6]
                        : isBottomSelect
                        ? Palette.white
                        : Palette.black,
                      { wei: "600" }
                    ),
                  ]}
                >
                  {slots[index * 3 + 2]}
                </Text>
              </TouchableOpacity>
            </View>
          );
        }}
      />
    </View>
  );
};

export default HourPickerWidget;
