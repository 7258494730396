import React from 'react';
import {Image, Text} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {
  responsiveHeight,
  responsiveWidth,
} from 'react-native-responsive-dimensions';
import {Fonts, gutters, Palette, Style} from '../../styles';
import {Button, Spacer} from '../../components';
import {Routes} from '../../navigation';
import Page from '../../components/Page';
import {useNavigation} from '@react-navigation/native';
import {images} from '../../assets';

export default () => {
  const navigation = useNavigation();
  const onPressCreateAnAccount = () => {
    navigation?.navigate(Routes.Inscription);
  };
  const onPressLogin = () => {
    navigation?.navigate(Routes.Login);
  };
  return (
    <Page>
      <SafeAreaView
        style={Style.con({
          flex: 1,
          bg: Palette.white,
          pb: responsiveHeight(2),
        })}>
        <Image
          resizeMode={'contain'}
          source={images.logo}
          style={{
            width: responsiveWidth(50),
            height: responsiveHeight(20),
            alignSelf: 'center',
          }}
        />
        <Text
          style={Fonts.t(22, Palette.black, {
            self: 'center',
            wei: '400',
            t: responsiveHeight(8),
            text: 'center',
          })}>
          {'Détendez-vous en un clic avec Wink'}
        </Text>
        <Spacer />
        <Button
          title="Créer un compte"
          onPress={onPressCreateAnAccount}
          style={Style.con({mx: gutters, mb: responsiveHeight(1)})}
        />
        <Button
          title="Connexion"
          onPress={onPressLogin}
          primary={false}
          style={Style.con({mx: gutters, mb: responsiveHeight(4)})}
        />
      </SafeAreaView>
    </Page>
  );
};
