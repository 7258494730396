import React from 'react';
import firebase from '../config/firebase';

export async function uploadPictureToFirebase({
  uri,
  path,
  width = 800,
  height = 800,
}) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(uri);
      const blob = await response.blob();

      const uploadTask = firebase.storage().ref(path).put(blob);
      // console.log("je passe ici 1")
      uploadTask.on(
        'state_changed',
        snapshot => {
          // console.log(snapshot);
        },
        error => {
          reject(error);
        },
        async () => {
          const url = await firebase.storage().ref(path).getDownloadURL();

          resolve({ resultURI: url });
        },
      );
    } catch (e) {
      reject(e);
    }
  });
}
