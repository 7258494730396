import React from 'react';
import {Platform, View, Text, FlatList, TouchableOpacity} from 'react-native';
import ModalBox from 'react-native-modalbox';
import {Fonts, Palette, resHeight, Style} from '../styles';
import {BOTTOM_BAR_HEIGHT, DIMENSION_WIDTH} from '../common';

export const FILTER_UI_MODE = {
  Filter: 'Filter',
  Price: 'Price',
  Score: 'Score',
  Distances: 'Distances',
  Availability: 'Availability',
};

const SettingsModal = ({
  style = {},
  isShow = false,
  onClosed = () => {},
  settingsList = [],
}) => {
  const onOpened = () => undefined;
  const _onClosed = () => {
    if (onClosed) {
      onClosed();
    }
  };
  return (
    <ModalBox
      style={[
        Style.con({
          bg: Palette.white,
          w: DIMENSION_WIDTH,
          bor: 20,
          pt: 18,
          pb: 60 + 16,
        }),
        {height: resHeight(527) + BOTTOM_BAR_HEIGHT},
        style,
      ]}
      useNativeDriver={true}
      backdropPressToClose={true}
      backdropOpacity={0.2}
      backdropColor={'black'}
      animationDuration={300}
      backdrop={true}
      backButtonClose={true}
      isOpen={isShow}
      swipeToClose={true}
      position={'bottom'}
      coverScreen={Platform.OS === 'android'}
      onOpened={onOpened}
      onClosed={_onClosed}>
      <View
        style={Style.con({
          w: 52,
          h: 5,
          bor: 5,
          bg: Palette.gray[4],
          self: 'center',
        })}
      />
      <Text
        style={Fonts.t(17, Palette.black, {wei: '700', self: 'center', t: 16})}>
        {'Paramètres'}
      </Text>
      <View style={Style.con({flex: 1})}>
        <FlatList
          style={Style.con({flex: 1})}
          data={settingsList}
          contentContainerStyle={Style.con({pt: 32, pb: 32})}
          renderItem={({item}) => {
            return (
              <TouchableOpacity
                key={item?.id}
                onPress={item?.onPress}
                style={Style.con({cen: true, py: 24})}>
                <Text style={Fonts.t(15, item?.color || Palette.black)}>
                  {item?.title}
                </Text>
              </TouchableOpacity>
            );
          }}
        />
      </View>
    </ModalBox>
  );
};

export default SettingsModal;
