import React from 'react';
import {
  Text,
  View,
  TouchableOpacity,
  Image,
  FlatList,
  ActivityIndicator,
} from 'react-native';
import {Style, Fonts, Palette, gutters} from '../../styles';
import {DIMENSION_WIDTH} from '../../common';
import {Routes} from '../../navigation';
import useDataFromRef from '../../hooks/useDataFromRef';
import {postsRef} from '../../config/firebase';
import {useNavigation, StackActions} from '@react-navigation/native';
import Page from '../../components/Page';
import {Header} from '../../components';
import {isDesktopWeb} from '../../config/layoutConstants';
import {thematicData} from '../../data';
import {responsiveHeight} from 'react-native-responsive-dimensions';

export default ({route}) => {
  const navigation = useNavigation();
  const {hashtag = null} = route?.params;
  const NUMBER_PER_BATCH = 10;

  const {
    data: postsList,
    loadMore,
    loading,
  } = useDataFromRef({
    ref: postsRef
      .where('hashtags', 'array-contains', hashtag)
      .where('type', '==', 'image'),
    simpleRef: false,
    condition: hashtag,
    documentID: 'postId',
    initialState: [],
    refreshArray: [hashtag],
    usePagination: true,
    listener: true,
    batchSize: NUMBER_PER_BATCH,
  });

  const loadingView = () => {
    if (loading) {
      return (
        <View style={Style.con({flex: 1, justify: 'center'})}>
          <ActivityIndicator size="large" />
        </View>
      );
    } else {
      return null;
    }
  };

  return (
    <Page>
      <Header
        back
        title={thematicData[hashtag.thematicId].name}
        navigation={navigation}
        titleStyle={Style.con({bg: Palette.gray[2], bor: 5, p: 10})}
        style={Style.con({py: responsiveHeight(2)})}
      />
      <View style={Style.con({flex: 1, direc: 'row'})}>
        <FlatList
          data={postsList}
          numColumns={3}
          showsVerticalScrollIndicator={false}
          onEndReached={loadMore}
          initialNumToRender={NUMBER_PER_BATCH}
          onEndReachedThreshold={0.5}
          ListFooterComponent={loadingView}
          ItemSeparatorComponent={() => {
            return <View style={Style.con({h: 2, bg: Palette.white})} />;
          }}
          renderItem={({item: post, index}) => {
            const itemSize = (DIMENSION_WIDTH - 3) / 3;
            const itemStyle = index % 3 === 1 ? Style.con({mx: 2}) : {};
            const postId = post?.postId || '';
            const pushAction = StackActions.push(Routes.PostDetails, {
              postId,
            });
            const onPressPost = () => {
              navigation?.dispatch(pushAction);
            };
            return (
              <TouchableOpacity
                key={postId}
                style={isDesktopWeb ? {width: '33%', height: 200} : itemStyle}
                onPress={onPressPost}>
                <Image
                  style={
                    isDesktopWeb
                      ? {
                          width: '100%',
                          height: '100%',
                        }
                      : Style.con({size: itemSize})
                  }
                  source={{uri: post?.mediaURL}}
                />
              </TouchableOpacity>
            );
          }}
        />
      </View>
    </Page>
  );
};
