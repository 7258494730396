import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import {Routes} from './Routes';
import {Palette} from '../styles';
import Search from '../screens/tabs/Search';
import InstituteProfile from '../screens/InstituteProfile';
import PostDetails from '../screens/posts/PostDetails';
import PostByHashtag from '../screens/posts/PostByHashtag';
import PostsRelatedToService from '../screens/posts/PostsRelatedToService';
import Inscription from '../screens/auth/Inscription';
import Login from '../screens/auth/Login';
import ForgotPassword from '../screens/auth/ForgotPassword';
import MakeAppointment from '../screens/booking/MakeAppointment';
import ChooseTimeDate from '../screens/ChooseTimeDate';
import Recap from '../screens/Recap';

const screenOptions = {
  headerShown: false,
  cardOverlayEnabled: true,
  headerTitleAlign: 'center',
  headerBackTitleVisible: false,
};

const SearchStack = createStackNavigator();

export default function SearchStackScreen() {
  return (
    <SearchStack.Navigator
      initialRouteName={Routes.Search}
      screenOptions={[
        {
          contentStyle: {
            backgroundColor: Palette.white,
          },
        },
        screenOptions,
      ]}>
      <SearchStack.Screen
        name={Routes.Search}
        component={Search}
        options={{headerShown: false}}
      />
      <SearchStack.Screen
        name={Routes.InstituteProfile}
        component={InstituteProfile}
        options={{headerShown: false}}
      />
      <SearchStack.Screen
        name={Routes.PostDetails}
        component={PostDetails}
        options={{headerShown: false}}
      />
      <SearchStack.Screen
        name={Routes.PostByHashtag}
        component={PostByHashtag}
        options={{headerShown: false}}
      />
      <SearchStack.Screen
        name={Routes.PostsRelatedToService}
        component={PostsRelatedToService}
        options={{headerShown: false}}
      />
      <SearchStack.Screen
        name={Routes.Inscription}
        component={Inscription}
        options={{headerShown: false}}
      />
      <SearchStack.Screen
        name={Routes.Login}
        component={Login}
        options={{headerShown: false}}
      />
      <SearchStack.Screen
        name={Routes.ForgotPassword}
        component={ForgotPassword}
        options={{headerShown: false}}
      />
      <SearchStack.Screen
        name={Routes.MakeAppointment}
        component={MakeAppointment}
        options={{headerShown: false}}
      />
      <SearchStack.Screen
        name={Routes.ChooseTimeDate}
        component={ChooseTimeDate}
        options={{headerShown: false}}
      />
      <SearchStack.Screen
        name={Routes.Recap}
        component={Recap}
        options={{headerShown: false}}
      />
    </SearchStack.Navigator>
  );
}
