import React from 'react';
import {Platform, View, Text, Image, ScrollView} from 'react-native';
import ModalBox from 'react-native-modalbox';

import {Fonts, gutters, Palette, Style} from '../styles';
import Button from './Button';
import {YourMeetingRow} from '../components';
import {BOTTOM_BAR_HEIGHT, DIMENSION_WIDTH} from '../common';
import moment from 'moment';
import {isDesktopWeb} from '../config/layoutConstants';
import {responsiveWidth} from 'react-native-responsive-dimensions';

export const AppointmentModalType = {
  Upcoming: 'Upcoming appointments',
  Past: 'Past appointments',
};

const AppointmentModal = ({
  style = {},
  isShow = false,
  onClosed = () => {},
  onReschedule = () => {},
  onMove = () => {},
  onCancel = () => {},
  onLeaveAReview = () => {},
  appointment = {},
  meetingRef,
}) => {
  const onOpened = () => undefined;
  const _onClosed = () => {
    if (onClosed) {
      onClosed();
    }
  };

  const isCancel = meetingRef?.status === 'CANCELLED';

  return (
    <ModalBox
      style={[
        Style.con({
          bg: Palette.white,
          w: responsiveWidth(100),
          h: 675 + BOTTOM_BAR_HEIGHT,
          bor: 20,
          over: 'hidden',
        }),
        style,
      ]}
      useNativeDriver={true}
      backdropPressToClose={true}
      backdropOpacity={0.2}
      backdropColor={'black'}
      animationDuration={300}
      backdrop={true}
      backButtonClose={true}
      isOpen={isShow}
      swipeToClose={true}
      position={'bottom'}
      coverScreen={Platform.OS === 'android'}
      onOpened={onOpened}
      onClosed={_onClosed}>
      <Image
        style={Style.con({
          h: 110,
          w: isDesktopWeb ? responsiveWidth(50) : DIMENSION_WIDTH,
        })}
        source={{uri: meetingRef?.instituteCoverPicture}}
      />
      <View style={Style.con({flex: 1, px: gutters})}>
        <ScrollView style={Style.con({flex: 1})}>
          <Text
            style={Fonts.t(27, Palette.black, {
              wei: '700',
              self: 'center',
              t: 20,
            })}>
            {meetingRef?.instituteData?.instituteName}
          </Text>
          <Text
            style={Fonts.t(16, Palette.black, {
              wei: '400',
              self: 'center',
              t: 8,
            })}>
            {`Rendez-vous prévu le ${moment(meetingRef?.date).format(
              'DD MMMM YYYY [à] HH[h]mm',
            )}`}
          </Text>
          <Text
            style={Fonts.t(16, Palette.black, {
              wei: '400',
              self: 'center',
              t: 8,
            })}>
            {`📍 ${meetingRef?.instituteData?.instituteLocation?.addressIns} ${meetingRef?.instituteData?.instituteLocation?.postCode} ${meetingRef?.instituteData?.instituteLocation?.city}`}
          </Text>
          <Text
            style={Fonts.t(18, Palette.black, {
              wei: '700',
              self: 'center',
              t: 30,
            })}>
            {'Votre rendez-vous'}
          </Text>
          <View style={Style.con({py: 8})}>
            {meetingRef?.prestationData?.map(meeting => {
              return (
                <YourMeetingRow
                  key={meeting.prestationId}
                  style={Style.con({my: 15})}
                  title={meeting.prestationName || meeting.name}
                  amount={meetingRef.prestationData.indexOf(meeting) + 1}
                />
              );
            })}
          </View>
        </ScrollView>
        {isCancel ? (
          <View style={Style.con({flex: 1})}>
            <Text style={Fonts.t(15, 'red', {wei: 700, text: 'center'})}>
              {'Rendez-vous annulé par le salon'}
            </Text>
          </View>
        ) : (
          <>
            <Button
              onPress={
                appointment === AppointmentModalType.Past
                  ? onReschedule
                  : onMove
              }
              title={
                appointment === AppointmentModalType.Past
                  ? 'Replanifier'
                  : 'Déplacer'
              }
              style={Style.con({mb: 8})}
            />
            <Button
              onPress={
                appointment === AppointmentModalType.Past
                  ? onLeaveAReview
                  : onCancel
              }
              primary={false}
              title={
                appointment === AppointmentModalType.Past
                  ? 'Laisser un avis'
                  : 'Annuler le rendez-vous'
              }
              style={Style.con({mb: 16 + BOTTOM_BAR_HEIGHT})}
            />
          </>
        )}
      </View>
    </ModalBox>
  );
};

export default AppointmentModal;
