import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import {Palette} from '../styles';
import {Routes} from './Routes';
import Map from '../screens/tabs/Map';
import InstituteProfile from '../screens/InstituteProfile';

const screenOptions = {
  headerShown: false,
  cardOverlayEnabled: true,
  headerStyle: {
    backgroundColor: Palette.background,
  },
  headerTitleAlign: 'center',
  headerBackTitleVisible: false,
};

const MapStack = createStackNavigator();

export default function MapStackScreen() {
  return (
    <MapStack.Navigator
      initialRouteName={Routes.Map}
      screenOptions={[
        {
          contentStyle: {
            backgroundColor: Palette.white,
          },
        },
        screenOptions,
      ]}>
      <MapStack.Screen
        name={Routes.Map}
        component={Map}
        options={{headerShown: false}}
      />
      <MapStack.Screen
        name={Routes.InstituteProfile}
        component={InstituteProfile}
        options={{headerShown: false}}
      />
    </MapStack.Navigator>
  );
}
