import { Platform } from "react-native";
import { responsiveWidth } from "react-native-responsive-dimensions";

export const webSideBarWidth = 200;
export const webMaxContentWidth = 800;

export const isWeb = Platform.OS === "web";

export const mobileBreakpoint = 800;
export const isMobile = responsiveWidth(100) <= mobileBreakpoint;

export const isDesktopWeb = isWeb && !isMobile;
export const isMobileWeb = isWeb && isMobile;

export const isMobileNative = !isWeb && isMobile;

export const webBorderRadius = isDesktopWeb ? 12 : 0;
