import React from "react";
import { View, Text, TouchableOpacity, Image } from "react-native";
import { Fonts, gutters, Palette, Style } from "../styles";
import { icons } from "../assets";
import Logo from "./RenderLogo";
import { isDesktopWeb, isMobileWeb } from "../config/layoutConstants";
import { Routes } from "../navigation";
import { resetToPage } from "../navigation/NavigationService";

const Header = ({
  style = {},
  title = "",
  titleStyle = {},
  subTitle = null,
  back = false,
  backBlack = false,
  iconRight = null,
  iconTintColor = Palette.black,
  onRight = () => undefined,
  navigation,
  withLogo = false,
  backToProfile = false,
}) => {
  const onPressBack = () => {
    navigation?.goBack();
  };

  const onPressBackToProfile = () => {
    resetToPage(navigation, Routes.Profile);
  }

  if (!title && !back && !withLogo && !iconRight) {
    return null;
  }

  return (
    <>
      <View
        style={[
          Style.con({
            w: "auto",
            px: isDesktopWeb ? 0 : gutters / 2,
            py: isDesktopWeb ? 0 : gutters / 2,
          }),
          Style.border({ bbw: 1, color: Palette.gray[5] }),
          style,
        ]}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <View style={Style.con({ w: 80 })}>
            {(back || backBlack) && (
              <TouchableOpacity
                onPress={backToProfile ? onPressBackToProfile : onPressBack}
                style={Style.con({
                  size: 40,
                  cen: true,
                })}
              >
                <Image
                  style={Style.con({
                    size: 24,
                    tin: backBlack ? Palette.white : Palette.black,
                  })}
                  source={icons.iconArrowLeft}
                />
              </TouchableOpacity>
            )}
          </View>

          <View style={[Style.con({ flex: 1, cen: true })]}>
            {withLogo && <Logo />}
            {subTitle !== null && 
            <Text style={[Fonts.t(12, Palette.text[1], { wei: "700" }), titleStyle]}>
              {subTitle}
            </Text>}
            <Text style={[Fonts.t(15, Palette.text[1], { wei: "700" }), titleStyle]}>
              {title}
            </Text>
          </View>

          <View style={Style.con({ w: 80, items: "flex-end" })}>
            {iconRight && (
              <TouchableOpacity
                onPress={onRight}
                style={Style.con({
                  size: 40,
                  cen: true,
                })}
              >
                <Image
                  style={[
                    Style.con({
                      size: 24,
                      tin: iconTintColor,
                    }),
                  ]}
                  source={iconRight}
                  resizeMode="contain"
                />
              </TouchableOpacity>
            )}
          </View>
        </View>
      </View>
    </>
  );
};

export default Header;
