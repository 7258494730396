import React, {useRef} from 'react';
import {TouchableOpacity} from 'react-native';

export default function ImagePickers({onComplete, children, style = {}}) {
  const hiddenFileInput = useRef(null);

  const onFilesAdded = evt => {
    const files = evt.target.files;
    if (onComplete) {
      onComplete({uri: URL.createObjectURL(files[0])});
    }
  };

  return (
    <>
      <input
        ref={hiddenFileInput}
        type="file"
        accept="image/*"
        style={{display: 'none'}}
        onChange={onFilesAdded}
      />
      <TouchableOpacity
        onPress={() => hiddenFileInput.current.click()}
        style={style}>
        {children}
      </TouchableOpacity>
    </>
  );
}
