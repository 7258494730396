import {useGlobal, setGlobal} from 'reactn';
import React, {useEffect} from 'react';
import useDataFromRef from '../hooks/useDataFromRef';
import initialGlobalState from '../config/initialGlobalState';

import firebase, {usersRef} from '../config/firebase';

export default ({children}) => {
  const [currentUID, setCurrentUID] = useGlobal('currentUID');
  const [currentUserData, setCurrentUserData] = useGlobal('currentUserData');

  useDataFromRef({
    ref: currentUID ? usersRef.doc(currentUID) : null,
    simpleRef: true,
    listener: true,
    condition: currentUID,
    refreshArray: [currentUID],
    onUpdate: data => {
      if (data) {
        setCurrentUserData(data);
      } else {
        setCurrentUserData(null);
      }
    },
  });

  useEffect(() => {
    return firebase.auth().onAuthStateChanged(onAuthStateChanged);
  }, []);

  const onAuthStateChanged = user => {
    if (user?.uid) {
      setCurrentUID(user.uid);
    } else {
      setCurrentUID(null);
      setGlobal(initialGlobalState);
    }
  };

  return children;
};
