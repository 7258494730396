import React from 'react';
import {Text, View} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {responsiveHeight} from 'react-native-responsive-dimensions';
import firebase from '../../config/firebase';
import {useGlobal} from 'reactn';
import {Style, Fonts, Palette, gutters} from '../../styles';
import {Button, Header} from '../../components';
import {Routes} from '../../navigation';
import Page from '../../components/Page';

export default ({navigation, route}) => {
  const {user, firstName, lastName, mail, phone} = route?.params;

  const db = firebase.firestore();
  const [, setIsGlobalLoading] = useGlobal('_isLoading');

  const onPressConfirm = async () => {
    try {
      await setIsGlobalLoading(true);
      await db
        .collection('users')
        .doc(user.user.uid)
        .set({
          contactDetails: {
            firstName: firstName,
            lastName: lastName,
            email: mail,
            phoneNumber: phone,
          },
          profilPicture: '',
          reviewsId: [],
          type: 'client',
          likedPostsId: [],
          likedInstitutesId: [],
          allowedNotificationTypes: [
            'PUSH_MEETING_REMINDER',
            'PUSH_NEW_POST_INSTITUTES',
            'PUSH_NEW_PRESTA_INSTITUTES',
            'PUSH_MEETING_CANCELLED',
            'SMS_MEETING_REMINDER',
            'SMS_NEW_POST_INSTITUTES',
            'SMS_NEW_PRESTA_INSTITUTES',
            'SMS_MEETING_CANCELLED',
          ],
        });
      navigation?.navigate(Routes.BottomTab);
    } catch (error) {
      console.log(error);
    } finally {
      await setIsGlobalLoading(false);
    }
  };
  return (
    <Page>
      <SafeAreaView style={Style.con({flex: 1, bg: Palette.white})}>
        <Header navigation={navigation} />
        <Text
          style={Fonts.t(28, Palette.black, {
            self: 'center',
            wei: '700',
            y: responsiveHeight(4),
            text: 'center',
          })}>
          {'Compte créé'}
        </Text>
        <View style={Style.con({flex: 1, px: gutters})}>
          <Text
            style={Fonts.t(12, Palette.gray[1], {
              wei: '700',
              self: 'center',
              text: 'center',
              b: responsiveHeight(2.5),
              t: responsiveHeight(15),
            })}>
            {
              'Votre compte a bien été créé. Pour valider votre identité, veuillez cliquer sur le lien reçu par mail.'
            }
          </Text>
          <Button title="Confirmer" onPress={onPressConfirm} />
        </View>
      </SafeAreaView>
    </Page>
  );
};
