import React from "react";
import { useGlobal, useState } from "reactn";
import { Text, View, Image, TouchableOpacity, Linking } from "react-native";
import firebase, {
  arrayRemove,
  arrayUnion,
  postsRef,
  usersRef,
} from "../config/firebase";
import { useNavigation } from "@react-navigation/native";
import { reservationRouting } from "../helpers/reservation";

import { Style, Fonts, Palette, gutters, resWidth } from "../styles";
import { icons } from "../assets";
import { Routes } from "../navigation";
import { DIMENSION_WIDTH } from "../common";
import { LinearGradient } from "expo-linear-gradient";
import { isDesktopWeb, webBorderRadius } from "../config/layoutConstants";
import { responsiveHeight, responsiveWidth } from "react-native-responsive-dimensions";
import { thematicData } from "../data";

const PostDetailsView = ({
  onLayout,
  instituteData,
  instituteName = instituteData?.name || "",
  addressIns = instituteData?.location.addressIns || "",
  postCode = instituteData?.location.postCode || "",
  city = instituteData?.location.city || "",
  instituteProfilPicture = instituteData?.profilPicture || "",
  categories = instituteData?.categories || [],
  instituteId,
  postId,
  datePost,
  mediaURL,
  description,
  prestationData,
  price = prestationData?.price || null,
  name = prestationData?.name || "",
  duration = prestationData?.duration || "",
  allSkinTypes = prestationData?.allSkinTypes || false,
  prestationId,
  hashtags = [],
}) => {
  const navigation = useNavigation();
  const [, setIsGlobalLoading] = useGlobal("_isLoading");
  const [, setTooltip] = useGlobal("_tooltip");
  const [currentUserData] = useGlobal("currentUserData");
  const [currentUID] = useGlobal("currentUID");
  const [selectedPrestations, setSelectedPrestations] = useGlobal(
    "selectedPrestations"
  );

  const [openString, setOpenString] = useState(false);
  const descriptionString = description;

  const postsRefDoc = postsRef.doc(postId);
  const usersRefDoc = usersRef.doc(currentUserData?.id);

  const onPressHashTag = (hashtag) => {
    navigation?.navigate(Routes.PostByHashtag, { hashtag });
  };
  const instituteLocation = { addressIns, postCode, city };
  const onPressReservation = (prestation) => {
    prestation = {
      duration,
      price,
      name,
      allSkinTypes,
      prestationId,
    };
    try {
      let newselectedPrestations = { ...selectedPrestations };

      if (
        !(newselectedPrestations?.[instituteId] || [])
          .map(({ prestationId }) => prestationId)
          ?.includes(prestation.prestationId)
      ) {
        newselectedPrestations[instituteId] = [
          ...(newselectedPrestations?.[instituteId] || []),
          prestation,
        ];
        setSelectedPrestations(newselectedPrestations);
      }
      reservationRouting({
        navigation,
        institute: {
          instituteName,
          instituteProfilPicture,
          instituteId,
          instituteLocation,
          categories,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const isLikedPost = currentUserData?.likedPostsId?.includes(postId);

  const likedPost = async () => {
    try {
      if (isLikedPost === true) {
        setIsGlobalLoading(true);
        await postsRefDoc.update({
          favBy: arrayRemove(currentUID),
        });
        await usersRefDoc.update({
          likedPostsId: arrayRemove(postId),
        });

        setTooltip({ text: "Post retiré des favoris" });
      } else {
        setIsGlobalLoading(true);
        await postsRefDoc.update({
          favBy: arrayUnion(currentUID),
        });
        await usersRefDoc.update({
          likedPostsId: arrayUnion(postId),
        });
        setTooltip({ text: "Post ajouté aux favoris" });
      }
    } catch (error) {
      console.log({ text: error.message });
    } finally {
      setIsGlobalLoading(false);
    }
  };

  const onPressInstitute = () => {
    navigation?.navigate(Routes.InstituteProfile, {instituteId,});
  };


  return (
    <View onLayout={onLayout} style={Style.con({ my: 8 })}>
      <TouchableOpacity onPress={onPressInstitute}>
        <View
          style={Style.con({
            direc: "row",
            items: "center",
            px: isDesktopWeb ? 0 : gutters / 2,
            mb: 16,
            // w: resWidth(350),
          })}
        >
          <Image
            style={[
              Style.con({ size: 32 }),
              Style.border({ bor: 32, width: 1, color: Palette.gray[4] }),
            ]}
            source={{ uri: instituteProfilPicture }}
          />
          <View style={Style.con({ ml: 10 })}>
            <Text
              style={Fonts.t(14, Palette.text[1], { wei: "600" })}
              numberOfLines={2}
            >
              {`${instituteName} • `}
              <Text style={Fonts.t(14, Palette.primary, { wei: "600" })}>
                {`${name} - ${price} €`}
              </Text>
            </Text>
            <Text style={Fonts.t(12, Palette.black, { t: 3 })}>
              {`${addressIns} - ${postCode} ${city}`}
            </Text>
          </View>
        </View>
      </TouchableOpacity>

      <View
        style={isDesktopWeb}
      >
        <Image
          style={
            isDesktopWeb
              ? {
                  width: 'auto',
                  height: responsiveHeight(30),
                  overflow: "hidden",
                  position: 'relative'
                }
              : Style.con({ size: DIMENSION_WIDTH, pos: 'relative' })
          }
          source={{ uri: mediaURL }}
          resizeMode={"cover"}
        />
        {currentUID ? (
          <TouchableOpacity
            onPress={likedPost}
            style={Style.con({
              pos: "absolute",
              r: 20,
              b: 20,
            })}
          >
            <Image
              style={Style.con({ size: 32 })}
              resizeMode="contain"
              source={
                isLikedPost
                  ? icons.iconHeartProfile
                  : icons.iconHeartStrokeProfile
              }
            />
          </TouchableOpacity>
        ) : null}
      </View>
      <TouchableOpacity onPress={onPressReservation}>
        <View
          style={Style.con({
            direc: "row",
            items: "center",
            justify: "space-between",
            h: 48,
            px: gutters,
            bg: Palette.primary,
          })}
        >
          <Text style={[Fonts.t(15, Palette.white), { fontWeight: "700" }]}>
            {"Réserver"}
          </Text>
          <Image
            style={Style.con({ size: 24 })}
            source={icons.iconTriangleRight}
          />
        </View>
      </TouchableOpacity>

      <View style={Style.con({ px: isDesktopWeb ? 0 : gutters, py: 18 })}>
        <View style={Style.con({ direc: "row", wrap: "wrap" })}>
          {hashtags.map((hashtag, index) => {
            return (
              <TouchableOpacity
                key={index}
                onPress={() => onPressHashTag(hashtag)}
                style={Style.con({
                  px: gutters / 2,
                  py: 6,
                  bg: Palette.gray[4],
                  bor: 5,
                  mr: 6,
                })}
              >
                <Text style={Fonts.t(12, Palette.black, { wei: "600" })}>
                  {thematicData[hashtag.thematicId].name}
                </Text>
              </TouchableOpacity>
            );
          })}
        </View>
        {openString ? (
          <>
            <Text style={Fonts.t(13, Palette.black, { wei: "400", t: 14 })}>
              {descriptionString}
            </Text>
            <TouchableOpacity onPress={() => setOpenString(false)}>
              <Text style={Fonts.t(13, Palette.gray[6])}>{"moins"}</Text>
            </TouchableOpacity>
          </>
        ) : (
          <>
            <Text style={Fonts.t(13, Palette.black, { wei: "400", t: 14 })}>
              {`${descriptionString?.substring(0, 50) || ""}...`}
            </Text>
            <TouchableOpacity onPress={() => setOpenString(true)}>
              <Text style={Fonts.t(13, Palette.gray[6])}>{"plus"}</Text>
            </TouchableOpacity>
          </>
        )}

        <Text style={Fonts.t(10, Palette.gray[7], { t: 3 })}>{datePost}</Text>
      </View>
    </View>
  );
};

export default PostDetailsView;
