import React from 'react';
import {Platform, View, Text} from 'react-native';
import ModalBox from 'react-native-modalbox';
import {Fonts, gutters, Palette, Style} from '../styles';
import {Spacer} from '../components';
import Button from './Button';

export const FILTER_UI_MODE = {
  Filter: 'Filter',
  Price: 'Price',
  Score: 'Score',
  Distances: 'Distances',
  Availability: 'Availability',
};

const LogoutModal = ({
  style = {},
  isShow = false,
  onClosed = () => {},
  onConfirm = () => {},
}) => {
  const onOpened = () => undefined;
  const _onClosed = () => {
    if (onClosed) {
      onClosed();
    }
  };
  const _onPressCancel = () => {
    if (onClosed) {
      onClosed();
    }
  };
  return (
    <ModalBox
      style={[
        Style.con({
          bg: Palette.white,
          w: 350,
          h: 172,
          bor: 20,
          px: gutters,
          pb: 10,
        }),
        style,
      ]}
      useNativeDriver={true}
      backdropPressToClose={true}
      backdropOpacity={0.2}
      backdropColor={'black'}
      animationDuration={300}
      backdrop={true}
      backButtonClose={true}
      isOpen={isShow}
      swipeToClose={true}
      position={'center'}
      coverScreen={Platform.OS === 'android'}
      onOpened={onOpened}
      onClosed={_onClosed}>
      <Text
        style={Fonts.t(17, Palette.black, {wei: '700', self: 'center', t: 20})}>
        {'Vous êtes sur le point \nde vous déconnecter'}
      </Text>
      <Spacer />
      <View style={Style.con({direc: 'row'})}>
        <Button
          title="Annuler"
          onPress={_onPressCancel}
          primary={false}
          style={Style.con({flex: 1})}
        />
        <Button
          title="Confirmer"
          onPress={onConfirm}
          style={Style.con({flex: 1, ml: 8})}
        />
      </View>
    </ModalBox>
  );
};

export default LogoutModal;
