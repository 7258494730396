import React from 'react';
import {Text, View, TouchableOpacity, Image} from 'react-native';
import {Style, Fonts, Palette, gutters} from '../styles';
import moment from 'moment';
import {capitalizeFirstLetter} from 'react-native-minuit/lib/commonjs/actions/textActions';

export const AppointmentCard = ({item, onPress = () => {}, style}) => {
  const {status = 'PENDING'} = item || {};

  const isCancelled = status === 'CANCELLED';

  const textPrestation = meeting => {
    const {instituteData, prestationData} = meeting;
    const prestationSyntax =
      meeting.prestationData.length > 1 ? 'prestations' : 'prestation';
    return `${instituteData.instituteName} • ${prestationData.length} ${prestationSyntax}`;
  };

  return (
    <TouchableOpacity
      onPress={() => onPress(item)}
      style={Style.con({
        h: 128,
        bg: Palette.gray[2],
        mb: 20,
        bor: 17,
        px: gutters,
        direc: 'row',
        items: 'center',
      })}>
      <Image
        style={Style.con({size: 78, bor: 5})}
        source={{uri: item?.instituteData?.instituteProfilPicture || ''}}
      />
      <View style={Style.con({ml: 15})}>
        <Text
          style={{
            ...Fonts.t(15, Palette.black, {
              wei: '700',
            }),
            maxWidth: '100%',
          }}>
          {`${capitalizeFirstLetter(
            moment(item?.date).format('dddd D '),
          )}${capitalizeFirstLetter(
            moment(item?.date).format('MMMM [•] H [h] mm'),
          )}`}
        </Text>
        <Text
          style={[
            Fonts.t(13, Palette.black, {wei: '400', t: 6}),
            isCancelled ? {textDecorationLine: 'line-through'} : null,
          ]}>
          {textPrestation(item)}
        </Text>
        {isCancelled && (
          <Text style={Fonts.t(12, Palette.primary, {t: 6})}>Annulé</Text>
        )}
      </View>
    </TouchableOpacity>
  );
};

export default AppointmentCard;
