import React from 'react';
import {Text, View} from 'react-native';

import {Style, Fonts, Palette} from '../styles';

export const PROFILE_TABS = {
  Appointment: 'Appointment',
  Favoris: 'Favoris',
};

export const YourMeetingRow = ({amount = null, title = '', style = {}}) => {
  return (
    <View
      style={[
        Style.con({
          direc: 'row',
          items: 'center',
        }),
        style,
      ]}>
      <View style={Style.con({size: 30, bg: Palette.gray[16], cen: true})}>
        <Text
          style={Fonts.t(15, Palette.black, {
            wei: '700',
          })}>
          {amount}
        </Text>
      </View>
      <Text
        style={Fonts.t(15, Palette.black, {
          wei: '700',
          l: 20,
        })}>
        {title}
      </Text>
    </View>
  );
};

export default YourMeetingRow;
