import {StyleSheet} from 'react-native';
import {responsiveWidth} from 'react-native-responsive-dimensions';

export const gutters = responsiveWidth(5.33);
export const mainBorderRadius = 10;
export const resHeight = height => {
  return height;
  // responsiveHeight(height);
  // if (typeof height === 'number') {
  //   if (Platform.isPad) {
  //     return height;
  //   }
  //   return responsiveHeight(height / 8.96); // 896px height follow by IPhone 11 Pro Max
  // }
  // return height;
};
export const resWidth = width => {
  return width;
  // responsiveWidth(width);
  // if (typeof width === 'number') {
  //   if (Platform.isPad) {
  //     return width;
  //   }
  //   return responsiveWidth(width / 4.14); // 414px width follow by IPhone 11 Pro Max
  // }
  // return width;
};

const border = ({
  bor,
  color,
  width,
  btw,
  blw,
  brw,
  bbw,
  das,
  bblr,
  bbrr,
  btlr,
  btrr,
}) => ({
  ...(typeof bor === 'number' ? {borderRadius: resWidth(bor)} : {}),
  ...(typeof color === 'string' ? {borderColor: color} : {}),
  ...(typeof width === 'number' ? {borderWidth: resWidth(width)} : {}),
  ...(typeof btw === 'number' ? {borderTopWidth: resHeight(btw)} : {}),
  ...(typeof blw === 'number' ? {borderLeftWidth: resWidth(blw)} : {}),
  ...(typeof brw === 'number' ? {borderRightWidth: resWidth(brw)} : {}),
  ...(typeof bbw === 'number' ? {borderBottomWidth: resHeight(bbw)} : {}),
  ...(typeof bblr === 'number'
    ? {borderBottomLeftRadius: resHeight(bblr)}
    : {}),
  ...(typeof bbrr === 'number'
    ? {borderBottomRightRadius: resHeight(bbrr)}
    : {}),
  ...(typeof btlr === 'number' ? {borderTopLeftRadius: resHeight(btlr)} : {}),
  ...(typeof btrr === 'number' ? {borderTopRightRadius: resHeight(btrr)} : {}),
  ...(das !== undefined ? {borderStyle: 'dashed'} : {}),
});

const con = ({
  flex,
  direc,
  bg,
  w,
  h,
  bor,
  borColor,
  m,
  mt,
  ml,
  mr,
  mb,
  mx,
  my,
  p,
  pt,
  pl,
  pr,
  pb,
  px,
  py,
  cen = false,
  items,
  justify,
  self,
  pos,
  l,
  t,
  r,
  b,
  size,
  tin,
  z,
  wrap,
  mw,
  over,
  op,
  rotate,
}) => ({
  ...(typeof flex === 'number' ? {flex: flex} : {}),
  ...(typeof direc === 'string' ? {flexDirection: direc} : {}),
  ...(typeof bg === 'string' ? {backgroundColor: bg} : {}),
  ...(w !== undefined ? {width: resWidth(w)} : {}),
  ...(h !== undefined ? {height: resHeight(h)} : {}),
  ...(bor !== undefined ? {borderRadius: resWidth(bor)} : {}),
  ...(m !== undefined ? {margin: resHeight(m)} : {}),
  ...(mx !== undefined ? {marginHorizontal: resWidth(mx)} : {}),
  ...(my !== undefined ? {marginVertical: resHeight(my)} : {}),
  ...(mt !== undefined ? {marginTop: resHeight(mt)} : {}),
  ...(ml !== undefined ? {marginLeft: resWidth(ml)} : {}),
  ...(mr !== undefined ? {marginRight: resWidth(mr)} : {}),
  ...(mb !== undefined ? {marginBottom: resHeight(mb)} : {}),
  ...(px !== undefined ? {paddingHorizontal: resWidth(px)} : {}),
  ...(py !== undefined ? {paddingVertical: resHeight(py)} : {}),
  ...(p !== undefined ? {padding: resHeight(p)} : {}),
  ...(pt !== undefined ? {paddingTop: resHeight(pt)} : {}),
  ...(pl !== undefined ? {paddingLeft: resWidth(pl)} : {}),
  ...(pr !== undefined ? {paddingRight: resWidth(pr)} : {}),
  ...(pb !== undefined ? {paddingBottom: resHeight(pb)} : {}),
  ...(typeof justify === 'string' ? {justifyContent: justify} : {}),
  ...(typeof items === 'string' ? {alignItems: items} : {}),
  ...(typeof self === 'string' ? {alignSelf: self} : {}),
  ...(typeof pos === 'string' ? {position: pos} : {}),
  ...(typeof l === 'number' ? {left: resWidth(l)} : {}),
  ...(typeof t === 'number' ? {top: resHeight(t)} : {}),
  ...(typeof r === 'number' ? {right: resWidth(r)} : {}),
  ...(typeof b === 'number' ? {bottom: resHeight(b)} : {}),
  ...(tin !== undefined ? {tintColor: tin} : {}),
  ...(z !== undefined ? {zIndex: z} : {}),
  ...(wrap !== undefined ? {flexWrap: wrap} : {}),
  ...(mw !== undefined ? {maxWidth: mw} : {}),
  ...(typeof over === 'string' ? {overflow: over} : {}),
  ...(typeof op === 'number' ? {opacity: op} : {}),
  ...(typeof borColor === 'string' ? {borderColor: borColor} : {}),
  ...(typeof rotate === 'string' ? {transform: [{rotate}]} : {}),
  ...(cen
    ? {
        justifyContent: 'center',
        alignItems: 'center',
      }
    : {}),
  ...(typeof size === 'number'
    ? {
        width: resWidth(size),
        height: resWidth(size),
      }
    : {}),
});

const Style = StyleSheet.create({
  con,
  border,
  sha: (color = 'rgba(0, 0, 0, 0.07)', width, height, radius) => ({
    shadowColor: color,
    shadowOffset: {
      width: width,
      height: height,
    },
    shadowOpacity: 1,
    shadowRadius: radius,
    // elevation: radius / 1.2,
  }),
});

export default Style;
