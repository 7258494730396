import React, {useGlobal} from 'reactn';
import {Text, View, ScrollView, Platform} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {Style, Fonts, Palette, gutters} from '../styles';
import {Header} from '../components';
import {OptionRow} from '../components';
import {responsiveHeight} from 'react-native-responsive-dimensions';
import {arrayRemove, arrayUnion, usersRef} from '../config/firebase';
import Page from '../components/Page';

export const notificationTypeListSms = [
  {
    label: 'Rappel de rendez-vous',
    type: 'SMS_MEETING_REMINDER',
  },
  // {
  //   label: 'Nouvelles publications de vos salons',
  //   type: 'SMS_NEW_POST_INSTITUTES',
  // },
  // {
  //   label: 'Nouvelles prestations de vos salons',
  //   type: 'SMS_NEW_PRESTA_INSTITUTES',
  // },
  {
    label: 'Rendez-vous annulé par le salon',
    type: 'SMS_MEETING_CANCELLED',
  },
];

export const notificationTypeListPush = [
  {
    label: 'Rappel de rendez-vous',
    type: 'PUSH_MEETING_REMINDER',
  },
  {
    label: 'Nouvelles publications de vos salons',
    type: 'PUSH_NEW_POST_INSTITUTES',
  },
  {
    label: 'Nouvelles prestations de vos salons',
    type: 'PUSH_NEW_PRESTA_INSTITUTES',
  },
  {
    label: 'Rendez-vous annulé par le salon',
    type: 'PUSH_MEETING_CANCELLED',
  },
];

export default ({navigation}) => {
  const [currentUserData] = useGlobal('currentUserData');

  return (
    <Page edges={[]}>
      <SafeAreaView style={Style.con({flex: 1, bg: Palette.white})}>
        <Header title="Notifications" back navigation={navigation} />
        <ScrollView
          style={Style.con({flex: 1})}
          contentContainerStyle={Style.con({pb: responsiveHeight(8)})}>
          {Platform.OS !== 'web' && (
            <>
              <Text
                style={Fonts.t(15, Palette.black, {
                  wei: '700',
                  y: 45,
                  x: gutters,
                })}>
                {'Notifications push'}
              </Text>
              <View style={Style.con({h: 1, bg: Palette.gray[5]})} />
              {notificationTypeListPush.map(
                ({label = '', type = ''}, index) => {
                  const notificationIncluded =
                    currentUserData?.allowedNotificationTypes?.includes(type);
                  const toggleNotification = async () => {
                    try {
                      await usersRef.doc(currentUserData.id).update({
                        allowedNotificationTypes:
                          currentUserData?.allowedNotificationTypes?.includes(
                            type,
                          )
                            ? arrayRemove(type)
                            : arrayUnion(type),
                      });
                    } catch (error) {
                      console.log(error);
                    }
                  };
                  return (
                    <OptionRow
                      key={index}
                      title={label}
                      switchValue={notificationIncluded}
                      divider
                      showSwitch
                      thumbColor
                      onSwitchChange={() => {
                        toggleNotification(type);
                      }}
                      style={Style.con({py: responsiveHeight(3)})}
                      switchTrackColor={{
                        false: '#F1F1F1',
                        true: Palette.primary,
                      }}
                    />
                  );
                },
              )}
            </>
          )}

          <Text
            style={Fonts.t(15, Palette.black, {
              wei: '700',
              y: responsiveHeight(4),
              x: gutters,
            })}>
            {'Notifications SMS'}
          </Text>

          <View style={Style.con({h: 1, bg: Palette.gray[5]})} />

          {notificationTypeListSms.map(({label = '', type = ''}, index) => {
            const notificationIncluded =
              currentUserData?.allowedNotificationTypes?.includes(type);
            const toggleNotification = async () => {
              try {
                await usersRef.doc(currentUserData.id).update({
                  allowedNotificationTypes:
                    currentUserData?.allowedNotificationTypes?.includes(type)
                      ? arrayRemove(type)
                      : arrayUnion(type),
                });
              } catch (error) {
                console.log(error);
              }
            };
            return (
              <OptionRow
                key={index}
                title={label}
                switchValue={notificationIncluded}
                onSwitchChange={() => {
                  console.log('toggleNotification', type);
                  toggleNotification(type);
                }}
                divider
                showSwitch
                style={Style.con({py: responsiveHeight(3)})}
                switchTrackColor={{false: '#F1F1F1', true: Palette.primary}}
              />
            );
          })}
        </ScrollView>
      </SafeAreaView>
    </Page>
  );
};
