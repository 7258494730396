import React, {useState, useGlobal} from 'reactn';
import {Text, View, ScrollView, Image, TouchableOpacity} from 'react-native';
import {responsiveHeight} from 'react-native-responsive-dimensions';
import {Style, Fonts, Palette, gutters} from '../../styles';
import {
  Header,
  DatePickerWidget,
  HourPickerWidget,
  Button,
} from '../../components';
import moment from 'moment';
import {BOTTOM_BAR_HEIGHT} from '../../common';
import {dummyDataHours} from '../../data';
import {Routes} from '../../navigation';
import firebase, {institutesRef, meetingsRef} from '../../config/firebase';
import {useNavigation} from '@react-navigation/native';
import useDataFromRef from '../../hooks/useDataFromRef';
import Page from '../../components/Page';

export default ({route}) => {
  const navigation = useNavigation();

  const {meetingRef} = route?.params;
  const onPressSettings = () => undefined;
  const [daySelected, setDaySelected] = useState(moment());
  const [hourSelected, setHourSelected] = useState(null);
  const [isGlobalLoading, setIsGlobalLoading] = useGlobal('_isLoading');
  const [tooltip, setTooltip] = useGlobal('_tooltip');
  const instituteProfilPicture =
    meetingRef?.instituteData?.instituteProfilPicture;
  const instituteName = meetingRef?.instituteData?.instituteName;

  // const meetingsRef = db.collection("meetings").doc(meetingRef.meetingId);
  const currentMeetingDate = meetingRef?.date;
  const hourArraySplitted = hourSelected?.split(':') || null;
  const meetingHour = hourArraySplitted?.[0] || null;
  const meetingMinute = hourArraySplitted?.[1] || null;
  const meetingDate = daySelected.toDate() || null;
  meetingDate?.setHours(meetingHour, meetingMinute) || null;

  const {data: theinstitute} = useDataFromRef({
    ref: institutesRef.doc(meetingRef?.instituteData?.instituteId),
    simpleRef: true,
    refreshArray: [],
    listener: true,
  });

  const onPressConfirmation = async () => {
    try {
      await setIsGlobalLoading(true);

      if (daySelected !== null && hourSelected !== null) {
        try {
          await meetingsRef.doc(meetingRef.meetingId).update({
            date: meetingDate,
          });
          navigation?.navigate(Routes.ReservationValidated, {
            meetingDate,
            instituteName,
            instituteProfilPicture,
            meetingRef,
          });
          console.log('Document written with success');
        } catch (error) {
          console.log(error);
        }
      } else {
        throw new Error('Selectionnez une date et une heure');
      }
    } catch (error) {
      await setTooltip({text: error.message});
      console.error(error);
    } finally {
      await setIsGlobalLoading(false);
    }
  };

  return (
    <Page>
      <Header title="Déplacer votre rendez-vous" back navigation={navigation} />
      <ScrollView
        style={Style.con({flex: 1})}
        contentContainerStyle={Style.con({
          pt: 30,
          px: gutters,
          pb: BOTTOM_BAR_HEIGHT,
        })}>
        <View
          style={Style.con({
            h: 128,
            bg: Palette.gray[2],
            mb: 20,
            bor: 17,
            px: gutters,
            direc: 'row',
            items: 'center',
          })}>
          <Image
            style={Style.con({size: 78, bor: 5})}
            source={{
              uri: meetingRef?.instituteData?.instituteProfilPicture,
            }}
          />
          <View style={Style.con({ml: 30})}>
            <Text style={Fonts.t(15, Palette.black, {wei: '700'})}>
              {moment(currentMeetingDate).format('dddd DD MMMM [•] HH [h]')}
            </Text>
            <Text style={Fonts.t(13, Palette.black, {wei: '400', t: 6})}>
              {`${instituteName} • ${meetingRef?.prestationData.length} prestations`}
            </Text>
          </View>
        </View>
        <View
          style={Style.con({
            h: 1,
            bg: Palette.gray[5],
            mb: responsiveHeight(3),
          })}
        />
        <Text
          style={Fonts.t(15, Palette.black, {
            wei: '600',
            x: gutters,
            b: responsiveHeight(1),
          })}>
          {'Date et heure de rendez-vous disponibles'}
        </Text>
        <DatePickerWidget
          daySelected={daySelected}
          setDaySelected={setDaySelected}
          style={Style.con({mb: responsiveHeight(1)})}
        />
        <HourPickerWidget
          data={dummyDataHours}
          daySelected={daySelected}
          hourSelected={hourSelected}
          setHourSelected={setHourSelected}
          theinstitute={theinstitute}
          instituteId={meetingRef?.instituteData?.instituteId}
        />
        <Button
          style={Style.con({
            // pos: "absolute",
            // bottom: 0,
            // right: 0,
            // left: 0,
            mx: gutters,
          })}
          title="Confirmer"
          onPress={onPressConfirmation}
        />
      </ScrollView>
    </Page>
  );
};
