// import {responsiveFontSize} from 'react-native-responsive-dimensions';
import { Colors, resHeight, resWidth } from '../styles';

// export const scaleFromFigma = s => responsiveFontSize(s / 7.6);
export const scaleFromFigma = s => s;

const base = (
  fontSize = 14,
  fontWeight = '400',
  color = Colors.text.primary,
  lineHeight,
  letterSpacing,
) => ({
  fontSize: scaleFromFigma(fontSize),
  // fontFamily: 'DMSans-Regular',
  color,
  ...(typeof fontWeight === 'string' ? { fontWeight } : {}),
  ...(typeof lineHeight === 'number' ? { lineHeight } : {}),
  ...(typeof letterSpacing === 'number' ? { letterSpacing } : {}),
});

const margin = (left, top, right, bottom, x, y) => ({
  marginLeft: typeof left === 'number' ? resWidth(left) : left,
  marginTop: typeof top === 'number' ? resHeight(top) : top,
  marginRight: typeof right === 'number' ? resWidth(right) : right,
  marginBottom: typeof bottom === 'number' ? resHeight(bottom) : bottom,
  marginHorizontal: typeof x === 'number' ? resWidth(x) : x,
  marginVertical: typeof y === 'number' ? resHeight(y) : y,
});

const align = (
  self = 'auto',
  textAlign = 'auto',
  textAlignVertical = 'auto',
) => ({
  alignSelf: self,
  textAlign,
  textAlignVertical,
});

const decoration = (dec, style, color) => ({
  ...(typeof dec === 'string' ? { textDecorationLine: dec } : {}),
  ...(typeof style === 'string' ? { textDecorationStyle: style } : {}),
  ...(typeof color === 'string' ? { textDecorationColor: color } : {}),
});

const Fonts = {
  t: (
    size,
    color,
    {
      wei,
      l,
      t,
      r,
      b,
      x,
      y,
      self,
      text,
      op,
      dec,
      decStyle,
      secColor,
      textVer,
      h,
      space,
      font,
    } = {},
  ) => ({
    ...base(size, wei, color, h, space),
    ...margin(l, t, r, b, x, y),
    ...align(self, text, textVer),
    ...decoration(dec, decStyle, secColor),
    opacity: op,
    fontFamily: font ?? 'Urbanist', // 'Playfair Display',
  }),
};

export default Fonts;
