export const Routes = {
  Splash: 'Splash',
  Inscription: 'Inscription',
  Login: 'Login',
  ForgotPassword: 'ForgotPassword',
  LinkSent: 'LinkSent',
  AccountCreated: 'AccountCreated',
  Home: 'Home',
  Map: 'Map',
  MapStack: 'MapStack',
  Search: 'Search',
  Reels: 'Reels',
  Profile: 'Profile',
  BottomTab: 'BottomTab',
  PostDetails: 'PostDetails',
  HomeStack: 'HomeStack',
  SearchStack: 'SearchStack',
  MyAccount: 'MyAccount',
  ProfileStack: 'ProfileStack',
  MoveAppointment: 'MoveAppointment',
  Privacy: 'Privacy',
  About: 'About',
  Help: 'Help',
  Notifications: 'Notifications',
  InstituteProfile: 'InstituteProfile',
  PostByHashtag: 'PostByHashtag',
  ReelsStack: 'ReelsStack',
  MakeAppointment: 'MakeAppointment',
  PostsRelatedToService: 'PostsRelatedToService',
  ChooseTimeDate: 'ChooseTimeDate',
  Recap: 'Recap',
  ReservationValidated: 'ReservationValidated',
  ReservationAttempt: 'ReservationAttempt',
};
