import React from "react";
import { Text, View, Image, TouchableOpacity } from "react-native";
import {
  responsiveHeight,
  responsiveWidth,
} from "react-native-responsive-dimensions";
import { DIMENSION_WIDTH } from "../common";

import { Fonts, gutters, Palette, Style } from "../styles";
import { Spacer, Button } from "../components";
import { icons } from "../assets";
import { isDesktopWeb } from "../config/layoutConstants";

export const ScoreFilterUI = ({
  scoreSelected,
  setScoreSelected,
  onReset = () => { },
  onApply = () => { },
}) => {
  const [tempScoreSelected, setTempScoreSelected] =
    React.useState(scoreSelected);
  return (
    <View style={Style.con({ flex: 1, justify: 'space-between', mb: 30 })}>
      <View>
        <Text
          style={Fonts.t(17, Palette.black, {
            wei: "700",
            text: "center",
            t: responsiveHeight(1.5),
          })}
        >
          {"Note"}
        </Text>
        <Text
          style={Fonts.t(15, Palette.black, {
            wei: "600",
            t: 40,
            x: isDesktopWeb ? gutters : 30,
            b: 20,
          })}
        >
          {"À partir de "}
        </Text>
        <View
          style={[
            Style.con({
              direc: "row",
              cen: true,
              alignSelf: "center",
              bg: Palette.gray[16],
              py: 10,
              h: 54,
              mx: gutters,
            }),
            Style.border({ bor: 10 }),
          ]}
        >
          {[1, 2, 3, 4, 5].map((item) => {
            const isSelected = item <= tempScoreSelected;
            return (
              <TouchableOpacity
                onPress={() => setTempScoreSelected(item)}
                key={item}
                style={Style.con({ mx: 5 })}
              >
                <Image
                  style={Style.con({ size: 40 })}
                  source={
                    isSelected ? icons.iconStarSelect : icons.iconStarUnselect
                  }
                />
              </TouchableOpacity>
            );
          })}
        </View>
      </View>
      <View>
        <Button
          title="Appliquer"
          onPress={() => {
            setScoreSelected(tempScoreSelected);
            onApply(tempScoreSelected);
          }}
          style={Style.con({ mx: gutters, mb: 6 })}
        />
        <Button
          primary={false}
          title="Réinitialiser" // Reset
          onPress={onReset}
          style={Style.con({ mx: gutters })}
        />
      </View>
    </View>
  );
};

export default ScoreFilterUI;
