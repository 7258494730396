import { placeholders } from '../assets';

export const thematicData = [
  { id: 0, type: 'HANDS_AND_FEET', name: 'Mains & pieds', icon: placeholders.nailsHires },
  { id: 1, type: 'MAKE_UP', name: 'Maquillage', icon: placeholders.lipGloss },
  { id: 2, type: 'HAIR', name: 'Cheveux', icon: placeholders.hairHires },
  { id: 3, type: 'BODY_CARE', name: 'Soins du corps', icon: placeholders.stonesHires },
  { id: 4, type: 'EYELASHES', name: 'Cils', icon: placeholders.eyelashHires },
  { id: 5, type: 'FACE_CARE', name: 'Soins du visage', icon: placeholders.cleansingHires },
  { id: 6, type: 'LIPS', name: 'Lèvres', icon: placeholders.lipsHires },
  { id: 7, type: 'WAXING', name: 'Épilation', icon: placeholders.waxingHires },
  { id: 8, type: 'SURGERY', name: 'Chirurgie', icon: placeholders.surgeryHires },
]


export const dummyDataHours = [
  '10:00',
  '13:00',
  '11:00',
  '14:00',
  '12:00',
  '15:00',
  '16:00',
  '19:00',
  '17:00',
  '20:00',
  '18:00',
  '21:00',
];

export const dummyDataPrivacy = {
  title: 'SPOT BEAUTY inc.',
  content: `Ça me semble interressant ici que ce soit le navigateur compris dans l’application qui s’ouvre sur les pages du site crées pour ça. 

  ostrum iusto vel sunt earum ex sint veritatis ea odio quia qui alias voluptatem! Et expedita reiciendis est similique quasi ut quae optio! Quo necessitatibus cumque vel sunt mollitia id omnis corrupti eum alias nihil aut temporibus architecto.
  
  Quo quisquam blanditiis aut doloremque enim vel cumque quae qui incidunt eos natus eveniet in molestiae explicabo cum expedita quas. Aut quidem facere cum aliquid eaque sed pariatur dicta hic sapiente commodi. Eum repellendus nobis et aperiam quidem qui odit omnis eos sunt cupiditate laudantium quia a voluptatibus natus.
  
  Sed pariatur praesentium eos quidem sapiente est velit dolores cum dolore omnis ea error iste aut corporis placeat ut autem magni. Qui aliquam quidem ut dolor cupiditate eos ipsam commodi qui perspiciatis consequatur. Ut assumenda nisi et ipsam laboriosam ea corporis perspiciatis At doloremque dolorem et voluptatibus dolor. Qui expedita dicta vel dolores voluptatem minus animi ut repellendus expedita aut sunt facere non aspernatur eius.`,
};

