import {Alert, Linking} from 'react-native';
import * as Location from 'expo-location';

async function start() {
  try {
    const {status: foregroundStatus} =
      await Location.requestForegroundPermissionsAsync();
    if (foregroundStatus === 'granted') {
      const {status: backgroundStatus} =
        await Location.requestBackgroundPermissionsAsync();
      if (backgroundStatus === 'granted') {
        await Location.startLocationUpdatesAsync('LOCATION_UPDATES', {
          accuracy: Location.Accuracy.Balanced,
        });
      }
    }
  } catch (e) {
    // if (!__DEV__) {
    errorAndOpenLocationSettings();
    // }

    console.log('error location ' + e);
  }
}

function errorAndOpenLocationSettings() {
  Alert.alert('Erreur', 'Nous avons besoin de connaître votre localisation.', [
    {
      text: 'Ouvrir les paramètres',
      onPress: () => Linking.openSettings(),
    },
    {
      text: 'Annuler',
      onPress: () => null,
    },
  ]);
}

async function location() {
  return await Location.getCurrentPositionAsync({});
}

const Locations = {start, location};
export default Locations;
