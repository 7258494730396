import React from 'react';
import {Image, Text, TouchableOpacity, View} from 'react-native';
import {Fonts, gutters, Palette, Style} from '../styles';
import {icons} from '../assets';
import {Routes} from '../navigation';
import {Rating} from '../helpers/rating';
import Carousel from '../components/SnapCarrousel';
import {DIMENSION_WIDTH} from '../common';

const PlaceHorizontalList = ({
  style = {},
  itemStyle = {},
  data,
  containerCustomStyle = {},
  isMap = false,
  navigation,
  onScroll = {},
}) => {
  const navigateToInstitute = instituteData => {
    navigation?.navigate(Routes.InstituteProfile, {
      instituteProfilPicture: instituteData.profilPicture,
      instituteId: instituteData.instituteId,
      instituteName: instituteData.name,
      categories: instituteData.categories,
      ratings: instituteData.ratings,
      instituteLocation: instituteData.location,
      instituteInstagram: instituteData.instagram,
      instituteType: instituteData.type,
    });
  };

  return (
    <>
      <Carousel
        onSnapToItem={onScroll}
        showsHorizontalScrollIndicator={false}
        keyExtractor={(item, index) => index.toString()}
        data={data}
        itemWidth={250}
        sliderWidth={DIMENSION_WIDTH}
        inactiveSlideOpacity={1}
        inactiveSlideScale={1}
        activeSlideAlignment={'start'}
        containerCustomStyle={containerCustomStyle}
        contentContainerCustomStyle={[Style.con({h: 158}), style]}
        navigateToInstitute={navigateToInstitute}
        navigation={navigation}
        isMap={isMap}
        itemStyle={itemStyle}
        renderItem={({item: institute, idx}) => {
          const instituteId =
            institute.objectID || institute.instituteId || institute.id;

          const ratingStart = Math.ceil(parseFloat(Rating(institute?.ratings)));

          return (
            <TouchableOpacity
              onPress={() => {
                isMap
                  ? navigateToInstitute({...institute, instituteId})
                  : navigation?.navigate(Routes.InstituteProfile, {
                      instituteProfilPicture: institute.profilPicture,
                      instituteId: institute.instituteId,
                      instituteName: institute.name,
                      categories: institute.categories,
                      ratings: institute.ratings,
                      instituteLocation: institute.location,
                      instituteInstagram: institute.instagram,
                      instituteType: institute.type,
                    });
              }}
              key={instituteId}
              style={[
                Style.con({
                  bg: Palette.white,
                  bor: 15,
                  h: 158,
                  w: 221,
                  ml: 16,
                  over: 'hidden',
                }),
                itemStyle,
              ]}>
              <Image
                style={Style.con({h: 60, w: 221})}
                source={{uri: institute?.coverPicture}}
              />
              <View
                style={[
                  Style.con({
                    flex: 1,
                    py: 12,
                    px: gutters / 2,
                    justify: 'space-between',
                  }),
                ]}>
                <Text style={Fonts.t(15, Palette.black, {wei: '700'})}>
                  {institute?.name}
                </Text>
                <Text
                  numberOfLines={2}
                  ellipsizeMode="tail"
                  style={Fonts.t(13, Palette.black, {wei: '400'})}>
                  {`📍 ${institute?.location?.addressIns} ${institute?.location?.postCode} ${institute?.location?.city} `}
                </Text>
                <View style={Style.con({direc: 'row', items: 'center'})}>
                  {ratingStart ? (
                    <>
                      <Text style={Fonts.t(13, Palette.black, {wei: '400'})}>
                        {institute.ratingNote}
                      </Text>
                      <View
                        style={Style.con({
                          direc: 'row',
                          items: 'center',
                          ml: 10,
                        })}>
                        {Array(ratingStart)
                          .fill('')
                          .map((v, i) => (
                            <Image
                              key={i}
                              style={Style.con({size: 12})}
                              source={icons.iconStar}
                            />
                          ))}
                      </View>
                      <Text
                        style={Fonts.t(13, Palette.black, {wei: '400', l: 10})}>
                        {`${institute?.reviewsId?.length || 0} avis`}
                      </Text>
                    </>
                  ) : (
                    <Text style={Fonts.t(13, Palette.black, {wei: '400'})}>
                      Aucun avis publié
                    </Text>
                  )}
                </View>
              </View>
            </TouchableOpacity>
          );
        }}
      />
    </>
  );
};

export default PlaceHorizontalList;
