import React, {useGlobal, useEffect, useState} from 'reactn';
import {
  Text,
  View,
  TouchableOpacity,
  Image,
  ScrollView,
  FlatList,
} from 'react-native';
import {
  responsiveHeight,
  responsiveWidth,
} from 'react-native-responsive-dimensions';
import {useNavigation, StackActions} from '@react-navigation/native';

import {Style, Fonts, Palette, gutters} from '../styles';
import {icons} from '../assets';
import {
  DatePickerWidget,
  Header,
  HourPickerWidget,
  Button,
} from '../components';
import {Routes} from '../navigation';

import useDataFromRef from '../hooks/useDataFromRef';
import {institutesRef} from '../config/firebase';
import moment from 'moment';
import firebase, {meetingsRef} from '../config/firebase';
import Page from '../components/Page';
import {isDesktopWeb} from '../config/layoutConstants';
import {renderPrice} from '../helpers';
import {useRef} from 'react';

export default ({route}) => {
  const navigation = useNavigation();
  const {institute = {}, prestationData, meetingRef} = route?.params;
  const {
    instituteName,
    instituteId = null,
    instituteProfilPicture,
    instituteInstagram,
    instituteLocation,
  } = institute;
  const [, setTooltip] = useGlobal('_tooltip');
  const [selectedPrestations, setSelectedPrestations] = useGlobal(
    'selectedPrestations',
  );
  const [, setIsGlobalLoading] = useGlobal('_isLoading');

  const prestations =
    selectedPrestations?.[instituteId] ||
    prestationData ||
    meetingRef?.prestationData ||
    [];

  const [daySelected, setDaySelected] = useState(moment());
  const [hourSelected, setHourSelected] = useState(null);
  const scrollRef = useRef();

  // MoveMeeting
  const currentMeetingDate = meetingRef?.date;
  const hourArraySplitted = hourSelected?.split(':') || null;
  const meetingHour = hourArraySplitted?.[0] || null;
  const meetingMinute = hourArraySplitted?.[1] || null;
  const meetingDate = daySelected.toDate() || null;

  meetingDate?.setHours(meetingHour, meetingMinute) || null;

  const [withWhom, setWithWhom] = useState(null);
  let [firstAvailability, setFirstAvailability] = useState(true);

  const {data: employeesList, loading: isLoadingEmployeeList} = useDataFromRef({
    ref: instituteId
      ? institutesRef.doc(instituteId).collection('employees')
      : null,
    simpleRef: false,
    initialState: [],
    listener: true,
    documentID: 'employeeId',
    refreshArray: [],
    condition: instituteId,
  });

  const {data: theinstitute, loading: isLoadingInstituteData} = useDataFromRef({
    ref: firebase.firestore().collection('institutes').doc(instituteId),
    simpleRef: true,
    refreshArray: [],
    listener: true,
  });

  useEffect(() => {
    setHourSelected(null);
  }, [daySelected, withWhom?.employeeId]);

  const onValidateReservation = () => {
    try {
      if (!prestations.length) {
        throw new Error('Selectionnez une prestation');
      }

      if (!withWhom?.employeeId) {
        throw new Error('Selectionnez un employé');
      }

      console.log(
        'validation daySelected',
        moment(daySelected).format('DD/MM/YYYY'),
      );

      if (daySelected && hourSelected) {
        navigation?.navigate(Routes.Recap, {
          prestations,
          instituteName,
          instituteId,
          instituteProfilPicture,
          instituteInstagram,
          instituteLocation,
          daySelected: moment(daySelected).format('DD/MM/YYYY').toString(),
          hourSelected,
          withWhom,
        });
      } else {
        throw new Error('Selectionnez une date et une heure');
      }
    } catch (error) {
      setTooltip({text: error.message});
    }
  };

  const onPressMoveAppointment = async () => {
    try {
      await setIsGlobalLoading(true);

      if (daySelected !== null && hourSelected !== null) {
        try {
          if (!withWhom?.employeeId) {
            throw new Error('Selectionnez un employé');
          }

          await meetingsRef.doc(meetingRef?.meetingId).update({
            date: meetingDate,
            employeeId: withWhom?.employeeId || null,
          });
          navigation?.navigate(Routes.ReservationValidated, {
            meetingDate,
            instituteName,
            instituteProfilPicture,
            meetingRef,
          });
          console.log('Document written with success');
        } catch (error) {
          console.log(error);
        }
      } else {
        throw new Error('Selectionnez une date et une heure');
      }
    } catch (error) {
      await setTooltip({text: error.message});
      console.error(error);
    } finally {
      await setIsGlobalLoading(false);
    }
  };

  const pushAction = StackActions.push(Routes.MakeAppointment, {institute});

  const disableNextButton = meetingRef ? false : !prestations.length === 0;

  const renderMeetingRef = () => {
    return (
      <View>
        <Text
          style={Fonts.t(27, Palette.black, {
            wei: '700',
            y: responsiveHeight(4),
            self: 'center',
          })}>
          {instituteName}
        </Text>

        <View style={Style.con({h: 1, bg: Palette.gray[5]})} />

        {meetingRef && (
          <View
            style={Style.con({
              h: 128,
              bg: Palette.gray[2],
              mb: 20,
              bor: 17,
              px: gutters,
              direc: 'row',
              items: 'center',
            })}>
            <Image
              style={Style.con({size: 78, bor: 5})}
              source={{
                uri: meetingRef?.instituteData?.instituteProfilPicture,
              }}
            />
            <View style={Style.con({ml: 30})}>
              <Text style={Fonts.t(15, Palette.black, {wei: '700'})}>
                {moment(currentMeetingDate).format(
                  'dddd DD MMMM [•] HH [h] mm',
                )}
              </Text>
              <Text style={Fonts.t(13, Palette.black, {wei: '400', t: 6})}>
                {`${instituteName} • ${meetingRef?.prestationData.length} prestations`}
              </Text>
            </View>
          </View>
        )}

        <View
          style={Style.con({
            px: gutters,
            py: responsiveHeight(2),
          })}>
          {(prestations || []).map(item => {
            const removePrestation = () => {
              const indexPresta = prestations.indexOf(item);
              if (indexPresta > -1) {
                prestations.splice(indexPresta, 1);
              }
              setSelectedPrestations({
                ...prestations,
                [instituteId]: prestations,
              });
            };
            return (
              <View
                key={item?.prestationId}
                style={Style.con({
                  py: responsiveHeight(2),
                  mb: responsiveHeight(2),
                  flex: 1,
                  direc: 'row',
                })}>
                <View style={Style.con({flex: 6})}>
                  <Text style={Fonts.t(13, Palette.black, {wei: '500'})}>
                    {item?.name || item?.prestationName}
                  </Text>
                  {item?.allSkinTypes || item?.prestationAllSkinTypes ? (
                    <Text
                      style={Fonts.t(12, Palette.black, {
                        wei: '400',
                        op: 0.5,
                      })}>
                      Convient à tous les types de peau
                    </Text>
                  ) : (
                    <Text
                      style={Fonts.t(12, Palette.black, {
                        wei: '400',
                        op: 0.5,
                      })}>
                      Ne convient pas à tous les types de peau
                    </Text>
                  )}
                  <View
                    style={Style.con({
                      direc: 'row',
                      items: 'center',
                      t: responsiveHeight(1),
                    })}>
                    <Image
                      style={Style.con({size: 16})}
                      source={icons.iconClock}
                    />
                    <Text
                      style={Fonts.t(12, Palette.black, {
                        wei: '600',
                        l: 9,
                      })}>
                      {`${item?.duration || item?.prestationDuration} min`}
                    </Text>
                    <Text
                      style={Fonts.t(12, Palette.primary, {
                        wei: '600',
                        l: responsiveWidth(12),
                      })}>
                      {renderPrice({prestation: item, phone: null})}
                    </Text>
                  </View>
                </View>

                {!meetingRef && (
                  <View style={Style.con({flex: 1, self: 'center'})}>
                    <TouchableOpacity onPress={removePrestation}>
                      <View
                        style={Style.con({
                          size: 30,
                          bg: Palette.primary,
                          bor: 22,
                          cen: true,
                        })}>
                        <Image
                          style={Style.con({size: 16, tin: Palette.white})}
                          source={icons.iconClose}
                        />
                      </View>
                    </TouchableOpacity>
                  </View>
                )}
              </View>
            );
          })}
        </View>

        <View style={Style.con({h: 1, bg: Palette.gray[5]})} />

        {!meetingRef && (
          <TouchableOpacity
            onPress={() => navigation?.dispatch(pushAction)}
            style={Style.con({
              direc: 'row',
              cen: true,
              py: 16,
              mx: 22,
              mb: 26,
            })}>
            <View
              style={Style.con({
                size: 22,
                bg: Palette.primary,
                bor: 22,
                cen: true,
              })}>
              <Text style={Fonts.t(17, Palette.white, {wei: '700'})}>
                {'+'}
              </Text>
            </View>
            <Text style={Fonts.t(17, Palette.primary, {wei: '700', l: 12})}>
              {'Ajouter une prestation à la suite'}
            </Text>
          </TouchableOpacity>
        )}

        <View style={Style.con({mb: responsiveHeight(3)})}>
          <Text
            style={Fonts.t(15, Palette.black, {
              wei: '600',
              x: gutters,
              b: 12,
            })}>
            {'Avec qui ? '}
          </Text>

          <View style={Style.con({direc: 'row', px: gutters - 4})}>
            <FlatList
              horizontal
              data={employeesList}
              showsHorizontalScrollIndicator={false}
              /* ListHeaderComponent={() => {
                return (
                  <TouchableOpacity
                    onPress={() => {
                      setFirstAvailability(true);
                      setWithWhom(null);
                    }}
                  >
                    <View
                      style={Style.con({
                        h: 128,
                        w: 117,
                        mx: 4,
                        cen: true,
                        bg: firstAvailability
                          ? Palette.primary
                          : Palette.gray[2],
                        bor: 15,
                      })}
                    >
                      <Text
                        style={Fonts.t(
                          13,
                          firstAvailability ? Palette.white : Palette.black,
                          {
                            wei: "600",
                            text: "center",
                          }
                        )}
                      >
                        {"Indifférent"}
                      </Text>
                    </View>
                  </TouchableOpacity>
                );
              }} */
              renderItem={({item: member}) => {
                let isSelected = member?.employeeId === withWhom?.employeeId;
                const {profilPicture = ''} = member;
                if (firstAvailability === true) {
                  isSelected = false;
                }

                return (
                  <TouchableOpacity
                    onPress={() => {
                      setFirstAvailability(false);
                      setWithWhom(member);
                      setTimeout(
                        () => scrollRef.current?.scrollToEnd({animated: true}),
                        500,
                      );
                    }}
                    style={Style.con({
                      h: 128,
                      w: 117,
                      mx: 4,
                      cen: true,
                      bg: isSelected ? Palette.primary : Palette.gray[2],
                      bor: 15,
                    })}>
                    {member?.guest === true ? (
                      <View
                        style={[
                          Style.border({
                            btrr: 15,
                            bblr: 15,
                          }),
                          Style.con({
                            bg: isSelected ? Palette.white : Palette.primary,
                            pos: 'absolute',
                            t: 0,
                            r: 0,
                            px: 6,
                            py: 2,
                          }),
                        ]}>
                        <Text
                          style={Fonts.t(
                            10,
                            isSelected ? Palette.primary : Palette.white,
                            {
                              wei: '700',
                            },
                          )}>
                          GUEST
                        </Text>
                      </View>
                    ) : null}
                    {profilPicture && (
                      <Image
                        style={Style.con({size: 85, bor: 85})}
                        source={{uri: profilPicture}}
                      />
                    )}
                    <Text
                      style={Fonts.t(
                        13,
                        isSelected ? Palette.white : Palette.black,
                        {wei: '600', t: 5},
                      )}>
                      {member?.name}
                    </Text>
                  </TouchableOpacity>
                );
              }}
            />
          </View>
        </View>
      </View>
    );
  };

  return (
    <Page>
      <Header
        back
        title={
          meetingRef ? 'Déplacer votre rendez-vous' : 'Prendre rendez-vous'
        }
        navigation={navigation}
        withLogo={!isDesktopWeb}
      />
      <ScrollView
        ref={scrollRef}
        style={{
          flex: 1,
        }}
        contentContainerStyle={Style.con({pb: responsiveHeight(10)})}>
        {renderMeetingRef()}

        {withWhom?.employeeId && (
          <View>
            <Text
              style={Fonts.t(15, Palette.black, {
                wei: '600',
                x: gutters,
                b: responsiveHeight(1),
              })}>
              {'Date et heure de rendez-vous'}
            </Text>

            <DatePickerWidget
              daySelected={daySelected}
              setDaySelected={setDaySelected}
              style={Style.con({mx: gutters, mb: responsiveHeight(1)})}
            />

            <HourPickerWidget
              prestations={prestations}
              employeesList={employeesList}
              isLoadingInstitute={
                isLoadingEmployeeList || isLoadingInstituteData
              }
              hourSelected={hourSelected}
              daySelected={daySelected}
              setHourSelected={setHourSelected}
              containerStyle={Style.con({px: gutters})}
              theinstitute={theinstitute}
              withWhom={withWhom}
              instituteId={instituteId}
            />
          </View>
        )}
      </ScrollView>

      <Button
        style={[
          Style.con({mx: gutters, my: responsiveHeight(2)}),
          {
            backgroundColor: disableNextButton
              ? Palette.gray[3]
              : Palette.primary,
          },
          {
            position: 'fixed',
            bottom: 80,
            left: 0,
            right: 0,
          },
        ]}
        title="Continuer"
        onPress={meetingRef ? onPressMoveAppointment : onValidateReservation}
        disabled={disableNextButton}
      />
    </Page>
  );
};
