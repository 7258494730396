import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';

import {Palette} from '../styles';
import {Routes} from './Routes';

import Reels from '../screens/tabs/Reels';
import InstituteProfile from '../screens/InstituteProfile';
import ChooseTimeDate from '../screens/ChooseTimeDate';
import MakeAppointment from '../screens/booking/MakeAppointment';
import Recap from '../screens/Recap';

const screenOptions = {
  headerShown: false,
  cardOverlayEnabled: true,
  headerTitleAlign: 'center',
  headerBackTitleVisible: false,
};

const ReelsStack = createStackNavigator();

export default function ReelsStackScreen() {
  return (
    <ReelsStack.Navigator
      initialRouteName={Routes.Reels}
      screenOptions={[
        {
          contentStyle: {
            backgroundColor: Palette.white,
          },
        },
        screenOptions,
      ]}>
      <ReelsStack.Screen
        name={Routes.Reels}
        component={Reels}
        options={{headerShown: false}}
      />
      <ReelsStack.Screen
        name={Routes.InstituteProfile}
        component={InstituteProfile}
        options={{headerShown: false}}
      />
      <ReelsStack.Screen
        name={Routes.ChooseTimeDate}
        component={ChooseTimeDate}
        options={{headerShown: false}}
      />
      <ReelsStack.Screen
        name={Routes.MakeAppointment}
        component={MakeAppointment}
        options={{headerShown: false}}
      />
      <ReelsStack.Screen
        name={Routes.Recap}
        component={Recap}
        options={{headerShown: false}}
      />
    </ReelsStack.Navigator>
  );
}
