import React, {useGlobal} from 'reactn';
import {Image, StyleSheet, Platform} from 'react-native';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {responsiveHeight} from 'react-native-responsive-dimensions';

import {Routes} from './Routes';
import {Palette, Style} from '../styles';

import TabBar from './TabBar';
import MapStack from './MapStack';
import HomeStack from './HomeStack';
import SearchStack from './SearchStack';
import ProfileStack from './ProfileStack';
import ReelsStack from './ReelsStack';
import {icons} from '../assets';

const bottomTabStyles = StyleSheet.create({
  icon: {
    width: responsiveHeight(4),
    height: responsiveHeight(4),
    resizeMode: 'contain',
  },
});

const BottomTab = createBottomTabNavigator();
export const BottomTabScreen = () => {
  const [, setSelectedTags] = useGlobal('selectedTags');

  return (
    <BottomTab.Navigator tabBar={props => <TabBar {...props} />}>
      <BottomTab.Screen
        name={Routes.HomeStack}
        component={HomeStack}
        listeners={({navigation, route}) => ({
          tabPress: e => {
            setSelectedTags([]);
          },
        })}
        options={{
          headerShown: false,
          tabBarLabel: 'hello',
          tabBarShowLabel: true,
          tabBarIcon: ({focused}) => (
            <Image
              style={[
                bottomTabStyles.icon,
                Style.con({
                  tin: focused ? Palette.primary : Palette.black,
                  size: 26,
                }),
              ]}
              source={icons.iconHome}
            />
          ),
        }}
      />
      <BottomTab.Screen
        name={Routes.SearchStack}
        component={SearchStack}
        options={{
          headerShown: false,
          tabBarLabel: 'hello',
          tabBarShowLabel: true,
          tabBarIcon: ({focused}) => (
            <Image
              style={[
                bottomTabStyles.icon,
                Style.con({
                  tin: focused ? Palette.primary : Palette.black,
                  size: 26,
                }),
              ]}
              source={icons.iconSearch}
            />
          ),
        }}
      />
      <BottomTab.Screen
        name={Routes.MapStack}
        component={MapStack}
        options={{
          headerShown: false,
          tabBarLabel: 'hello',
          tabBarShowLabel: true,
          tabBarIcon: ({focused}) => (
            <Image
              style={[
                bottomTabStyles.icon,
                Style.con({
                  tin: focused ? Palette.primary : Palette.black,
                  size: 26,
                }),
              ]}
              source={icons.iconMap}
            />
          ),
        }}
      />
      <BottomTab.Screen
        name={Routes.ReelsStack}
        component={ReelsStack}
        options={{
          headerShown: false,
          tabBarLabel: 'hello',
          tabBarShowLabel: true,
          tabBarIcon: ({focused}) => (
            <Image
              style={[
                bottomTabStyles.icon,
                Style.con({
                  tin: focused ? Palette.primary : Palette.black,
                  size: 26,
                }),
              ]}
              source={icons.iconReels}
            />
          ),
        }}
      />

      <BottomTab.Screen
        name={Routes.ProfileStack}
        component={ProfileStack}
        options={{
          headerShown: false,
          tabBarLabel: 'hello',
          tabBarShowLabel: true,
          tabBarIcon: ({focused}) => (
            <Image
              style={[
                bottomTabStyles.icon,
                Style.con({
                  tin: focused ? Palette.primary : Palette.black,
                  size: 26,
                }),
              ]}
              source={icons.iconUser}
            />
          ),
        }}
      />
    </BottomTab.Navigator>
  );
};
