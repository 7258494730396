import React, { useGlobal } from "reactn";
import { ActivityIndicator, View } from "react-native";

import { isDesktopWeb, isMobileWeb } from "../config/layoutConstants";
import { Palette } from "../styles";

export default ({ children }) => {
  const [isGlobalLoading] = useGlobal("_isLoading");

  return (
    <>
      {children}

      {isGlobalLoading && (
        <View
          style={[
            {
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 9999,
              backgroundColor: "rgba(255, 255, 255, 0.5)",
            },
            isDesktopWeb
              ? { position: "fixed" }
              : isMobileWeb
              ? { position: "fixed" }
              : { position: "absolute" },
          ]}
        >
          <ActivityIndicator size={"large"} color={Palette.primary} />
        </View>
      )}
    </>
  );
};
