import React from 'react';
import {View, TouchableOpacity, Image, Text} from 'react-native';

import {BOTTOM_BAR_HEIGHT} from '../common';
import {Palette, Style} from '../styles';
import {icons} from '../assets';

import {isDesktopWeb, webSideBarWidth} from '../config/layoutConstants';

const headerArray = [
  {
    title: 'Accueil',
  },
  {
    title: 'Recherche',
  },
  {
    title: 'Carte',
  },
  {
    title: 'Réels',
  },
  {
    title: 'Profil',
  },
];

const TabBar = ({
  style = {},
  state = {},
  descriptors = {},
  navigation = {},
}) => {
  return (
    <View
      style={[
        Style.con({
          pos: 'absolute',
          bg: Palette.white,
          l: 0,
          r: 0,
          b: 0,
          h: BOTTOM_BAR_HEIGHT,
          pb: 12,
          cen: true,
        }),
        Style.border({btw: 0.5, color: Palette.gray[12]}),
        isDesktopWeb
          ? {
              position: 'fixed',
              height: '100%',
              width: webSideBarWidth,
              justifyContent: 'flex-start',
              shadowColor: Palette.primary,
              shadowOffset: {width: 10, height: 0},
              shadowOpacity: 0.2,
              shadowRadius: 20,
            }
          : {},
        style,
      ]}>
      {isDesktopWeb && (
        <Image
          resizeMode="contain"
          source={icons.wink_full_red}
          style={{
            width: '80%',
            height: 80,
            marginVertical: 50,
          }}
        />
      )}

      <View
        style={[
          Style.con({
            direc: isDesktopWeb ? 'column' : 'row',
            justify: isDesktopWeb ? 'center' : 'space-between',
            items: isDesktopWeb ? 'flex-start' : 'center',
            py: 7,
          }),
          isDesktopWeb ? {width: '80%'} : {width: '100%'},
        ]}>
        {state?.routes?.map((route, index) => {
          const {options} = descriptors[route.key];
          const isFocused = state?.index === index;

          const _onPressTabBar = () => {
            const event = navigation.emit({
              type: 'tabPress',
              target: route.key,
              canPreventDefault: true,
            });

            if (!isFocused && !event.defaultPrevented) {
              // The `merge: true` option makes sure that the params inside the tab screen are preserved
              navigation.navigate({name: route.name, merge: true});
            }
          };

          const onLongPress = () => {
            navigation.emit({
              type: 'tabLongPress',
              target: route.key,
            });
          };

          return (
            !options?.hide && (
              <TouchableOpacity
                key={route.key}
                style={[
                  Style.con({flex: 1, cen: true}),
                  isDesktopWeb
                    ? {
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        paddingVertical: 20,
                      }
                    : {},
                ]}
                accessibilityRole="button"
                accessibilityState={isFocused ? {selected: true} : {}}
                accessibilityLabel={options.tabBarAccessibilityLabel}
                testID={options.tabBarTestID}
                onPress={_onPressTabBar}
                onLongPress={onLongPress}>
                {options?.tabBarIcon({focused: isFocused})}

                {isDesktopWeb && (
                  <Text
                    style={{
                      marginLeft: 20,
                      color: isFocused ? Palette.primary : Palette.black,
                    }}>
                    {headerArray[index].title}
                  </Text>
                )}
              </TouchableOpacity>
            )
          );
        })}
      </View>
    </View>
  );
};

export default TabBar;
