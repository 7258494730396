import React, {useState, useGlobal} from 'reactn';
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  ScrollView,
  Alert,
  FlatList,
  ActivityIndicator,
  Platform,
  Linking,
} from 'react-native';
import {Style, Fonts, Palette, gutters} from '../../styles';
import {icons} from '../../assets';
import {
  AppointmentCard,
  PlaceHorizontalList,
  SettingsModal,
  LogoutModal,
  TabView,
  AppointmentModal,
  ReviewModal,
} from '../../components';
import {Routes} from '../../navigation';
import {BOTTOM_BAR_HEIGHT} from '../../common';
import firebase from '../../config/firebase';
import useDataFromRef from '../../hooks/useDataFromRef';
import {institutesRef, meetingsRef, postsRef} from '../../config/firebase';
import moment from 'moment';
import {responsiveWidth} from 'react-native-responsive-dimensions';
import {validateDate} from '../../actions/dateActions';
import {Link, useNavigation} from '@react-navigation/native';
import {Video} from 'expo-av';
import {isDesktopWeb, isMobileWeb} from '../../config/layoutConstants';
import Page from '../../components/Page';
import ReservationAttempt from '../booking/ReservationAttempt';
import colors from '../../styles/Colors';

export const PROFILE_TABS = {
  Appointment: 'Appointment',
  Favoris: 'Favoris',
};

export default () => {
  const [currentUserData] = useGlobal('currentUserData');
  const [currentUID, setCurrentUID] = useGlobal('currentUID');
  const [, setIsLoading] = useGlobal('_isLoading');
  const [curTab, setCurTab] = useState(PROFILE_TABS.Appointment);
  const [showSettings, setShowSettings] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const [showAppointment, setShowAppointment] = useState(false);
  const [appointment, setAppointment] = useState(null);
  const [showReview, setShowReview] = useState(false);
  const [meetingRef, setMeetingRef] = useState(null);

  const navigation = useNavigation();

  const db = firebase.firestore();

  const {data: institutesList} = useDataFromRef({
    ref: institutesRef
      .limit(10)
      .where('favBy', 'array-contains', currentUID || '-'),
    simpleRef: false,
    listener: true,
    documentID: 'instituteId',
    initialState: [],
    refreshArray: [currentUID],
  });

  const meetingQueryParams = {
    simpleRef: false,
    documentID: 'meetingId',
    initialState: [],
    listener: true,
    refreshArray: [currentUID],
    format: (data = []) => {
      return data.map(meeting => ({
        ...meeting,
        date: validateDate(meeting.date),
      }));
    },
  };

  const meetingQueryRef = meetingsRef
    .where('clientId', '==', currentUID || '-')
    .where('status', 'in', ['PENDING', 'PAID', 'CONFIRMED', 'CANCELLED']);

  const {data: upcomingMeetings} = useDataFromRef({
    ...meetingQueryParams,
    ref: meetingQueryRef
      .where('date', '>=', moment().toDate())
      .orderBy('date')
      .limit(10),
  });

  const {data: pastMeetings} = useDataFromRef({
    ...meetingQueryParams,
    ref: meetingQueryRef
      .where('date', '<=', moment().toDate())
      .orderBy('date')
      .limit(15),
  });

  const {
    data: postsList,
    // loading,
    // loadMore,
  } = useDataFromRef({
    ref: postsRef.where('favBy', 'array-contains', currentUID || '-'),
    simpleRef: false,
    documentID: 'postId',
    listener: true,
    initialState: [],
    refreshArray: [currentUID],
  });

  const today = new Date();

  const onPressSettings = () => {
    setShowSettings(true);
  };

  const handleConfirmLogout = async () => {
    try {
      await setIsLoading(true);
      await firebase.auth().signOut();
      await setCurrentUID(null);
      navigation?.goBack();
      console.log('Logout successfully done with userId: ', currentUserData.id);
    } catch (error) {
      console.log(error);
    } finally {
      await setIsLoading(false);
      setShowLogout(false);
    }
  };

  const onCancelMeeting = async () => {
    await setIsLoading(true);
    try {
      await db
        .collection('meetings')
        .doc(meetingRef.meetingId)
        .update({status: 'CANCELLED'});

      setMeetingRef(null);
      setShowAppointment(false);
      setAppointment(null);
    } catch (error) {
      console.log(error);
    } finally {
      await setIsLoading(false);
    }
  };

  const alertPolyfill = (title, description, options) => {
    const result = window.confirm(
      [title, description].filter(Boolean).join('\n'),
    );

    if (result) {
      const confirmOption = options.find(({style}) => style !== 'cancel');
      confirmOption && confirmOption.onPress();
    } else {
      const cancelOption = options.find(({style}) => style === 'cancel');
      cancelOption && cancelOption.onPress();
    }
  };

  const alert = Platform.OS === 'web' ? alertPolyfill : Alert.alert;

  const onCancelAlert = () => {
    alert('Attention', 'Voulez-vous vraiment annuler cette réservation ?', [
      {
        text: 'Confirmer',
        onPress: () => {
          onCancelMeeting(meetingRef);
        },
        style: 'destructive',
      },
      {
        text: 'Annuler',
        onPress: () => {
          setShowAppointment(false);
        },
        style: 'cancel',
      },
    ]);
  };

  const settingsList = [
    {
      id: 1,
      title: 'Mon compte',
      onPress: () => {
        navigation?.navigate(Routes.MyAccount);
      },
    },
    {
      id: 2,
      title: 'Notifications',
      onPress: () => {
        navigation?.navigate(Routes.Notifications);
      },
    },
    {
      id: 3,
      title: 'Aide',
      onPress: () => {
        navigation?.navigate(Routes.Help);
      },
    },
    {
      id: 4,
      title: 'À propos',
      onPress: () => {
        navigation?.navigate(Routes.About);
      },
    },
    {
      id: 5,
      title: 'Confidentialité',
      onPress: () => {
        const url = encodeURI(
          'https://firebasestorage.googleapis.com/v0/b/beautyspot-f58bf.appspot.com/o/CGU%2FPolitique%20de%20Confidentialite%CC%81%20WINK.pdf?alt=media&token=0f87564e-796f-4985-970a-fa779d60c524',
        );
        Linking.openURL(url);
        // navigation?.navigate(Routes.Privacy);
      },
    },
    {
      id: 6,
      title: 'Déconnexion',
      onPress: () => {
        setShowLogout(true);
      },
      color: Palette.primary,
    },
  ];

  const renderTabBody = () => {
    if (curTab === PROFILE_TABS.Appointment) {
      return (
        <ScrollView
          nestedScrollEnabled
          contentContainerStyle={[
            Style.con({
              pt: 30,
              px: gutters,
              pb: BOTTOM_BAR_HEIGHT,
            }),
            isMobileWeb
              ? {
                  height: '90vh',
                }
              : {},
          ]}>
          <Text
            style={Fonts.t(17, Palette.primary, {
              wei: '700',
              self: 'center',
              b: 24,
            })}>
            {'Rendez-vous à venir'}
          </Text>
          {upcomingMeetings.length === 0 ? (
            <View
              style={Style.con({justify: 'center', self: 'center', mb: 20})}>
              <Text style={Fonts.t(14)}>
                {"Vous n'avez pas encore de rendez-vous."}
              </Text>
            </View>
          ) : (
            <View>
              {upcomingMeetings.map(item => {
                const upcomingMeetings = moment(item?.date).isAfter(
                  moment(today),
                );
                if (upcomingMeetings) {
                  return (
                    <AppointmentCard
                      key={item.meetingId}
                      item={item}
                      onPress={() => {
                        setShowAppointment(true);
                        setAppointment('Upcoming appointments');
                        setMeetingRef(item);
                      }}
                    />
                  );
                }
              })}
            </View>
          )}

          {pastMeetings.length > 0 && (
            <>
              <Text
                style={Fonts.t(17, Palette.primary, {
                  wei: '700',
                  self: 'center',
                  b: 24,
                })}>
                {'Rendez-vous passés'}
              </Text>
              <View style={Style.con({mb: 120})}>
                {pastMeetings.map((item, index) => {
                  const pastMeeting = moment(item?.date).isBefore(
                    moment(today),
                  );
                  if (pastMeeting) {
                    return (
                      <AppointmentCard
                        key={item.meetingId}
                        item={item}
                        onPress={() => {
                          setShowAppointment(true);
                          setAppointment('Past appointments');
                          setMeetingRef(item);
                        }}
                      />
                    );
                  }
                })}
              </View>
            </>
          )}
        </ScrollView>
      );
    }
    if (curTab === PROFILE_TABS.Favoris) {
      return (
        <ScrollView
          nestedScrollEnabled
          style={Style.con({flex: 1})}
          contentContainerStyle={Style.con({pt: 30, pb: BOTTOM_BAR_HEIGHT})}>
          <Text
            style={Fonts.t(17, Palette.primary, {
              wei: '700',
              self: 'center',
              b: 24,
            })}>
            {'Salons favoris'}
          </Text>
          {institutesList.length === 0 ? (
            <View style={Style.con({justify: 'center', self: 'center'})}>
              <Text style={Fonts.t(14)}>
                Vous n'avez pas encore de favoris.
              </Text>
            </View>
          ) : (
            <PlaceHorizontalList
              navigation={navigation}
              data={institutesList}
              itemStyle={Style.con({bg: Palette.gray[2]})}
              onScroll={null}
              containerCustomStyle={
                isDesktopWeb
                  ? {
                      maxHeight: 158,
                    }
                  : isMobileWeb
                  ? {
                      maxHeight: 158,
                    }
                  : {}
              }
            />
          )}
          <Text
            style={Fonts.t(17, Palette.primary, {
              wei: '700',
              self: 'center',
              y: 32,
              b: 10,
            })}>
            {'Posts enregistrés'}
          </Text>
          <View
            style={Style.con({
              flex: 1,
            })}>
            {postsList.length === 0 ? (
              <View
                style={Style.con({
                  justify: 'center',
                  self: 'center',
                })}>
                <Text style={Fonts.t(14)}>
                  Vous n'avez pas encore de favoris.
                </Text>
              </View>
            ) : (
              <FlatList
                data={postsList}
                numColumns={3}
                showsVerticalScrollIndicator={false}
                scrollEnabled={false}
                // onEndReached={loadMore}
                // onEndReachedThreshold={0.5}
                // initialNumToRender={10}
                // ListFooterComponent={loadingView}
                ItemSeparatorComponent={() => {
                  return <View style={Style.con({h: 2, bg: Palette.white})} />;
                }}
                renderItem={({item: post, index}) => {
                  const postId = post?.postId;
                  const itemSize = (responsiveWidth(100) - 3) / 3;
                  const itemStyle = index % 3 === 1 ? Style.con({mx: 2}) : {};
                  const LIKED_POST = true;
                  const onPressPostItem = () => {
                    navigation?.navigate(Routes.PostDetails, {
                      postId,
                      LIKED_POST,
                    });
                  };
                  const onPressVideo = () => {
                    navigation?.navigate(Routes.ReelsStack, post);
                  };
                  return (
                    <TouchableOpacity
                      key={post?.postId}
                      style={
                        isDesktopWeb ? {width: '33%', height: 200} : itemStyle
                      }
                      onPress={
                        post.type === 'image' ? onPressPostItem : onPressVideo
                      }>
                      {post?.type === 'image' ? (
                        <Image
                          style={
                            isDesktopWeb
                              ? {
                                  width: '100%',
                                  height: '100%',
                                }
                              : Style.con({size: itemSize})
                          }
                          source={{
                            uri: post?.mediaURL,
                          }}
                        />
                      ) : (
                        <Video
                          source={{uri: post?.mediaURL}}
                          style={
                            isDesktopWeb
                              ? {
                                  width: '100%',
                                  height: '100%',
                                }
                              : Style.con({size: itemSize})
                          }
                          onError={e => console.log('error', e)}
                          resizeMode="cover"
                          paused={true}
                        />
                      )}
                    </TouchableOpacity>
                  );
                }}
              />
            )}
          </View>
        </ScrollView>
      );
    }
    return null;
  };

  if (!currentUID) {
    return <ReservationAttempt />;
  } else {
    return (
      <Page>
        <View
          style={[
            {
              flexDirection: 'row',
              paddingHorizontal: 20,
              paddingVertical: 10,
              alignItems: 'center',
            },
          ]}>
          {currentUserData?.profilPicture ? (
            <Image
              style={Style.con({
                size: 58,
                bor: 58,
              })}
              source={{uri: currentUserData?.profilPicture || ''}}
            />
          ) : (
            <View
              style={[
                Style.con({
                  size: responsiveWidth(15),
                  bor: responsiveWidth(15),
                  bg: Palette.gray[4],
                  justify: 'center',
                }),
                {
                  maxHeight: 60,
                  maxWidth: 60,
                },
              ]}>
              <Text
                style={[
                  Fonts.t(15, Palette.text[1], {
                    wei: '700',
                    mt: 20,
                    text: 'center',
                  }),
                  {},
                ]}>
                {`${(currentUserData?.contactDetails?.firstName || '').charAt(
                  0,
                )} ${(currentUserData?.contactDetails?.lastName || '').charAt(
                  0,
                )}`}
              </Text>
            </View>
          )}
          <View style={Style.con({flex: 1, cen: true})}>
            <Text style={Fonts.t(17, Palette.text[1], {wei: '700'})}>
              {`${currentUserData?.contactDetails?.firstName || '-'} ${
                currentUserData?.contactDetails?.lastName || '-'
              }`}
            </Text>
          </View>
          <TouchableOpacity
            onPress={onPressSettings}
            style={Style.con({size: 40, cen: true})}>
            <Image style={Style.con({size: 24})} source={icons.iconSettings} />
          </TouchableOpacity>
        </View>
        <View style={Style.con({flex: 1})}>
          <View style={Style.con({direc: 'row', h: 45})}>
            <TabView
              title="Rendez-vous"
              isSelected={curTab === PROFILE_TABS.Appointment}
              onTap={() => setCurTab(PROFILE_TABS.Appointment)}
            />
            <TabView
              title="Favoris"
              isSelected={curTab === PROFILE_TABS.Favoris}
              onTap={() => setCurTab(PROFILE_TABS.Favoris)}
            />
          </View>
          {renderTabBody()}
        </View>
        <SettingsModal
          isShow={showSettings}
          settingsList={settingsList}
          onClosed={() => setShowSettings(false)}
        />
        <LogoutModal
          isShow={showLogout}
          onClosed={() => setShowLogout(false)}
          onConfirm={handleConfirmLogout}
        />
        <AppointmentModal
          isShow={showAppointment}
          onClosed={() => setShowAppointment(false)}
          onReschedule={() => {
            setShowAppointment(false);
            navigation?.navigate(Routes.ChooseTimeDate, {
              institute: meetingRef?.instituteData,
              prestationData: meetingRef?.prestationData,
            });
          }}
          onMove={() => {
            setShowAppointment(false);
            navigation?.navigate(Routes.ChooseTimeDate, {
              institute: meetingRef?.instituteData,
              meetingRef,
            });
          }}
          onCancel={onCancelAlert}
          onLeaveAReview={() => setShowReview(true)}
          meetingRef={meetingRef}
          appointment={appointment}
        />
        <ReviewModal
          isShow={showReview}
          onClosed={() => setShowReview(false)}
          onSubmitReview={() => setShowReview(false)}
          meetingRef={meetingRef}
        />
      </Page>
    );
  }
};
