import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';

import {Palette} from '../styles';
import {Routes} from './Routes';

import Splash from '../screens/onboarding/Splash';
import LinkSent from '../screens/LinkSent';
import AccountCreated from '../screens/auth/AccountCreated';
import ReservationAttempt from '../screens/booking/ReservationAttempt';

import {BottomTabScreen} from './BottomTab';
import Inscription from '../screens/auth/Inscription';

const screenOptions = {
  headerShown: false,
  cardOverlayEnabled: true,
  headerStyle: {
    backgroundColor: Palette.background,
  },
  headerTitleStyle: {},
  headerTitleAlign: 'center',
  headerBackTitleVisible: false,
};

const MainStack = createStackNavigator();

export default function Main() {
  return (
    <MainStack.Navigator
      initialRouteName={Routes.BottomTab}
      screenOptions={[
        {
          contentStyle: {
            backgroundColor: Palette.white,
          },
        },
        screenOptions,
      ]}>
      <MainStack.Screen
        name={Routes.BottomTab}
        component={BottomTabScreen}
        options={{headerShown: false}}
      />
      <MainStack.Screen
        name={Routes.Splash}
        component={Splash}
        options={{headerShown: false}}
      />
      <MainStack.Screen
        name={Routes.LinkSent}
        component={LinkSent}
        options={{headerShown: false}}
      />
      <MainStack.Screen
        name={Routes.AccountCreated}
        component={AccountCreated}
        options={{headerShown: false}}
      />
      <MainStack.Screen
        name={Routes.Inscription}
        component={Inscription}
        options={{headerShown: false}}
      />
      <MainStack.Screen
        name={Routes.ReservationAttempt}
        component={ReservationAttempt}
        options={{headerShown: false}}
      />
    </MainStack.Navigator>
  );
}
