import React from "react";
import { Image, Platform, Text, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import {
  responsiveHeight,
  responsiveWidth,
} from "react-native-responsive-dimensions";

import { Style, Fonts, Palette, gutters } from "../styles";
import { Button } from "../components";
import { Routes } from "../navigation";
import { icons } from "../assets";
import Page from "../components/Page";

export default ({ navigation }) => {
  const onPressReturnToTheLoginScreen = () => {
    navigation?.navigate(Routes.Profile);
  };
  return (
    <Page>
      <SafeAreaView style={Style.con({ flex: 1, bg: Palette.white })}>
        <Image
          resizeMode="contain"
          source={icons.logo}
          style={Style.con({
            h:
              Platform.OS === "ios"
                ? responsiveHeight(13)
                : responsiveHeight(15),
            w:
              Platform.OS === "ios"
                ? responsiveWidth(64.5)
                : responsiveWidth(67.5),
            self: "center",
            mt: responsiveHeight(4),
            mb: responsiveHeight(2),
          })}
        />
        <Text
          style={Fonts.t(28, Palette.black, {
            self: "center",
            wei: "700",
            text: "center",
            b: responsiveHeight(4),
          })}
        >
          {"Lien envoyé"}
        </Text>
        <View style={Style.con({ flex: 1, px: gutters })}>
          <Text
            style={Fonts.t(12, Palette.gray[1], {
              wei: "700",
              self: "center",
              text: "center",
              b: 24,
              t: responsiveHeight(20),
            })}
          >
            {"Le lien vous a été envoyé par mail"}
          </Text>
          <Button
            title="Revenir à l'écran de connexion"
            onPress={onPressReturnToTheLoginScreen}
          />
        </View>
      </SafeAreaView>
    </Page>
  );
};
