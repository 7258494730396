import React, {useState, useGlobal} from 'reactn';
import {Image, Text, View, Platform} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {
  responsiveHeight,
  responsiveWidth,
} from 'react-native-responsive-dimensions';
import firebase from '../../config/firebase';
import {Style, Fonts, Palette, gutters} from '../../styles';
import {Button, Header, Input} from '../../components';
import {Routes} from '../../navigation';
import {handleFirebaseError} from '../../actions/signupActions';
import {icons} from '../../assets';
import Page from '../../components/Page';

export default ({navigation}) => {
  const [email, setEmail] = useState('');

  const [, setIsGlobalLoading] = useGlobal('_isLoading');
  const [, setTooltip] = useGlobal('_tooltip');

  const onPressResetMyPassword = async () => {
    try {
      setIsGlobalLoading(true);
      await firebase.auth().sendPasswordResetEmail(email.trim());
      navigation.navigate(Routes.LinkSent);
    } catch (error) {
      setTooltip({text: handleFirebaseError(error.code)});
    } finally {
      setIsGlobalLoading(false);
    }
  };

  const onPressBackToLogin = () => {
    navigation?.navigate(Routes.Login);
  };

  return (
    <Page>
      <SafeAreaView style={Style.con({flex: 1, bg: Palette.white})}>
        <KeyboardAwareScrollView
          keyboardDismissMode={'interactive'}
          keyboardShouldPersistTaps={'handled'}
          contentInsetAdjustmentBehavior={'automatic'}
          enableOnAndroid={true}
          extraHeight={160}
          style={Style.con({flex: 1})}
          scrollEnabled>
          <Image
            resizeMode="contain"
            source={icons.wink_full_red}
            style={Style.con({
              h:
                Platform.OS === 'ios'
                  ? responsiveHeight(13)
                  : responsiveHeight(15),
              w:
                Platform.OS === 'ios'
                  ? responsiveWidth(64.5)
                  : responsiveWidth(67.5),
              self: 'center',
              mt: responsiveHeight(4),
              mb: responsiveHeight(2),
            })}
          />
          <Text
            style={Fonts.t(28, Palette.black, {
              self: 'center',
              wei: '700',
              b: responsiveHeight(4),
              text: 'center',
            })}>
            {'Mot de passe oublié'}
          </Text>
          <View style={Style.con({flex: 1, px: gutters})}>
            <View style={Style.con({flex: 1, justify: 'center'})}>
              <Text
                style={Fonts.t(12, Palette.gray[1], {
                  wei: '700',
                  self: 'center',
                  text: 'center',
                  y: responsiveHeight(5),
                })}>
                {
                  'Renseignez votre adresse mail afin de recevoir \n un lien vous permettant de changer votre mot de passe.'
                }
              </Text>
              <Input
                placeholder="Adresse mail"
                style={Style.con({mb: responsiveHeight(1)})}
                value={email}
                keyboardType={'email-address'}
                onChange={value => setEmail(value)}
                autoCapitalize={'none'}
                autoCorrect={false}
              />
              <Button
                title="Réinitialiser mon mot de passe"
                onPress={onPressResetMyPassword}
              />
            </View>
          </View>
        </KeyboardAwareScrollView>
        <Text
          onPress={onPressBackToLogin}
          style={Fonts.t(12, Palette.primary, {
            wei: '700',
            self: 'center',
            b: responsiveHeight(5),
          })}>
          {'Retour à la connexion'}
        </Text>
      </SafeAreaView>
    </Page>
  );
};
