import React, {useGlobal} from 'reactn';
import {
  View,
  FlatList,
  Image,
  Text,
  TouchableOpacity,
  Platform,
} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import {Routes} from '../navigation';
import {Fonts, gutters, Palette, Style} from '../styles';
import {isDesktopWeb} from '../config/layoutConstants';

const ServiceTypeList = ({
  style = {},
  data = [],
  showTitle = true,
  isHome = false,
  isInstituteProfile = false,
}) => {
  const navigation = useNavigation();
  const [selectedTags, setSelectedTags] = useGlobal('selectedTags');

  return (
    <View style={[style]}>
      {isDesktopWeb ? (
        <View
          style={[
            style,
            {
              flexDirection: 'row',
              justifyContent: isInstituteProfile ? 'center' : 'space-between',
            },
          ]}>
          {data.map(item => {
            const onPress = () => {
              if (isHome) {
                if (selectedTags.includes(item.type)) {
                  setSelectedTags(
                    selectedTags.filter(tag => tag !== item.type),
                  );
                } else {
                  setSelectedTags([...selectedTags, item.type]);
                  navigation.navigate(Routes.Search);
                }
              } else {
                if (selectedTags.includes(item.type)) {
                  setSelectedTags(
                    selectedTags.filter(tag => tag !== item.type),
                  );
                } else {
                  setSelectedTags([...selectedTags, item.type]);
                }
              }
            };
            const isSelected = selectedTags.includes(item.type);
            const color = isSelected ? Palette.primary : Palette.black;
            return (
              <TouchableOpacity
                onPress={() => {
                  onPress(item);
                }}
                key={item?.id}
                style={Style.con({items: 'center', w: 70})}>
                <View
                  style={[
                    Style.con({size: 60, cen: true}),
                    Style.border({
                      width: isSelected ? 5 : 1,
                      color: color,
                      bor: 78,
                    }),
                  ]}>
                  <Image
                    resizeMode="contain"
                    style={Style.con({size: 45, tin: color})}
                    source={item?.icon}
                  />
                </View>
                {showTitle && (
                  <Text
                    style={[
                      Fonts.t(10, Palette.black, {
                        t: 10,
                        text: 'center',
                      }),
                      {
                        whiteSpace: 'nowrap',
                      },
                    ]}>
                    {item?.name}
                  </Text>
                )}
              </TouchableOpacity>
            );
          })}
        </View>
      ) : (
        <FlatList
          horizontal
          data={data}
          contentContainerStyle={Style.con({px: gutters / 2, pt: 14})}
          showsHorizontalScrollIndicator={false}
          renderItem={({item}) => {
            const isSelected = selectedTags.includes(item.type);

            const onPress = () => {
              if (isHome) {
                if (selectedTags.includes(item.type)) {
                  setSelectedTags(
                    selectedTags.filter(tag => tag !== item.type),
                  );
                } else {
                  setSelectedTags([...selectedTags, item.type]);
                  navigation.navigate(Routes.Search);
                }
              } else {
                if (selectedTags.includes(item.type)) {
                  setSelectedTags(
                    selectedTags.filter(tag => tag !== item.type),
                  );
                } else {
                  setSelectedTags([...selectedTags, item.type]);
                }
              }
            };

            const color = isSelected ? Palette.primary : Palette.black;

            return (
              <TouchableOpacity
                onPress={() => {
                  onPress(item);
                }}
                key={item?.id}
                style={Style.con({items: 'center', mr: 4, w: 88})}>
                <View
                  style={[
                    Style.con({size: 75, cen: true}),
                    Style.border({
                      width: isSelected ? 5 : 1,
                      color: color,
                      bor: 78,
                    }),
                  ]}>
                  <Image
                    resizeMode="contain"
                    style={Style.con({size: 54, tin: color})}
                    source={item?.icon}
                  />
                </View>
                {showTitle && (
                  <Text
                    style={[
                      Fonts.t(12, Palette.black, {
                        t: 10,
                        text: 'center',
                      }),
                    ]}>
                    {item?.name}
                  </Text>
                )}
              </TouchableOpacity>
            );
          }}
        />
      )}
    </View>
  );
};

export default ServiceTypeList;
