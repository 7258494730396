import React from "react";
import { Text, View, ScrollView } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";

import { Style, Fonts, Palette, gutters } from "../styles";
import { Header } from "../components";
import { dummyDataPrivacy } from "../data";
import { responsiveHeight } from "react-native-responsive-dimensions";
import Page from "../components/Page";

export default ({ navigation }) => {
  return (
    <Page>
        <Header title="Confidentialité" back navigation={navigation} />
        <ScrollView style={Style.con({ flex: 1 })}>
          <Text
            style={Fonts.t(15, Palette.black, {
              wei: "600",
              y: responsiveHeight(5),
              x: gutters,
            })}
          >
            {"WINK"}
          </Text>
          <View style={Style.con({ h: 1, bg: Palette.gray[5] })} />
          <Text
            style={Fonts.t(13, Palette.black, {
              wei: "400",
              y: responsiveHeight(4),
              x: gutters,
            })}
          >
            {dummyDataPrivacy.content}
          </Text>
        </ScrollView>
    </Page>
  );
};
