import React from 'react';
import {View} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {
  isDesktopWeb,
  isMobileWeb,
  isWeb,
  webSideBarWidth,
} from '../config/layoutConstants';
import {Palette} from '../styles';

import {BOTTOM_BAR_HEIGHT} from '../common/constants';

export default function Page({
  edges = ['top'],
  containerType = 'SAFE_AREA_VIEW',
  children,
}) {
  const Container = containerType === 'SAFE_AREA_VIEW' ? SafeAreaView : View;

  return (
    <Container
      edges={edges}
      style={[
        {
          backgroundColor: Palette.white,
          ...(isWeb
            ? {
                height: '100svh',
              }
            : {flex: 1}),
          ...(isDesktopWeb
            ? {
                maxWidth: 800,
                alignSelf: 'center',
                width: '50%',
                marginLeft: webSideBarWidth,
              }
            : isMobileWeb
            ? {
                paddingBottom: BOTTOM_BAR_HEIGHT,
              }
            : {}),
        },
      ]}>
      {children}
    </Container>
  );
}
